import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EventAlarmsGetResponse } from "../../../services/device-management/types";
import { green, red } from '@mui/material/colors';
import LazyLoadIcon from "components/LazyLoadIcon";

const iconSize = {
    width: 50,
    height: 50,
};

interface DashboardAlarmsTableProps {
    alarms?: EventAlarmsGetResponse;
}

export default function DashboardAlarmsTable(props: DashboardAlarmsTableProps) {
    const { t } = useTranslation();
    const { alarms } = props;
    
    return (
        <Grid container>
            <Grid item xs={12}>
                {t("System")}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid item>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    {t('Door alarms')}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <LazyLoadIcon iconName="MeetingRoom" sx={iconSize} style={{ color: green[500] }} />
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <Typography variant="h6">{alarms?.total?.doorAlarms ?? 0}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    {t('Device alarms')}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <LazyLoadIcon iconName="PowerSettingsNew" sx={iconSize} style={{ color: red[500] }} />
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <Typography variant="h6">{alarms?.total?.deviceAlarms ?? 0}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}