import { apiUrl } from "../../config";

const getDevicesEndpoint = () => `${apiUrl}/devices`;
const getDevicesWithSchedulesEndpoint = () => `${apiUrl}/devices/schedules`;
const getDeviceEventsEndpoint = (page: number, pageSize: number, selectedLogs: number[], startDate?: string, endDate?: string, locationUuid?: string) => 
        `${apiUrl}/device-events?page=${page}&pageSize=${pageSize}${(startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}` : ""}${selectedLogs.length ? `&eventTypes=${selectedLogs.join(",")}` : ""}${locationUuid ? `&location=${locationUuid}` : ""}`;
const getActivesEndpoint = (locationUuid?: string) => 
        `${apiUrl}/device-events/active-count?${locationUuid ? `location=${locationUuid}` : ""}`;
const getDeviceEndpoint = (deviceId: string) => `${apiUrl}/devices/${deviceId}`;
const getAlarmsEndpoint = (startDate?: string, endDate?: string, locationUuid?: string) =>
        `${apiUrl}/device-events/alarms?${startDate ? `startDate=${startDate}` : ""}${endDate? `&endDate=${endDate}` : ""}${locationUuid ? `&location=${locationUuid}` : ""}`;
const getPeopleFlowEndpoint = (startDate?: string, endDate?: string, locationUuid?: string) =>
        `${apiUrl}/device-events/people-flow?${startDate ? `startDate=${startDate}` : ""}${endDate? `&endDate=${endDate}` : ""}${locationUuid ? `&location=${locationUuid}` : ""}`;

export {
    getDevicesEndpoint,
    getDevicesWithSchedulesEndpoint,
    getDeviceEventsEndpoint,
    getActivesEndpoint,
    getDeviceEndpoint,
    getAlarmsEndpoint,
    getPeopleFlowEndpoint,
};
