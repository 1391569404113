import { createTheme, responsiveFontSizes } from "@mui/material";
import { indigo, pink } from "@mui/material/colors";
import { Theme } from '@mui/material/styles';
import zIndex from "@mui/material/styles/zIndex";

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    success: true;
    warning: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: pink['A400'],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSuccess: {
          backgroundColor: "#4caf50",
          color: "#fff",
        },
        outlinedSuccess: {
          borderColor: "#4caf50",
          color: "#4caf50",
        },
        containedPrimary: {
          backgroundColor: "#3f51b5",
          color: "#fff",
          ":hover": {
            backgroundColor: '#303f9f',
          },
        },
        outlinedPrimary: {
          borderColor: "#651fff",
          color: "#651fff",
        },
        containedError: {
          backgroundColor: "#ff1744",
          color: "#fff",
        },
        outlinedError: {
          borderColor: "#ff1744",
          color: "#ff1744",
        },
      },
    },
    MuiFab: {
      variants: [
        {
          props: { variant: "extended", color: "success" },
          style: {
            backgroundColor: "#008000",
            color: "#fff",
          }
        },
        {
          props: { variant: "extended", color: "warning" },
          style: {
            backgroundColor: "#ffa500",
            color: "#fff",
          }
        },
      ],
      styleOverrides: {
        
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: 'rgb(159,149,137) linear-gradient(74deg, rgba(159,149,137,1) 4%, rgba(249,169,72,1) 25%, rgba(31,157,181,1) 93%)',
          zIndex: zIndex.drawer + 1,
        },
        colorError: {
          backgroundColor: "#f44336",
        },
      },
    },
  }
})

export default responsiveFontSizes(theme);
