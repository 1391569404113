import React, { useEffect, useState } from 'react';
import { PermissionCategory, permissionsService } from 'services/account-management';
import { useSessionContext } from 'contexts/SessionContext';
import PermissionContext from 'contexts/PermissionContext';

type Props = {
    // permissions: PermissionCategory[]
}

const PermissionProvider: React.FunctionComponent<Props> = ({children}) => {
    const [sessionContext] = useSessionContext();
    
    const savedPermissions = localStorage.getItem('permissions')?.split(',').map(Number)
    
    const [ isLoading, setLoading ] = useState<boolean>(!savedPermissions);
    const [ permissions, setPermissions ] = useState<PermissionCategory[]>(savedPermissions ?? []);

    useEffect(() => {
        if (!sessionContext.token) return;
        permissionsService.getMyPermissions(sessionContext.token)
            .then(res => res.permissions)
            .then(permissions => {
                localStorage.setItem("permissions", permissions.join(","))
                setPermissions(permissions)
            })
            .catch(err => {})
            .finally(() => setLoading(false))
    }, [sessionContext.token]);
    
    const isAllowedTo = (permission: PermissionCategory) => {
        if (isLoading) return false;
        return permissions.includes(permission);
    }

    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
