import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EmployeeData } from 'services/company-management/employees.service';
import TableSearchbar from 'components/TableSearchbar';
import LazyLoadIcon from 'components/LazyLoadIcon';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof EmployeeData;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'First and last name' },
    { id: 'location', numeric: false, disablePadding: true, label: 'Location' },
    { id: 'department', numeric: false, disablePadding: true, label: 'Department' },
    { id: 'role', numeric: false, disablePadding: true, label: 'Role' },
    { id: 'cardId', numeric: true, disablePadding: true, label: 'Card ID' },
    // { id: 'PIN', numeric: true, disablePadding: true, label: 'PIN' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof EmployeeData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof EmployeeData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell
                    align={'center'}
                    sx={{ width: 150 }}
                >
                    {t("Actions")}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

interface EmployeesTableProps {
    handleClickEditOpen: (uuid: string) => void;
    handleClickDeleteOpen: (uuid: string) => void;
    handleClickGroupDeleteOpen: (uuid: string[]) => void;
    selected: string[];
    setSelected: (selected: string[]) => void;
    rows: EmployeeData[];
    unemployedChecked: boolean,
    searchTerm: string,
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
}

export default function EmployeesTable(props: EmployeesTableProps) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof EmployeeData>('name');
    const { handleClickEditOpen, handleClickDeleteOpen, selected, setSelected, rows, unemployedChecked, searchTerm, setSearchTerm } = props;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof EmployeeData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.uuid);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <Toolbar sx={{ pl: 2, pr: 1 }}>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                        {t("Employees")}
                    </Typography>
                    <TableSearchbar type={unemployedChecked ? "employees unemployed" : "employees employed"} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                </Toolbar>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="employeesTitle"
                        aria-label="employees table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `employees-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.uuid}
                                        >
                                            <TableCell
                                                align="left"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                padding="none"
                                            >
                                                {row.location.length === 0 ? t("nd") : row.location}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                padding="none"
                                            >
                                                {row.department}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                padding="none"
                                            >
                                                {row.role.length === 0 ? t("Nothing assigned") : row.role}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                padding="none"
                                            >
                                                {row.cardId.length === 0 ? t("Nothing assigned") : row.cardId}
                                            </TableCell>
                                            {/* <TableCell
                                                align="left"
                                                padding="none"
                                            >
                                                {row.PIN.length === 0 ? t("nd") : row.PIN}
                                            </TableCell> */}

                                            <TableCell align="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickEditOpen(row.uuid)} size="large">
                                                            <LazyLoadIcon iconName='Edit' />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickDeleteOpen(row.uuid)} size="large">
                                                            <LazyLoadIcon iconName='Delete' />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
