import { endOfDay, startOfDay } from "date-fns";
import { format } from "date-fns/esm";
import { genericGet } from "../genericService";
import { getActivesEndpoint, getAlarmsEndpoint, getDeviceEndpoint, getDeviceEventsEndpoint, getDevicesEndpoint, getDevicesWithSchedulesEndpoint, getPeopleFlowEndpoint } from "./endpoints";
import { ActiveGetResponse, EventAlarmsGetResponse, DeviceGetAllResponse, DeviceGetAllToTable, DeviceGetResponse, DeviceGetWithSchedulesResponse, EventGetAllResponse } from "./types";


const getAll = async (token?: string, term?: string): Promise<DeviceGetAllResponse[]> => {
    return genericGet<DeviceGetAllResponse[]>(getDevicesEndpoint(), {}, token);
}

const getOne = async (uuid: string, token?: string): Promise<DeviceGetResponse | undefined> => {
    return genericGet<DeviceGetResponse>(getDeviceEndpoint(uuid), {}, token);
}

const getWithSchedules = async (token?: string): Promise<DeviceGetWithSchedulesResponse[]> => {
    return genericGet<DeviceGetWithSchedulesResponse[]>(getDevicesWithSchedulesEndpoint(), {}, token);
};

const getAllToTable = async (token?: string): Promise<DeviceGetAllToTable[]> => {
    return getAll(token)
            .then(res => res.map(({ uuid, name, location }) => ({
                uuid,
                name,
                color: location.color
            })))
}

const getAllEvents = async (page: number, pageSize: number, selectedLogs: number[], startDate: Date | null, endDate: Date | null, locationUuid?: string, token?: string): Promise<EventGetAllResponse> => {
    // const start = startDate ? startOfDay(startDate).toISOString() : "";
    // const end = endDate ? endOfDay(endDate).toISOString() : "";
    const start = startDate ? format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
    const end = endDate ? format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";

    return genericGet<EventGetAllResponse>(
            getDeviceEventsEndpoint(page, pageSize, selectedLogs, start, end, locationUuid)
        , {}, token)
        .then(res => ({
            ...res,
            events: res.events.map(event => ({
                ...event,
                timestamp: new Date(event.timestamp),
            }))
        }))
}

const getAlarms = async (startDate: Date | null, endDate: Date | null, locationUuid?: string, token?: string): Promise<EventAlarmsGetResponse> => {
    // const start = startDate ? startOfDay(startDate).toISOString() : "";
    // const end = endDate ? endOfDay(endDate).toISOString() : "";
    const start = startDate ? format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
    const end = endDate ? format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";

    return genericGet<EventAlarmsGetResponse>(
            getAlarmsEndpoint(start, end, locationUuid)
            , {}, token)
}

const getAllActive = async (locationUuid?: string, token?: string): Promise<ActiveGetResponse> => {
    return genericGet<ActiveGetResponse>(
        getActivesEndpoint(locationUuid)
        , {}, token)
}

const getDataFlow = async (startDate: Date | null, endDate: Date | null, locationUuid?: string, token?: string): Promise<ActiveGetResponse[]> => {
    // const start = startDate ? startOfDay(startDate).toISOString() : "";
    // const end = endDate ? endOfDay(endDate).toISOString() : "";
    const start = startDate ? format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
    const end = endDate ? format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";

    return genericGet<ActiveGetResponse[]>(
            getPeopleFlowEndpoint(start, end, locationUuid)
            , {}, token)
}

export const deviceService = {
    getAll,
    getOne,
    getWithSchedules,
    getAllToTable,
    getAllEvents,
    getAlarms,
    getAllActive,
    getDataFlow,
}
