import usePermission from 'hooks/permission-hook';
import React from 'react';
import { PermissionCategory } from 'services/account-management';

type Props = {
    to: PermissionCategory | PermissionCategory[];
    fallback?: JSX.Element | string;
    loadingComponent?: JSX.Element | string;
};

const Restricted: React.FunctionComponent<Props> = ({to, fallback, loadingComponent, children}) => {
    const [allowed, loading] = usePermission(to);

    if (loading) return <>{loadingComponent}</>;

    if(allowed) return <>{children}</>;

    return <>{fallback}</>;
};

export default Restricted;
