import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@mui/material';

import SchedulesSchedulesTab from './components/schedules/SchedulesSchedulesTab';
import SchedulesHolidaysTab from './components/holidays/SchedulesHolidaysTab';
import Restricted from 'components/Restricted';
import { PermissionCategory } from 'services/account-management';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
        value: index,
    };
}

export default function SchedulesView() {
    const [value, setValue] = React.useState('one');

    const [t] = useTranslation();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Restricted to={PermissionCategory.Schedules}>
            <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={t("Schedules")} {...a11yProps('one')} />
                <Tab label={t("Holidays")} {...a11yProps('two')} />
            </Tabs>
            <TabPanel value={value} index="one">
                <SchedulesSchedulesTab />
            </TabPanel>
            <TabPanel value={value} index="two">
                <SchedulesHolidaysTab />
            </TabPanel>

        </Restricted>
    );
}