import {
    Button,
    ButtonGroup,
    IconButton,
} from "@mui/material";
import { useSessionContext } from "contexts/SessionContext";
import { useSnackbar, VariantType } from "notistack";
import EvacuationDialog from "pages/settings/components/evacuation/EvacuationDialog";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCreateErrorText } from "services/genericService";
import { Link as RouterLink } from 'react-router-dom';
import { evacuationListEmailService } from "services/setting-management/evacuationList.service";
import { EvacuationListEmail } from "services/setting-management/types";
import DialogOverlay from "components/DialogOverlay";
import LazyLoadIcon from "components/LazyLoadIcon";

interface EvacuationButtonProps {
    location: string;
    isWideButton?: boolean;
}

export default function EvacuationButton(props: EvacuationButtonProps) {
    const [t] = useTranslation();
    const [evacuationOpen, setEvacuationOpen] = React.useState(false);
    const [ emailsToEvacuate, setEmailsToEvatuate ] = React.useState<EvacuationListEmail[]>([]);
    const [sessionContext] = useSessionContext();
    const { location, isWideButton } = props;

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };
    
    const handleClickEvacuation = () => {
        setEvacuationOpen(true);
    };

    const handleClose = () => {
        setEvacuationOpen(false);
    };

    useEffect(() => {
        evacuationListEmailService.getAll(sessionContext.token)
            .then(res => setEmailsToEvatuate(res))
            .catch(err => 
                handleSnackBar(getCreateErrorText(err), 'error')
            )
    }, []);

    const handleEvacuate = () => {
        evacuationListEmailService.sendEvacuationList(location, (new Date()).getTimezoneOffset(), sessionContext.token)
            .then(() => {
                handleSnackBar("Evacuation list sent", 'success')
            })
            .catch(err => 
                handleSnackBar(getCreateErrorText(err), 'error')
            )

        handleClose();
    };
    
    return <>
        <ButtonGroup variant="contained">                
            <Button  variant="contained" color="primary" onClick={handleClickEvacuation} fullWidth={!!isWideButton} disabled={location === '' || !emailsToEvacuate.length}>
                {t("Evacuation")}
            </Button>
            <IconButton 
                component={RouterLink} 
                to={'/settings/evacuation'}
                sx={{ px: 1 }}
                size="small"
            >
                <LazyLoadIcon iconName="Settings" size="sm" color={!!location && !emailsToEvacuate.length ? "error" : "primary"} />
            </IconButton>
        </ButtonGroup>

        <DialogOverlay isOpen={evacuationOpen} handleClose={handleClose} title={t("Create evacuation list")}>
            <EvacuationDialog handleClose={handleClose} handleEvacuate={handleEvacuate} />
        </DialogOverlay>
    </>;
}
