import React, { FormEvent } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { useEffect } from 'react';

import { useInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import { holidaysService } from 'services/schedules-management/holidays.service';
import { HolidayCreateRequest } from 'services/schedules-management/types';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface AddHolidayFormProps {
    editedHoliday?: string;
    handleClose: () => void;
};

export default function AddHolidayForm(props: AddHolidayFormProps) {
    const classes = useStyles();
    const { value: name, setValue: setName, bind: bindName } = useInput("");
    const { value: description, setValue: setDescription, bind: bindDescription } = useInput("");
    const [ date, setDate ] = React.useState<Date | null>(new Date());
    const {editedHoliday, handleClose} = props;
    const [sessionContext] = useSessionContext();
    const { enqueueSnackbar } = useSnackbar();

    const [t] = useTranslation();

    useEffect(() => {
        if (editedHoliday) {
            holidaysService.getHoliday(editedHoliday, sessionContext.token)
                .then(res => {
                    setName(res.name);
                    setDescription(res.description);                
                    setDate(new Date(res.date));
                })
                .catch(err => handleSnackBar(getGetErrorText(err), "error"));
        }
    }, [editedHoliday]);

    function validate(): boolean {
        let err = false;

        if (name.length === 0) {handleSnackBar("Provide holiday name", "warning"); err = true;}
        if (!date) {handleSnackBar("Provide holiday date", "warning"); err = true;}

        return err;
    }

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();
        if (validate()) return;

        let holiday: HolidayCreateRequest = {
            name,
            date: date ? date.toISOString() : ""
        };
        if (description) holiday.description = description;
        
        if (editedHoliday) {
            holidaysService.updateHoliday(editedHoliday, holiday, sessionContext.token)
                .then(() => {
                    handleSnackBar(ResponseSuccessMessages.EDIT, 'success');
                    handleClose();
                })
                .catch(error => {
                    handleSnackBar(getCreateErrorText(error), 'error'); 
                });
        } else {
            holidaysService.createHoliday(holiday, sessionContext.token)
                .then(() => {
                    handleSnackBar(ResponseSuccessMessages.ADD, 'success');
                    handleClose();
                })
                .catch(error => {
                    handleSnackBar(getCreateErrorText(error), 'error'); 
                });
        }
    };

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const handleDateChange = (date: Date | null) => {
        if (date) setDate(date);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("Insert holiday data")}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label={t("Name")}
                        name="name"
                        autoComplete="name"
                        autoFocus
                        {...bindName}
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        id="description"
                        label={t("Description")}
                        name="description"
                        autoComplete="description"
                        {...bindDescription}
                    />
                    <DatePicker
                        format="dd/MM/yyyy"
                        label={t("Date")}
                        sx={{ width: "100%", mt: 3 }}
                        value={date}
                        onChange={handleDateChange}
                    />

                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedHoliday ? "Save" : "Add holiday")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
