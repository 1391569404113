import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

interface TableSearchbarProps {
    type:
        "accounts active"
        | "accounts blocked"
        | "employees employed"
        | "schedules"
        | "employees unemployed"
        | "events"
        | "guests"
        | "guest types"
        | "sum-work-time"
        | "work-time"
        | "locations"
        | "alarms"
        | "roles"
        | "devices"
        | "device groups"
        | "people"
        | "holidays"
        | "work-time-schedule";
    searchTerm: string;
    setSearchTerm: (newTerm: string) => void;
};

export default function TableSearchbar(props: TableSearchbarProps) {
    const { type, searchTerm, setSearchTerm } = props;

    const [t] = useTranslation();

    const getPhrase = (): string => {
        switch (type) {
            case "locations": return "location"
            case "accounts active":
            case "accounts blocked": return "account";
            case "employees employed": return "employee";
            case "employees unemployed": return "employee";
            case "guests": return "guest";
            case "guest types": return "guest type";
            case "events":
            case "work-time":
            case "sum-work-time":
            case "alarms": return "report";
            case "roles": return "role";
            case "devices": return "device";
            case "device groups": return "device group";
            case "people": return "person";
            case "holidays": return "holiday";
            case "schedules": return "schedule";
            case "work-time-schedule": return "work time schedule";
            default: return type;
        }
    }

    function handleOnChange(event: React.ChangeEvent<{ value: unknown }>): void {
        setSearchTerm(event.target.value as string);
    };

    return (
        <React.Fragment>
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                label={t(`Find ${getPhrase()}`)}
                name="searchTerm"
                value={searchTerm}
                onChange={handleOnChange}
                style={{ maxWidth: 250 }}
            />
        </React.Fragment>
    );
}
