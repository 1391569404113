import React, { useEffect } from 'react';
import { Box, Button, Grid, Switch } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';

import DeleteDialog from 'components/DeleteDialog';
import { useSessionContext } from 'contexts/SessionContext';
import { EmployeeCreateRequest } from 'services/company-management';
import { EmployeeData, employeesService } from 'services/company-management/employees.service';
import { getCreateErrorText, getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import AddEmployeeForm from './AddEmployeeForm';
import ConfirmInviteDialog from '../guests/ConfirmInviteDialog';
import EmployeesTable from './EmployeesTable';
import EditEmployeeForm from './EditEmployeeForm';
import DialogOverlay from 'components/DialogOverlay';

export default function MyCompanyEmployeesTab() {
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [inviteOpen, setInviteOpen] = React.useState(false);
    const [unemployedChecked, setUnemployedChecked] = React.useState(false);
    const [rows, setRows] = React.useState<EmployeeData[]>([]);
    const [editedRow, setEditedRow] = React.useState<string>("");
    const [selected, setSelected] = React.useState<string[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const additionalTabHidden = true;
    const { enqueueSnackbar } = useSnackbar();
    
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    useEffect(() => {
        refreshData();
    }, [unemployedChecked]);

    const refreshData = () => {
        employeesService.getAll(sessionContext.token)
            .then(res => {

                let temp: EmployeeData[] = [];

                res.forEach(singleRes => {
                    const { uuid, firstName, lastName, locations, departments, pin, cardNumber, role } = singleRes;
                    
                    temp.push({
                        uuid,
                        name: `${firstName} ${lastName}`,
                        location: locations.map(l => l.name).join(", "),
                        department: departments.map(d => d.name).join(", "),
                        role: role ? role.name : "",
                        cardId: cardNumber ?? "",
                        PIN: pin ?? "",
                    })
                })
                setRows(temp);
                
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnemployedChecked(event.target.checked);
        setSelected([]);
    };

    const handleAddEmployee = async (firstName: string, lastName: string, locations: string[], departments: string[]): Promise<void> => {
        const dept: EmployeeCreateRequest = {
            firstName,
            lastName,
            locations: locations.map(uuid => ({ uuid })),
            departments: departments.map(uuid => ({ uuid })),
        };
        
        employeesService.createEmployee(dept, sessionContext.token)
            .then((res) => {
                handleSnackBar(ResponseSuccessMessages.ADD, "success")

                refreshData();
                setEditedRow(res.uuid);
                setEditOpen(true);
            })
            .catch(err => handleSnackBar(getCreateErrorText(err), 'error'));
    };

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickInviteOpen = () => {
        setInviteOpen(true);
    };

    const handleClickEditOpen = (selected: string) => {
        setEditedRow(selected);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (selected: string) => {
        setEditedRow(selected);
        setDeleteOpen(true);
    };

    const handleClickGroupDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        setInviteOpen(false);
    };

    const handleDelete = () => {
        employeesService.deleteEmployees(editedRow, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                refreshData();
                handleClose();
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), "error"));
    };

    const containsSearchTerm = (employee: EmployeeData, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return employee.name.toLowerCase().includes(term) ||
            employee.location.toLowerCase().includes(term) ||
            employee.department.toLowerCase().includes(term) ||
            employee.role.toLowerCase().includes(term) ||
            employee.cardId.toLowerCase().includes(term) ||
            employee.PIN.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>

            {!additionalTabHidden &&
                <Grid component="label" container alignItems="center" spacing={1} style={{ maxWidth: 300 }}>
                    <Grid item>{t('Employed')}</Grid>
                    <Grid item>
                        <Switch color='primary' checked={unemployedChecked} onChange={handleSwitchChange} name="unemployedChecked" />
                    </Grid>
                    <Grid item>{t('Unemployed')}</Grid>
                </Grid>
            }

            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add employee")}
                        </Button>
                    </Grid>
                    {selected[0]
                        && <Grid item>
                            <Button variant="contained" color="primary" onClick={handleClickInviteOpen}>
                                {t("Invite employees")}
                            </Button>
                        </Grid>}
                </Grid>
            </Grid>

            <Box mt={2}>
                <EmployeesTable
                    selected={selected}
                    rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                    setSelected={setSelected}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickDeleteOpen}
                    handleClickGroupDeleteOpen={handleClickGroupDeleteOpen}
                    unemployedChecked={unemployedChecked}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add employee")}>
                <AddEmployeeForm handleAddEmployee={handleAddEmployee} handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit employee")}>
                <EditEmployeeForm refreshData={refreshData} handleClose={handleClose} editedEmployee={editedRow} />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete employee")}>
                <DeleteDialog type="employees" handleDelete={handleDelete} names={rows.filter(row => row.uuid === editedRow).map(row => row.name)} handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={inviteOpen} handleClose={handleClose} title={t("Invite employees")}>
                <ConfirmInviteDialog selected={selected} handleClose={handleClose} />
            </DialogOverlay>

        </React.Fragment>
    );
}