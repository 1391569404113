import { apiUrl } from "../../config";

const getEventsEndpoint = (locationUuid: string, filter: string, sortOrder: string, sortOrderBy: number, page: number, pageSize: number, startDate?: string, endDate?: string) =>
        `${apiUrl}/reports/events?location=${locationUuid}&filter=${filter}&page=${page}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortOrderBy=${sortOrderBy}${(startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}` : ""}`;

const downloadEventsEndpoint = (locationUuid: string, filter: string, sortOrder: string, sortOrderBy: number, startDate?: string, endDate?: string, type?: string, lang?: string) =>
        `${apiUrl}/reports/events/download?location=${locationUuid}&filter=${filter}&sortOrder=${sortOrder}&sortOrderBy=${sortOrderBy}${(startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}` : ""}${type ? `&type=${type}` : ""}${lang ? `&lang=${lang}` : ""}`;

const getSummarizedWorkTimeEndpoint = (locationUuid: string, filter: string, sortOrder: string, sortOrderBy: number, page: number, pageSize: number, employeeFilter?: string, startDate?: string, endDate?: string) =>
        `${apiUrl}/work-time-registration/summarized?location=${locationUuid}&filter=${filter}&page=${page}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortOrderBy=${sortOrderBy}${(startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}` : ""}${employeeFilter ? `&employeeFilter=${employeeFilter}` : ""}`;

const downloadSummarizedWorkTimeEndpoint = (locationUuid: string, filter: string, sortOrder: string, sortOrderBy: number, employeeFilter?: string, startDate?: string, endDate?: string, type?: string, lang?: string) =>
        `${apiUrl}/work-time-registration/summarized/download?location=${locationUuid}&filter=${filter}&sortOrder=${sortOrder}&sortOrderBy=${sortOrderBy}${(startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}` : ""}${type ? `&type=${type}` : ""}${lang ? `&lang=${lang}` : ""}${employeeFilter ? `&employeeFilter=${employeeFilter}` : ""}`;

export {
    getEventsEndpoint,
    downloadEventsEndpoint,
    getSummarizedWorkTimeEndpoint,
    downloadSummarizedWorkTimeEndpoint,
};
