import { Card, CardActionArea, CardContent, Container, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink} from 'react-router-dom';
import Restricted from 'components/Restricted';
import { PermissionCategory } from 'services/account-management';
import { useTranslation } from 'react-i18next';
import LazyLoadIcon from 'components/LazyLoadIcon';

const cardIconStyle = {
    fontSize: "inherit",
    color: "white",
};

export default function ReportsView() {
    const [t] = useTranslation();

    const cardData: { text: string, link: string, icon: string }[] = [
        {
            text: "Events",
            link: "events",
            icon: "EventNote"
        },
        // {
        //     text: "Guest passes",
        //     link: "guests",
        //     icon: "FreeBreakfast"
        // },
        // {
        //     text: "Alarms",
        //     link: "alarms",
        //     icon: "Warning"
        // },
        {
            text: "Work time",
            link: "work-time",
            icon: "Work"
        },
        {
            text: "Work Time Sum",
            link: "sum-work-time",
            icon: "Summarize"
        },
        // {
        //     text: "Add new",
        //     link: "add",
        //     icon: "AddCircleOutline"
        // },
    ];

    const getIcon = (name: string): JSX.Element => {
        const getIconName = () => {
            switch (name) {
                case "Settings": return "Settings";
                case "FreeBreakfast": return "FreeBreakfast";
                case "Warning": return "Warning";
                case "People": return "People";
                case "AddCircleOutline": return "AddCircleOutline";
                case "EventNote": return "EventNote";
                case "Work": return "Work";
                case "Summarize": return "Summarize";
                default: return "Warning";
            };
        }

        return <LazyLoadIcon iconName={getIconName()} style={cardIconStyle} size='lg' />
    };

    return (
        <Restricted to={PermissionCategory.Reports}>
            <Container sx={{ pt: 2, pb: 4 }}>
                <Grid
                    container
                    spacing={4}
                    alignContent="flex-start"
                    wrap="wrap"
                    justifyContent='flex-start'
                    direction='row'
                >
                    {cardData.map((card,index) => (
                        <Grid key={index} item>
                            <Link component={RouterLink} to={`/reports/${card.link}`}>
                                <Card sx={{
                                    backgroundColor: "primary.main",
                                    width: "20vw",
                                    height: "20vw",
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    minWidth: 150,
                                    minHeight: 150,
                                }}>
                                    <CardActionArea style={{ height: "100%" }}>
                                        <CardContent>
                                            <Grid container
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}>
                                                <Grid container item alignItems="center" justifyContent="center">
                                                    <Grid item>
                                                        <Typography gutterBottom variant="h6" component="h2" align='center' style={{ color: "white" }}>
                                                            {t(card.text)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sx={{
                                                    fontSize: {
                                                        xs: 50,
                                                        md: 70,
                                                    },
                                                }}>
                                                    {getIcon(card.icon)}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Restricted>
    );
}