import { Skeleton, SxProps, Theme } from "@mui/material";
import { Suspense, lazy } from "react";

const Menu = lazy(() => import(`@mui/icons-material/Menu`));
const ChevronLeft = lazy(() => import(`@mui/icons-material/ChevronLeft`));
const ChevronRight = lazy(() => import(`@mui/icons-material/ChevronRight`));
const Dashboard = lazy(() => import(`@mui/icons-material/Dashboard`));
const LocationCity = lazy(() => import(`@mui/icons-material/LocationCity`));
const Person = lazy(() => import(`@mui/icons-material/Person`));
const EventAvailable = lazy(() => import(`@mui/icons-material/EventAvailable`));
const Report = lazy(() => import(`@mui/icons-material/Report`));
const Devices = lazy(() => import(`@mui/icons-material/Devices`));
const DateRange = lazy(() => import(`@mui/icons-material/DateRange`));
const Settings = lazy(() => import(`@mui/icons-material/Settings`));
const MenuBook = lazy(() => import(`@mui/icons-material/MenuBook`));
const Clear = lazy(() => import(`@mui/icons-material/Clear`));
const MeetingRoom = lazy(() => import(`@mui/icons-material/MeetingRoom`));
const InsertChart = lazy(() => import(`@mui/icons-material/InsertChart`));
const Close = lazy(() => import(`@mui/icons-material/Close`));
const Lock = lazy(() => import(`@mui/icons-material/Lock`));
const AccountCircle = lazy(() => import(`@mui/icons-material/AccountCircle`));
const ExpandLess = lazy(() => import(`@mui/icons-material/ExpandLess`));
const ExpandMore = lazy(() => import(`@mui/icons-material/ExpandMore`));
const People = lazy(() => import(`@mui/icons-material/People`));
const PersonOutline = lazy(() => import(`@mui/icons-material/PersonOutline`));
const PowerSettingsNew = lazy(() => import(`@mui/icons-material/PowerSettingsNew`));
const Edit = lazy(() => import(`@mui/icons-material/Edit`));
const Delete = lazy(() => import(`@mui/icons-material/Delete`));
const KeyboardArrowUp = lazy(() => import(`@mui/icons-material/KeyboardArrowUp`));
const KeyboardArrowDown = lazy(() => import(`@mui/icons-material/KeyboardArrowDown`));
const ExitToApp = lazy(() => import(`@mui/icons-material/ExitToApp`));
const Casino = lazy(() => import(`@mui/icons-material/Casino`));
const GetApp = lazy(() => import(`@mui/icons-material/GetApp`));
const FreeBreakfast = lazy(() => import(`@mui/icons-material/FreeBreakfast`));
const Warning = lazy(() => import(`@mui/icons-material/Warning`));
const AddCircleOutline = lazy(() => import(`@mui/icons-material/AddCircleOutline`));
const EventNote = lazy(() => import(`@mui/icons-material/EventNote`));
const Work = lazy(() => import(`@mui/icons-material/Work`));
const Backup = lazy(() => import(`@mui/icons-material/Backup`));
const Summarize = lazy(() => import(`@mui/icons-material/Summarize`));

interface LazyLoadIconProps {
  iconName:
    (
      "Menu"
      | "ChevronLeft"
      | "ChevronRight"
      | "Dashboard"
      | "LocationCity"
      | "Person"
      | "EventAvailable"
      | "Report"
      | "Devices"
      | "DateRange"
      | "Settings"
      | "MenuBook"
      | "Clear"
      | "MeetingRoom"
      | "InsertChart"
      | "InsertChart"
      | "Close"
      | "Lock"
      | "AccountCircle"
      | "ExpandLess"
      | "ExpandMore"
      | "People"
      | "PersonOutline"
      | "PowerSettingsNew"
      | "Edit"
      | "Delete"
      | "KeyboardArrowUp"
      | "KeyboardArrowDown"
      | "ExitToApp"
      | "Casino"
      | "GetApp"
      | "FreeBreakfast"
      | "Warning"
      | "AddCircleOutline"
      | "EventNote"
      | "Work"
      | "Backup"
      | "Summarize"
    );
  size?: "sm" | "md" | "lg";
  style?: React.CSSProperties;
  color?: "inherit" | "disabled" | "error" | "success" | "warning" | "info" | "action" | "primary" | "secondary";
  sx?: SxProps<Theme>;
}

function LazyLoadIcon(props: LazyLoadIconProps) {
  const { iconName, size, style, color, sx } = props;

  const iconSize = (() => {
    if (size === "lg") return 50;
    if (size === "md") return 24;
    if (size === 'sm') return 20;
  })()
  const fontSize = (() => {
    if (size === "lg") return "large";
    if (size === "md") return "medium";
    if (size === 'sm') return "small";
  })()
  
  const customProps: any = { style, color, fontSize, sx };

  const getIcon = () => {
    switch (iconName) {
      case "Menu": return <Menu {...customProps} />;
      case "Dashboard": return <Dashboard {...customProps} />;
      case "LocationCity": return <LocationCity {...customProps} />;
      case "Person": return <Person {...customProps} />;
      case "Devices": return <Devices {...customProps} />;
      case "DateRange": return <DateRange {...customProps} />;
      case "EventAvailable": return <EventAvailable {...customProps} />;
      case "Report": return <Report {...customProps} />;
      case "Settings": return <Settings {...customProps} />;
      case "MenuBook": return <MenuBook {...customProps} />;
      case "MeetingRoom": return <MeetingRoom {...customProps} />;
      case "InsertChart": return <InsertChart {...customProps} />;
      case "ChevronRight": return <ChevronRight {...customProps} />;
      case "ChevronLeft": return <ChevronLeft {...customProps} />;
      case "Clear": return <Clear {...customProps} />;
      case "Close": return <Close {...customProps} />;
      case "Lock": return <Lock {...customProps} />;
      case "AccountCircle": return <AccountCircle {...customProps} />;
      case "ExpandLess": return <ExpandLess {...customProps} />;
      case "ExpandMore": return <ExpandMore {...customProps} />;
      case "People": return <People {...customProps} />;
      case "PersonOutline": return <PersonOutline {...customProps} />;
      case "PowerSettingsNew": return <PowerSettingsNew {...customProps} />;
      case "Edit": return <Edit {...customProps} />;
      case "Delete": return <Delete {...customProps} />;
      case "KeyboardArrowUp": return <KeyboardArrowUp {...customProps} />;
      case "KeyboardArrowDown": return <KeyboardArrowDown {...customProps} />;
      case "ExitToApp": return <ExitToApp {...customProps} />;
      case "Casino": return <Casino {...customProps} />;
      case "GetApp": return <GetApp {...customProps} />;
      case "FreeBreakfast": return <FreeBreakfast {...customProps} />;
      case "Warning": return <Warning {...customProps} />;
      case "AddCircleOutline": return <AddCircleOutline {...customProps} />;
      case "EventNote": return <EventNote {...customProps} />;
      case "Work": return <Work {...customProps} />;
      case "Backup": return <Backup {...customProps} />;
      case "Summarize": return <Summarize {...customProps} />;
      default: return <Settings {...customProps} />;
    }
  }

  return (
    <Suspense fallback={<Skeleton variant="circular" width={iconSize} height={iconSize} />}>
      {getIcon()}
    </Suspense>
  );
}

export default LazyLoadIcon;