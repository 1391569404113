import { Card, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ChartData } from "chart.js";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    labelColor: {
        width: 40,
        height: 12,
        display: 'inline-block',
        margin: "0 8px",
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
    },
    labelNotActive: {
        textDecoration: 'line-through',
    },
}));

interface ChartLabelProps {
    data?: ChartData;
    chartRef: any;
}

export function ChartLabel(props: ChartLabelProps) {
    const { data, chartRef } = props;
    const classes = useStyles();
    const [ labelsActive, setLabelsActive ] = useState<boolean[]>([]);

    useEffect(() => {
        if (data) {
            setLabelsActive(Array(data.datasets.length).fill(true))
        }
    }, [data]);
    
    const handleLabelClick = (index: number) => {
        const chart = chartRef.current;
        if (chart) {
            const nextState = !chart.isDatasetVisible(index);
            chart.setDatasetVisibility(index, nextState);
            chart.update();

            setLabelsActive(labelsActive.map((la, i) => i === index ? nextState : la))
        }
    }
    
    return (
        <Grid item container direction="row" justifyContent="center">
            {data && data.datasets.map((ds, index) => (
                <Grid item className={classes.labelContainer} onClick={() => handleLabelClick(index)} key={ds.label}>
                    <Card className={classes.labelColor} style={{ backgroundColor: ds?.backgroundColor?.toString() ?? "#000" }} component="span"></Card>
                    <Typography variant="caption" className={labelsActive[index] ? "" : classes.labelNotActive}>{ds.label}</Typography>
                </Grid>
            ))}
        </Grid>
    );
}