import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { ChartData } from "chart.js";
import React, { memo, useEffect, useRef, useState } from "react";
import { ActiveGetResponse } from "services/device-management/types";
import { addHours, addMinutes, format } from "date-fns";
import { ChartLabel } from "./ChartLabel";

const useStyles = makeStyles((theme) => ({
    graph: {
        width: '100%!important',
        maxHeight: 250,
    },
}));

interface DashboardPeopleFlowChartProps {
    peopleFlow: ActiveGetResponse[];
    isLessThenOneDayRange: boolean;
}

function DashboardPeopleFlowChart(props: DashboardPeopleFlowChartProps) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { peopleFlow, isLessThenOneDayRange } = props;
    const [ data, setData ] = useState<ChartData | undefined>(undefined);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        if (!peopleFlow) return;

        setData({
            labels: peopleFlow.map((r, i) => {
                if (i % 2 || !r.day) return "";
                const date = addMinutes(new Date(r.day), (new Date()).getTimezoneOffset());

                return format(date, isLessThenOneDayRange ? "HH:mm" : "dd.MM")
            }),
            datasets: [
                {
                    label: t("employees"),
                    data: peopleFlow.map(r => r.employees),
                    fill: false,
                    backgroundColor: "rgba(75,75,220,0.6)",
                    borderSkipped: "bottom",
                    borderRadius: 16,
                    animation: false,
                },
                {
                    label: t("guests"),
                    data: peopleFlow.map(r => r.guests),
                    fill: false,
                    backgroundColor: "rgba(180,120,140,0.6)",
                    borderSkipped: "bottom",
                    borderRadius: 16,
                    animation: false,
                },
            ],
        })

    }, [ peopleFlow, isLessThenOneDayRange, t ]);
    
    return (
        <Grid container direction="column">
            <Grid item>
                {t("Flow of people")}
            </Grid>
            <ChartLabel
                chartRef={chartRef}
                data={data}
            />
            <Grid item>
                <Bar
                    data={data}
                    className={classes.graph}
                    options={{
                        // responsive: true,
                        // maintainAspectRatio: true,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        scales: {
                            x: {
                                grid: {
                                    lineWidth: 1,
                                    //drawBorder: false

                                }
                            },
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    precision: 0,
                                },
                                grid: {
                                    lineWidth: 1,
                                    //drawBorder: false
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                              callbacks: {
                                title: (tooltipItems: any[]) => {
                                    const { dataIndex } = tooltipItems[0];
                                    
                                    const day = peopleFlow[dataIndex]?.day;
                                    if (!day) return "";
                                    const date = addMinutes(new Date(day), (new Date()).getTimezoneOffset());

                                    if (isLessThenOneDayRange)
                                        return `${format(date, "HH:mm")} - ${format(addHours(date, 1), "HH:mm")}`;

                                    return format(date, "dd.MM.yyyy");
                                },
                              }
                            },
                            legend: {
                                display: false,
                            }
                        }
                    }}
                    ref={chartRef}
                />
            </Grid>
        </Grid>
    );
}

const propsAreEqual = (prevData: DashboardPeopleFlowChartProps, nextData: DashboardPeopleFlowChartProps) =>
    JSON.stringify(prevData.peopleFlow) === JSON.stringify(nextData.peopleFlow);

export default memo(DashboardPeopleFlowChart, propsAreEqual);
