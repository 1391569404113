import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createEvacuationListEmailEndpoint, deleteEvacuationListEmailEndpoint, getEvacuationListEmailEndpoint, getEvacuationListEmailsEndpoint, sendEvacuationListEndpoint, updateEvacuationListEmailEndpoint } from "./endpoints/evacuationLists";
import { EvacuationListEmailCreateRequest, EvacuationListEmailCreateResponse, EvacuationListEmailGetAllResponse, EvacuationListEmailGetResponse, EvacuationListEmailUpdateRequest, EvacuationListEmailUpdateResponse } from "./types/evacuationList";

export interface EvacuationListEmail {
  uuid: string,
  email: string,
};

const sendEvacuationList = async(locationUuid: string, timezoneOffset: number, token?: string): Promise<EvacuationListEmail[]> => {
  return await genericGet(sendEvacuationListEndpoint(locationUuid, timezoneOffset), {}, token);
};

const getAll = async(token?: string): Promise<EvacuationListEmail[]> => {
  return await genericGet<EvacuationListEmailGetAllResponse[]>(getEvacuationListEmailsEndpoint(), {}, token);
};

const getEvacuationListEmail = async(uuid: string, token?: string): Promise<EvacuationListEmail> => {
  return await genericGet<EvacuationListEmailGetResponse>(getEvacuationListEmailEndpoint(uuid), {}, token);
};

const createEvacuationListEmail = async(dept: EvacuationListEmailCreateRequest, token?: string): Promise<EvacuationListEmail> => {
  return await genericPost<EvacuationListEmailCreateResponse>(createEvacuationListEmailEndpoint(), dept, token);
};

const updateEvacuationListEmail = async(dept: EvacuationListEmailUpdateRequest, uuid: string, token?: string): Promise<EvacuationListEmail> => {
  return await genericPut<EvacuationListEmailUpdateResponse>(updateEvacuationListEmailEndpoint(uuid), dept, token);
};

const deleteEvacuationListEmail = async(uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
  return await genericDelete(deleteEvacuationListEmailEndpoint(uuid), token);
};

export const evacuationListEmailService = {
  sendEvacuationList,
  getAll,
  getEvacuationListEmail,
  createEvacuationListEmail,
  updateEvacuationListEmail,
  deleteEvacuationListEmail
};

