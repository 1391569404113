import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createRoleEndpoint, deleteRoleEndpoint, getRoleEndpoint, getRolesWithPermissionsEndpoint, updateRoleEndpoint } from "./endpoints";
import { RoleCreateRequest, RoleCreateResponse, RoleGetAllResponse, RoleGetResponse, RoleUpdateRequest, RoleUpdateResponse } from "./types";

const getRoles = async (token?: string, term?: string): Promise<RoleGetAllResponse[]> => {
    const roles = await genericGet<RoleGetAllResponse[]>(getRolesWithPermissionsEndpoint(), {}, token);
    if (term)
        return roles.filter(role => role.name.toLowerCase().includes(term.toLowerCase()));
    else
        return roles;
};

const getRole = async (uuid: string, token?: string): Promise<RoleGetResponse> => {
    return genericGet<RoleGetResponse>(getRoleEndpoint(uuid), {}, token);
};

const createRole = async (role: RoleCreateRequest, token?: string): Promise<RoleCreateResponse> => {
    return genericPost(createRoleEndpoint(), role, token);
};

const updateRole = async (uuid: string, role: RoleUpdateRequest, token?: string): Promise<RoleUpdateResponse> => {
    return genericPut(updateRoleEndpoint(uuid), role, token);
};

const deleteRole = async (uuid: string, token?: string): Promise<void> => {
    await genericDelete(deleteRoleEndpoint(uuid), token);
};

export const rolesService = {
    getRoles,
    getRole,
    createRole,
    updateRole,
    deleteRole,
}