import { AppBar, Box, Breakpoint, Container, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import LazyLoadIcon from "./LazyLoadIcon";

interface DialogOverlayProps {
    children?: React.ReactNode;
    isOpen?: boolean;
    setOpen?: (open: boolean) => void;
    handleClose?: () => void;
    color?: "default" | "error";
    title: string;
    maxWidth?: false | Breakpoint | undefined;
};

function DialogOverlay(props: DialogOverlayProps) {
    const { children, isOpen, setOpen, color, title, maxWidth } = props;

    const handleClose = () => {
        if (props.handleClose) props.handleClose()
        else if (setOpen) setOpen(false);
    }
    
    return (
        <Dialog fullScreen open={isOpen ?? false} onClose={handleClose} >
            <AppBar color={color} sx={{
                position: 'relative',
            }}>
                <Toolbar sx={{ position: "relative" }}>
                    <Typography marginLeft={2} flex={1} variant="h6">
                        {title}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        size="large">
                        <LazyLoadIcon iconName="Close" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box pb={1}>
                {maxWidth ? (
                    <Container component="main" maxWidth={maxWidth}>
                        {children}
                    </Container>
                ) : children}
            </Box>
        </Dialog>
    );
}

export default DialogOverlay;
