import React, { FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';

import { useInput, useSelectInput } from 'hooks/input-hook';
import { locationsService } from 'services/company-management/locations.service';
import { departmentsService } from 'services/company-management/departments.service';
import ColorPicker from 'components/ColorPicker';
import { useSessionContext } from 'contexts/SessionContext';
import { DepartmentCreateRequest, DepartmentGetNamesResponse, DepartmentUpdateRequest } from 'services/company-management';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import LocationSelect from 'components/LocationSelect';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

interface AddDepartmentFormProps {
    editedDepartmentId?: string;
    handleClose: () => void;
};

export default function AddDepartmentForm(props: AddDepartmentFormProps) {
    const classes = useStyles();
    const [t] = useTranslation();
    const { editedDepartmentId, handleClose } = props;
    const [sessionContext] = useSessionContext();
    const [color, setColor] = React.useState<string>("ff0000");
    const { value: departmentName, setValue: setDepartmentName, bind: bindDepartmentName } = useInput('', /^.{0,50}$/);
    const { value: alias, setValue: setAlias, bind: bindAlias } = useInput('', /^.{0,10}$/);
    const { value: note, setValue: setNote, bind: bindNote } = useInput('', /^.{0,50}$/);
    const [locationId, setLocationId] = React.useState('');
    const [departments, setDepartments] = React.useState<DepartmentGetNamesResponse[]>([]);
    const { value: parentDepartmentId, setValue: setParentDepartmentId, bind: bindParentDepartmentId } = useSelectInput('');
    const editMode: boolean = !!editedDepartmentId;
    const [ departmentNameError, setDepartmentNameError ] = useState<boolean>(false);
    const [ parentDepartmentError, setParentDepartmentError ] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    useEffect(() => {
        departmentsService.getNames(sessionContext.token)
        .then(res => {
            setDepartments(res);
        })
        .then(() => {
            if (editedDepartmentId) {
                departmentsService.getDepartment(editedDepartmentId, sessionContext.token)
                    .then(res => {
                        setColor(res.color ?? 'none');
                        setDepartmentName(res.name);
                        setAlias(res.alias ?? '');
                        setNote(res.note ?? '');
                        setParentDepartmentId(res.parentDepartmentId ?? '-');
                    })
                    .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
            }
        })
        .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }, []);

    const handleOnSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();

        resetErrorLog();
        if (!ifValidateOk()) return;

        const dept: DepartmentUpdateRequest | DepartmentCreateRequest = {
            name: departmentName,
            ...(!!color && color !== 'none' && { color }),
            ...(!!note && { note }),
            ...(!!alias && { alias }),
            ...(!!parentDepartmentId && parentDepartmentId !== '-' && { parentDepartmentId })
        };

        try {
            if (!!editedDepartmentId) {
                await departmentsService.updateDepartment(editedDepartmentId, dept as DepartmentUpdateRequest, sessionContext.token)
                    .then(() => {
                        handleSnackBar(ResponseSuccessMessages.EDIT, "success");
                    })
            } else {
                await departmentsService.createDepartment(dept as DepartmentCreateRequest, sessionContext.token)
                    .then(() => {
                        handleSnackBar(ResponseSuccessMessages.ADD, "success");
                    })
            }
            
            handleClose();
        } catch (error) {
            handleSnackBar(getCreateErrorText(error as number), 'error'); 
        }
    };

    const resetErrorLog = (): void => {
        setDepartmentNameError(false);
        setParentDepartmentError(false);
    }

    const ifValidateOk = (): boolean => {
        let temp = false;

        if (departmentName.length === 0) {
            setDepartmentNameError(true);
            temp = true;
        }

        if (parentDepartmentId.length === 0) {
            setParentDepartmentError(true);
            temp = true;
        }

        if (temp) {
            handleSnackBar("You have to fill in all spaces", "error")
            return false;
        }

        return true;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t(editMode ? "Edit department" : "Add a new department")}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="departmentName"
                        label={t("Department name")}
                        name="departmentName"
                        autoComplete="departmentName"
                        autoFocus
                        error={departmentNameError}
                        {...bindDepartmentName}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="alias"
                        label={t("Alias")}
                        name="alias"
                        autoComplete="alias"
                        {...bindAlias}
                    />
                    <TextField
                        multiline
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="description"
                        label={t("Description")}
                        name="description"
                        {...bindNote}
                    />

                    <FormControl sx={{ mt: 2, mb: 1 }} fullWidth required variant='standard'>
                        <InputLabel id="location-select-labels">{t("Select parent department")}</InputLabel>
                        <Select
                            fullWidth
                            displayEmpty
                            error={parentDepartmentError}
                            {...bindParentDepartmentId}
                        >
                            <MenuItem value="-" >
                                {t("No parent")}
                            </MenuItem>
                            {departments.map(department => (
                                <MenuItem key={department.uuid} value={department.id}>
                                    {department.name}
                                </MenuItem >
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <ColorPicker value={color} setValue={setColor} />
                    </FormControl>

                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editMode ? "Save" : "Add department")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}