export type Permission = {
    uuid: string;
    name: string;
    category?: number;
    isGranted: boolean;
}

export type GrantedPermission = {
    uuid: string;
}

export type PermissionGetAllResponse = {
    uuid: Permission["uuid"];
    name: Permission["name"];
    category?: Permission["category"];
}

export enum PermissionCategory {
    Location = 1,
    Departments = 2,
    Employees = 3,
    Roles = 4,
    Guests = 5,
    Devices = 6,
    Schedules = 7,
    ManageAccessGroup = 8,
    ManageAccessPermissions = 9,
    Reports = 10,
    Settings = 11,
}

export type PermissionGetMyResponse = {
    permissions: PermissionCategory[];
}
