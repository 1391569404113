import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, IconButton, Toolbar, Typography, AppBar, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GuestDataTable, guestsService } from 'services/account-management';
import DeleteDialog from 'components/DeleteDialog';
import { useSessionContext } from 'contexts/SessionContext';
import AddGuestForm from './AddGuestForm';
import UnregisterGuestDialog from '../registered/UnregisterGuestDialog';
import GuestsTable from './GuestsTable';
import EvacuationButton from 'pages/settings/components/evacuation/EvacuationButton';
import { useSelectInput } from 'hooks/input-hook';
import { ResponseSuccessMessages, getCreateErrorText, getDeleteErrorText, getGetErrorText } from 'services/genericService';
import { useSnackbar, VariantType } from 'notistack';
import RegisterGuestDialog from './RegisterGuestDialog';
import DialogOverlay from 'components/DialogOverlay';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    deleteAppBar: {
        position: 'relative',
        backgroundColor: theme.palette.error.main
    },
    appBarSpacer: theme.mixins.toolbar,
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function MyGuestsGuestsTab() {
    const classes = useStyles();
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [registerOpen, setRegisterOpen] = React.useState(false);
    const [unregisterOpen, setUnregisterOpen] = React.useState(false);
    const [selectedUuid, setSelectedUuid] = React.useState<string>("");
    const [rows, setRows] = React.useState<GuestDataTable[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    useEffect(() => {
        refreshData()
    }, []);

    const refreshData = () => {
        guestsService.getAll(undefined, sessionContext.token)
            .then(guests => {
                setRows(
                    guests.map(guest => {
                        return {
                            ...guest,
                            guestType: guest.guestType?.name ?? "",
                            name: `${guest.firstName} ${guest.lastName}`,
                            supervisor: "",
                            cardNumber: "",
                            pin: "",
                            isRegistered: guest.isRegistered ? "true" : "false",
                            company: guest.company ?? "",
                        }
                    })
                );
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickEditOpen = (selectedUuid: string) => {
        setSelectedUuid(selectedUuid);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (selectedUuid: string) => {
        setSelectedUuid(selectedUuid);
        setDeleteOpen(true);
    };

    const handleClickRegisterOpen = (selectedUuid: string) => {
        setSelectedUuid(selectedUuid);
        setRegisterOpen(true);
    };

    const handleClickUnregisterOpen = (selectedUuid: string) => {
        setSelectedUuid(selectedUuid);
        setUnregisterOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        setRegisterOpen(false);
        setUnregisterOpen(false);
        setSelectedUuid("");
        refreshData();
    };

    const handleDelete = () => {
        guestsService.deleteGuest(selectedUuid, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, 'success')
                handleClose()
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), 'error'));
    };

    const containsSearchTerm = (guest: GuestDataTable, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return (
            guest.name.toLowerCase().includes(term) ||
            guest.company.toLowerCase().includes(term) ||
            guest.phoneNumber.toString().toLowerCase().includes(term)
        );
    };
    
    const handleUnregister = () => {
        guestsService.unregisterGuest(selectedUuid, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.UNREGISTER, 'success');
                handleClose();
            })
            .catch(err => handleSnackBar(getCreateErrorText(err), 'error'));
    };

    return <>
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item container alignItems="center" xs spacing={2}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                        {t("Register new")}
                    </Button>
                </Grid>
                {/* <Grid item>
                    <EvacuationButton location={location} />
                </Grid> */}
            </Grid>
        </Grid>
        <Box mt={2}>
            <GuestsTable
                rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                handleClickEditOpen={handleClickEditOpen}
                handleClickDeleteOpen={handleClickDeleteOpen}
                handleClickRegisterOpen={handleClickRegisterOpen}
                handleClickUnregisterOpen={handleClickUnregisterOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
        </Box>

        <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Register new")}>
            <AddGuestForm handleClose={handleClose} />
        </DialogOverlay>

        <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit guest")}>
            <AddGuestForm editedGuest={selectedUuid} handleClose={handleClose} />
        </DialogOverlay>

        <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete guest")}>
            <DeleteDialog
                type={"guests"}
                handleDelete={handleDelete}
                names={rows.filter(row => selectedUuid.includes(row.uuid)).map(row => row.name)}
                handleClose={handleClose}
            />
        </DialogOverlay>

        <DialogOverlay isOpen={registerOpen} handleClose={handleClose} title={t("Register guest")}>
            <RegisterGuestDialog selectedUuid={selectedUuid} handleClose={handleClose} />
        </DialogOverlay>

        <DialogOverlay isOpen={unregisterOpen} handleClose={handleClose} title={t("Unregister guest")}>
            <UnregisterGuestDialog selected={rows.filter(row => selectedUuid.includes(row.uuid))} handleClose={handleClose} handleUnregister={handleUnregister} />
        </DialogOverlay>
    </>;
}
