import { genericDelete, genericGet, genericPost, genericPut } from "services/genericService";
import { createWorktimeScheduleEndpoint, deleteWorktimeScheduleEndpoint, getEmployeeWorktimeScheduleEndpoint, getWorktimeScheduleEndpoint, getWorktimeSchedulesEndpoint, updateEmployeeWorktimeScheduleEndpoint, updateWorktimeScheduleEndpoint } from "./endpoints/worktimeSchedules";
import { WorktimeScheduleCreateRequest, WorktimeScheduleCreateResponse, WorktimeScheduleEmployeeUpdateRequest, WorktimeScheduleGetResponse, WorktimeScheduleGetShortNamesResponse, WorktimeScheduleUpdateRequest, WorktimeScheduleUpdateResponse } from "./types/worktimeSchedules";

const getAll = (token?: string): Promise<WorktimeScheduleGetShortNamesResponse[]> => {
    return genericGet<WorktimeScheduleGetShortNamesResponse[]>(getWorktimeSchedulesEndpoint(), {}, token)
        .then(res => res.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
}

const getWorktimeSchedule = (uuid: string, token?: string): Promise<WorktimeScheduleGetResponse> => {
    return genericGet<WorktimeScheduleGetResponse>(getWorktimeScheduleEndpoint(uuid), {}, token)
        .then(res => ({
            ...res,
            shifts: res.shifts.sort((a, b) => a.shiftNumber - b.shiftNumber),
        }))
}

const createWorktimeSchedule = (dept: WorktimeScheduleCreateRequest, token?: string): Promise<WorktimeScheduleCreateResponse> => {
    return genericPost<WorktimeScheduleCreateResponse>(createWorktimeScheduleEndpoint(), dept, token);
}

const updateWorktimeSchedule = (uuid: string, dept: WorktimeScheduleUpdateRequest, token?: string): Promise<WorktimeScheduleUpdateResponse> => {
    return genericPut<WorktimeScheduleUpdateResponse>(updateWorktimeScheduleEndpoint(uuid), dept, token);
}

const deleteWorktimeSchedule = (uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
    return genericDelete(deleteWorktimeScheduleEndpoint(uuid), token);
}

const getEmployeeWorktimeSchedule = (employeeUuid: string, token?: string): Promise<WorktimeScheduleGetShortNamesResponse> => {
    return genericGet<WorktimeScheduleGetShortNamesResponse>(getEmployeeWorktimeScheduleEndpoint(employeeUuid), {}, token);
}

const updateEmployeeWorktimeSchedule = (employeeUuid: string, dept: WorktimeScheduleEmployeeUpdateRequest, token?: string): Promise<WorktimeScheduleGetShortNamesResponse> => {
    return genericPut<WorktimeScheduleGetShortNamesResponse>(updateEmployeeWorktimeScheduleEndpoint(employeeUuid), dept, token);
}

export const worktimeSchedulesService = {
    getAll,
    getWorktimeSchedule,
    createWorktimeSchedule,
    updateWorktimeSchedule,
    deleteWorktimeSchedule,
    getEmployeeWorktimeSchedule,
    updateEmployeeWorktimeSchedule,
}
