import React, { Suspense } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import usePermission from 'hooks/permission-hook';
import { PermissionCategory } from 'services/account-management';
import ProfileMenu from './ProfileMenu';
import LazyLoadIcon from './LazyLoadIcon';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: `${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })} !important`,
    },
    appBarShift: {
      marginLeft: `${drawerWidth} !important`,
      width: 0,
      zIndex: `${theme.zIndex.drawer} !important`,
      transition: `${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })} !important`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px) !important`,
      },
    },
    drawerOpen: {
      width: "100%",
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
      transition: `${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })} !important`,
    },
    drawerClose: {
      transition: `${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })} !important`,
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
      overflowX: "hidden",
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center !important',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
  }),
);

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

export default function SideMenu(props: any) {
  const theme = useTheme();
  const classes = useStyles(props);
  const location = useLocation();
  const [open, setOpen] = React.useState(window.innerWidth >= 600);
  const [t] = useTranslation();

  const [ hasCompanyPermission ] = usePermission([PermissionCategory.Location, PermissionCategory.Departments, PermissionCategory.Employees, PermissionCategory.Roles]);
  const [ hasGuestPermission ] = usePermission(PermissionCategory.Guests);
  const [ hasDevicePermission ] = usePermission(PermissionCategory.Devices);
  const [ hasSchedulePermission ] = usePermission(PermissionCategory.Schedules);
  const [ hasManageAccessPermission ] = usePermission([PermissionCategory.ManageAccessGroup, PermissionCategory.ManageAccessPermissions]);
  const [ hasReportPermission ] = usePermission(PermissionCategory.Reports);

  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  const getIcon = (key: string) => {
    const getIconname = () => {
      switch (key) {
        case "MenuIcon": return "Menu";
        case "DashboardIcon": return "Dashboard";
        case "LocationCityIcon": return "LocationCity";
        case "PersonIcon": return "Person";
        case "DevicesIcon": return "Devices";
        case "DateRangeIcon": return "DateRange";
        case "EventAvailableIcon": return "EventAvailable";
        case "ReportIcon": return "Report";
        case "SettingsIcon": return "Settings";
        case "MenuBookIcon": return "MenuBook";
        case "MeetingRoomIcon": return "MeetingRoom";
        case "InsertChartIcon": return "InsertChart";
        case "ChevronRightIcon": return "ChevronRight";
        case "ChevronLeftIcon": return "ChevronLeft";
        default: return "Clear";
      }
    }

    return <LazyLoadIcon iconName={getIconname()} />
  };

  const listItems: { href: string, text: string, iconKey: string }[] = [];

  if (hasCompanyPermission) listItems.push({
    href: "/company",
    text: "My company",
    iconKey: "LocationCityIcon",
  })
  if (hasGuestPermission) listItems.push({
    href: "/guests",
    text: "My guests",
    iconKey: "PersonIcon",
  })
  if (hasDevicePermission) listItems.push({
    href: "/devices",
    text: "My devices",
    iconKey: "DevicesIcon",
  })
  if (hasSchedulePermission) listItems.push({
    href: "/schedules",
    text: "Access schedule",
    iconKey: "DateRangeIcon",
  })
  if (hasManageAccessPermission) listItems.push({
    href: "/manage",
    text: "Manage access",
    iconKey: "MeetingRoomIcon",
  })
  if (hasReportPermission) listItems.push({
    href: "/reports",
    text: "Reports",
    iconKey: "InsertChartIcon",
  })

  return (
    <>
      <CssBaseline />
      <AppBar
        sx={{
          
        }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {!open && <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              mr: 5
            }}
            size="large">
              <Suspense fallback="test">
                {getIcon("MenuIcon")}
              </Suspense>
          </IconButton>}
          <Typography variant="h6" noWrap flexGrow={1}>
            Protectimo
          </Typography>

          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          whiteSpace: 'nowrap',
          overflowX: !open ? 'hidden' : undefined,
          width: {
            xs: !open ? 57 : drawerWidth,
            sm: !open ? 73 : undefined,
          },
        }}
        className={clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? getIcon("ChevronRightIcon") : getIcon("ChevronLeftIcon")}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItemLink sx={{ pl: { sm: "24px" } }} component={Link} to="/" selected={location.pathname === "/"}>
            <ListItemIcon > {getIcon("DashboardIcon")} </ListItemIcon>
            <ListItemText primary={t("Dashboard")} />
          </ListItemLink>

          {listItems.map((item, index) => (
            <ListItemLink sx={{ pl: { sm: "24px" } }} component={Link} key={index} to={item.href} selected={location.pathname.startsWith(item.href)}>
              <ListItemIcon > {getIcon(item.iconKey)} </ListItemIcon>
              <ListItemText primary={t(item.text)} />
            </ListItemLink>
          ))}

        </List>
      </Drawer>
    </>
  );
}