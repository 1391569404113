import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';

import DeleteDialog from 'components/DeleteDialog';
import { ScheduleGetForTableResponse } from 'services/schedules-management/types';
import { schedulesService } from 'services/schedules-management/schedules.service';
import { useSessionContext } from 'contexts/SessionContext';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import SchedulesTable from './SchedulesTable';
import AddScheduleForm from './AddScheduleForm';
import DialogOverlay from 'components/DialogOverlay';

export default function SchedulesSchedulesTab() {
    const [t] = useTranslation();
    const [rows, setRows] = React.useState<ScheduleGetForTableResponse[]>([]);
    const [addOpen, setAddOpen] = React.useState<boolean>(false);
    const [editOpen, setEditOpen] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [editedRowId, setEditedRowId] = React.useState<string>('');
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [sessionContext] = useSessionContext();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        refreshList();
    }, [editedRowId]);

    const handleClose = () => {
        setEditedRowId('');
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        refreshList();
    };

    const handleClickAddOpen = () => {
        setEditedRowId('');
        setAddOpen(true);
    };

    const handleClickEditOpen = (uuid: string) => {
        setEditedRowId(uuid);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (uuid: string) => {
        setEditedRowId(uuid);
        setDeleteOpen(true);
    };

    const handleDelete = () => {
        schedulesService.deleteSchedule(editedRowId, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
                setEditedRowId('');
            })
            .catch(err => {
                handleSnackBar(getDeleteErrorText(err), "error");
            });
    };

    const refreshList = () => {
        schedulesService.getAllSchedules(sessionContext.token)
            .then(res => {
                setRows(res);
            })
            .catch(err => {
                handleSnackBar(getGetErrorText(err), "error");
            });
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const containsSearchTerm = (schedule: ScheduleGetForTableResponse, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return schedule.name.toLowerCase().includes(term);
    };

    return <>
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item container alignItems="center" xs>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                        {t("Add schedule")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>

        <Box mt={2}>
            <SchedulesTable
                rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                handleClickEditOpen={handleClickEditOpen}
                handleClickDeleteOpen={handleClickDeleteOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
        </Box>

        <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add schedule")}>
            <AddScheduleForm handleClose={handleClose} />
        </DialogOverlay>

        <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit schedule")}>
            <AddScheduleForm handleClose={handleClose} editedSchedule={editedRowId} />
        </DialogOverlay>

        <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete schedule")}>
            <DeleteDialog type='schedule' handleDelete={handleDelete} names={[rows.find(row => row.uuid === editedRowId)?.name ?? '']} handleClose={handleClose} />
        </DialogOverlay>
    </>;
}
