import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { ReportPaginationData, ReportWorkTimeSortOrderBy, ReportWorkTimeTableData } from 'services/report-management/types';
import TablePagination from 'components/TablePagination';
import Holidays from 'date-holidays';
import { isSameDay, isSaturday, isSunday } from 'date-fns';
import { Box } from '@mui/material';

type Order = 'asc' | 'desc';

interface HeadCell {
    id: keyof ReportWorkTimeTableData;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'employeeId', numeric: true, label: 'Identifier' },
    { id: 'employeeName', numeric: false, label: 'Last and first name' },
    { id: 'employeeDepartment', numeric: false, label: 'Department' },
    { id: 'normalTime', numeric: false, label: 'Stay' },
    { id: 'workTime', numeric: false, label: 'Time passed' },
    { id: 'cameLateBy', numeric: false, label: 'Came late by' },
    { id: 'leftEarlierBy', numeric: false, label: 'Left earlier by' },
    { id: 'nightHours', numeric: false, label: 'Night hours' },
    { id: 'breakTime', numeric: false, label: 'Break time' },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ReportWorkTimeTableData) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof ReportWorkTimeTableData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const [t] = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {(headCell.id in ReportWorkTimeSortOrderBy) ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.label)}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={{
                                        border: 0,
                                        clip: 'rect(0 0 0 0)',
                                        margin: -1,
                                        overflow: 'hidden',
                                        padding: 0,
                                        position: 'absolute',
                                        top: 20,
                                    }}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            t(headCell.label)
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface ReportsSummarizedWorktimeTableProps {
    rows?: ReportPaginationData<ReportWorkTimeTableData>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number | "auto">>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<number>>;
    selectedAuto?: boolean;
    selectedEmployee?: string;
}

export default function ReportsSummarizedWorktimeTable(props: ReportsSummarizedWorktimeTableProps) {
    const { rows, setRowsPerPage, setPage, setOrder, setOrderBy, selectedAuto, selectedEmployee } = props;
    const holidays = new Holidays("PL");

    const orderByLabel = (property: keyof ReportWorkTimeTableData): ReportWorkTimeSortOrderBy => {
        if (property === 'weekDay') return ReportWorkTimeSortOrderBy.weekDay;
        if (property === 'employeeDepartment') return ReportWorkTimeSortOrderBy.employeeDepartment;
        if (property === 'employeeId') return ReportWorkTimeSortOrderBy.employeeId;
        if (property === 'employeeName') return ReportWorkTimeSortOrderBy.employeeName;
        if (property === 'exitTime') return ReportWorkTimeSortOrderBy.exitTime;
        if (property === 'normalTime') return ReportWorkTimeSortOrderBy.normalTime;
        return ReportWorkTimeSortOrderBy.entranceTime;
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReportWorkTimeTableData) => {
        const isAsc = rows?.sortOrder === 'asc';
        const newOrderLabel = orderByLabel(property);
        
        setOrder(newOrderLabel === rows?.sortOrderBy && !isAsc ? 'asc' : 'desc');
        setOrderBy(newOrderLabel);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (newRowsPerPage: number | "auto") => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const emptyRows = (rows && rows.count) ? rows.pageSize - Math.min(rows.pageSize, rows.count - rows.page * rows.pageSize) : 0;

    const checkIfIsWorkoff = (date: Date) => (
        isSunday(date)
        || isSaturday(date)
        || Boolean(holidays.getHolidays().filter(el => isSameDay(new Date(el.date), date)).length)
    )
    
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750, pl: 2, pr: 1 }}
                        aria-labelledby="workTimeReportsTable"
                        aria-label="work time reports table"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={rows?.sortOrder === 'desc' ? 'asc' : 'desc'}
                            orderBy={ReportWorkTimeSortOrderBy[rows?.sortOrderBy ?? ReportWorkTimeSortOrderBy.entranceTime]}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {rows && (rows.rows as ReportWorkTimeTableData[])
                                .map((row, index) => {
                                    const labelId = `report-${index}`;
                                    const isHoliday = row.day ? checkIfIsWorkoff(new Date(row.day)) : false;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            sx={{
                                                opacity: isHoliday ? 0.4 : 1
                                            }}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row.employeeId}
                                            </TableCell>
                                            <TableCell >{row.employeeName}</TableCell>
                                            <TableCell >{row.employeeDepartment}</TableCell>
                                            <TableCell>{row.normalTime}</TableCell>
                                            <TableCell>{row.workTime}</TableCell>
                                            <TableCell>{row.cameLateBy}</TableCell>
                                            <TableCell>{row.leftEarlierBy}</TableCell>
                                            <TableCell>{row.nightHours}</TableCell>
                                            <TableCell>{row.breakTime}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={9} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {rows && !!rows.count && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    autoRowEnabled={selectedEmployee === '0'}
                    page={rows.page}
                    rowsPerPage={selectedAuto ? "auto" : rows.pageSize}
                    count={rows.count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </Paper>
        </Box>
    );
}
