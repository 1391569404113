import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { GuestDataTable } from 'services/account-management';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface UnregisterGuestDialogProps {
    selected: GuestDataTable[];
    handleClose: () => void;
    handleUnregister: () => void;
}

export default function UnregisterGuestDialog(props: UnregisterGuestDialogProps) {
    const classes = useStyles();
    const { selected, handleClose, handleUnregister } = props;

    const [t] = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("You are about to unregister")}
                    </Typography>
                    {selected.map(s => (
                        <Typography component="h4" variant='h6' align='center'>
                            {s.name}
                        </Typography>
                    ))}
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={handleClose}
                            color="primary"
                        >
                            {t("Cancel")}
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={handleUnregister}
                            color="error"
                        >
                            {t("Unregister guest")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}