import { createGuestEndpoint, deleteGuestEndpoint, getGuestEndpoint, getGuestsEndpoint, registerGuestEndpoint, unregisterGuestEndpoint, updateGuestEndpoint } from ".";
import { genericGet, genericPost, genericPut, genericDelete } from "../genericService";
import { GuestCreateRequest, GuestCreateResponse, GuestGetAllResponse, GuestGetResponse, GuestRegisterRequest, GuestUpdateRequest, GuestUpdateResponse } from "./types/guest";

export interface GuestDataTable {
  id: number;
  uuid: string;
  name: string;
  company: string;
  supervisor: string;
  guestType: string;
  cardNumber: string;
  phoneNumber: number;
  isRegistered: string;
  pin: string;
};

const getAll = async(locationUuid?: string, token?: string): Promise<GuestGetAllResponse[]> => {
  return genericGet<GuestGetAllResponse[]>(getGuestsEndpoint(locationUuid), {}, token);
};

const getAllRegistered = async(locationUuid?: string, token?: string): Promise<GuestGetAllResponse[]> => {
  return genericGet<GuestGetAllResponse[]>(getGuestsEndpoint(locationUuid, true), {}, token);
};

const getGuest = async(guestUuid: string, token?: string): Promise<GuestGetResponse | undefined> => {
  return await genericGet<GuestGetResponse>(getGuestEndpoint(guestUuid), {}, token);
}

const createGuest = (dept: GuestCreateRequest, token?: string): Promise<GuestCreateResponse> => {
  return genericPost<GuestCreateResponse>(createGuestEndpoint(), dept, token);
};

const updateGuest = (uuid: string, dept: GuestUpdateRequest, token?: string): Promise<GuestUpdateResponse> => {
  return genericPut<GuestUpdateResponse>(updateGuestEndpoint(uuid), dept, token);
};

const deleteGuest = (uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
  return genericDelete(deleteGuestEndpoint(uuid), token);
};

const registerGuest = (uuid: string, dept: GuestRegisterRequest, token?: string): Promise<{status: number, statusText: string}> => {
  return genericPut(registerGuestEndpoint(uuid), dept, token);
};

const unregisterGuest = (uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
  return genericPut(unregisterGuestEndpoint(uuid), {}, token);
};

export const guestsService = {
  getAll,
  getAllRegistered,
  getGuest,
  createGuest,
  updateGuest,
  deleteGuest,
  registerGuest,
  unregisterGuest,
};
