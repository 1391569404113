import { FormControl, MenuItem, PaginationItem, Select, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";

const usePaginationStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: 'rgba(0, 0, 0, 0.87)',
            overflow: 'auto',
            fontSize: '0.875rem',
            '&:last-child': {
                padding: 0,
            }
        },
        toolbar: {
            minHeight: 52,
            paddingRight: 2,
            paddingLeft: 16,
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
        },
        spacer: {
            flex: '1 1 100%',
        },
        noShrink: {
            flexShrink: 0,
        },
        actions: {
            flexShrink: 0,
            marginLeft: 20,
        },
        selectRoot: {
            marginLeft: 8,
            marginRight: 32,
        },
    }),
);

interface TablePaginationWithoutAutoProps {
    rowsPerPageOptions: number[];
    autoRowEnabled?: boolean;
    count: number;
    rowsPerPage: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onRowsPerPageChange: (event: number) => void;
}

interface TablePaginationWithAutoProps {
    rowsPerPageOptions: number[];
    autoRowEnabled: boolean;
    count: number;
    rowsPerPage: number | "auto";
    page: number;
    onPageChange: (newPage: number) => void;
    onRowsPerPageChange: (event: number | "auto") => void;
}

type TablePaginationProps = TablePaginationWithoutAutoProps | TablePaginationWithAutoProps;

export default function TablePagination(props: TablePaginationProps) {
    const { count, onPageChange, onRowsPerPageChange, page, rowsPerPage, rowsPerPageOptions, autoRowEnabled } = props;
    const [t] = useTranslation();
    const classes = usePaginationStyles();
    
    const handleChangeToPrevPage = () => onPageChange(page - 1);
    const handleChangeToNextPage = () => onPageChange(page + 1);

    const handleChangeRowsPerPage = (event: any) => {
        onRowsPerPageChange(event)
    }

    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <div className={classes.spacer}></div>
                <Typography variant="body2" display="block" flexShrink={0}>
                    {t("Rows per page")}
                </Typography>
                <FormControl
                    sx={{ ml: 1, mr: 4, flexShrink: 0 }}
                    variant='standard'
                >
                    <Select
                        required
                        value={rowsPerPage}
                        onChange={e => handleChangeRowsPerPage(e.target.value as any)}
                    >
                        {autoRowEnabled && <MenuItem value="auto">{t("Automatic")}</MenuItem>}
                        {rowsPerPageOptions.map((rowsPerPageOption, index) => (
                            <MenuItem value={rowsPerPageOption} key={index}>{rowsPerPageOption}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {rowsPerPage !== 'auto' && <Typography variant="body2" display="block" flexShrink={0}>
                    {`${page * rowsPerPage + 1}-${Math.min(page * rowsPerPage + rowsPerPage, count)} ${t('of')} ${count}`}
                </Typography>}
                <div className={classes.actions}>
                    <PaginationItem
                        type='previous'
                        size='large'
                        onClick={handleChangeToPrevPage}
                        disabled={page <= 0}
                    />
                    <PaginationItem
                        type='next'
                        size='large'
                        onClick={handleChangeToNextPage}
                        disabled={rowsPerPage !== 'auto' && (page * rowsPerPage + rowsPerPage) >= count}
                    />
                </div>
            </div>
        </div>
    )
}