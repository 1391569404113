import React from "react";
import { Paper, Grid, Typography, Button } from "@mui/material";
import {
  getDate, isSameMonth, isToday, format, isWithinInterval, isSameDay, isAfter,
  setMonth,
  set,
  isSameYear,
} from "date-fns";
import {
  chunks, getDaysInMonth, isStartOfRange, isEndOfRange, inDateRange, isRangeSameDay,
  getMonths,
  getYears
} from "../utils";
import Header from "./Header";
import Day from "./Day";

import { NavigationAction, DateRange } from "../types";
import { useTranslation } from "react-i18next";

interface MonthProps {
  value: Date;
  marker: symbol;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  navState: [boolean, boolean];
  setValue: (date: Date) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onNormalNavigate: (marker: symbol, action: NavigationAction) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  locale?: Locale;
  hoverDay?: Date;
  displayMode: "normal" | "months" | "years";
  setDisplayMode: React.Dispatch<React.SetStateAction<"normal" | "months" | "years">>;
}

const Month: React.FunctionComponent<MonthProps> = (props: MonthProps) => {
  const {
    helpers,
    handlers,
    value: date,
    dateRange,
    marker,
    setValue: setDate,
    minDate,
    maxDate,
    locale,
    hoverDay,
    displayMode,
    setDisplayMode,
  } = props;

  const weekStartsOn = locale?.options?.weekStartsOn || 0;
  const WEEK_DAYS = typeof locale !== 'undefined'
    ? [0, 1, 2, 3, 4, 5, 6].map(d => locale.localize?.day((d + weekStartsOn) % 7, { width: 'short', context: 'standalone' }))
    : ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const [back, forward] = props.navState;
  const [t] = useTranslation();

  const selectNavigator = () => {
    if (displayMode === 'months') return handlers.onMonthNavigate;
    return handlers.onNormalNavigate;
  }

  const onClickPrevious = () => selectNavigator()(marker, NavigationAction.Previous)
  const onClickNext = () => selectNavigator()(marker, NavigationAction.Next)

  const onClickMonth = (month: number) => {
    setDate(set(date, { month, date: 1 }))
    setDisplayMode('normal')
  }

  const onClickYear = (year: number) => {
    setDate(set(date, { year, month: 0, date: 1 }))
    setDisplayMode('normal')
  }

  return (
    <Paper square elevation={0} sx={{ width: 290 }}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={onClickPrevious}
          onClickNext={onClickNext}
          locale={locale}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
        />

        {displayMode === 'normal' && (
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            sx={{
              marginTop: "10px",
              paddingLeft: "30px",
              paddingRight: "30px"
            }}
          >
            {WEEK_DAYS.map((day, index) => (
              <Typography color="textSecondary" key={index} variant="caption">
                {day}
              </Typography>
            ))}
          </Grid>
        )}

        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          gap={displayMode === 'normal' ? 0.25 : 2}
          sx={{
            p: "15px",
            pb: "20px",
            pt: displayMode === 'months' ? 1 : undefined,
          }}
        >
          {displayMode === 'normal' && chunks(getDaysInMonth(date, locale), 7).map((week, idx) => (
            <Grid key={idx} container direction="row" justifyContent="center">
              {week.map((day) => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const isRangeOneDay = isRangeSameDay(dateRange);
                const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);
                const isHover = hoverDay && dateRange.startDate ? (isAfter(hoverDay, dateRange.startDate) && isSameDay(day, hoverDay)) : false;

                return (
                  <Day
                    key={format(day, "dd-MM-yyyy")}
                    filled={isStart || isEnd || isHover}
                    outlined={isToday(day)}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={
                      !isSameMonth(date, day)
                      || !isWithinInterval(day, { start: minDate, end: maxDate })
                    }
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd}
                    onClick={() => handlers.onDayClick(day)}
                    onHover={() => handlers.onDayHover(day)}
                    isHover={isHover}
                    value={getDate(day)}
                  />
                );
              })}
            </Grid>
          ))}
          {displayMode === 'months' && chunks(getMonths(), 4).map((monthRow, idx) => (
            <Grid key={idx} container direction="row" justifyContent="center" item>
              {monthRow.map((monthName, index) => {
                const monthNumber = idx * 4 + index;
                const month = setMonth(date, monthNumber)
                const currentMonth = dateRange.startDate && isSameMonth(month, dateRange.startDate)

                return (
                  <Grid item key={index}>
                    <Button variant={currentMonth ? "outlined" : "text"} color="inherit" disabled={!isWithinInterval(month, { start: minDate, end: maxDate })} onClick={() => onClickMonth(monthNumber)}>{t(monthName).slice(0, 3)}</Button>
                  </Grid>
                )
              })}
            </Grid>
          ))}
          {displayMode === 'years' && chunks(getYears(new Date()), 4).map((yearRow, idx) => (
            <Grid key={idx} container direction="row" justifyContent="center">
              {yearRow.map((yearNumber, index) => {
                const yearDate = set(date, { year: yearNumber, month: 0, date: 1 })
                const currentYear = dateRange.startDate && isSameYear(yearDate, dateRange.startDate)

                return (
                  <Grid item key={index}>
                    <Button variant={currentYear ? "outlined" : "text"} color="inherit" disabled={!isWithinInterval(yearDate, { start: minDate, end: maxDate })} onClick={() => onClickYear(yearNumber)}>{yearNumber}</Button>
                  </Grid>
                )
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Month;
