import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Toolbar, Typography } from '@mui/material';

import { Holiday } from 'services/schedules-management/types';
import TableSearchbar from 'components/TableSearchbar';
import LazyLoadIcon from 'components/LazyLoadIcon';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends 'name'>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: 'name';
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'name', numeric: false, label: 'Name' }
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: 'name') => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = () => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, 'name');
    };
    const [t] = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler()}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell width={"130px"}>
                    {t("Holiday date")}
                </TableCell>
                <TableCell width={"150px"}>
                    {t("Actions")}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        toolbarRoot: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        title: {
            flex: '1 1 100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

interface HolidaysTableProps {
    rows:Holiday[];
    handleClickEditOpen: (id: string) => void;
    handleClickDeleteOpen: (id: string) => void;
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

export default function HolidaysTable(props: HolidaysTableProps) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<'name'>('name');

    const { rows, handleClickEditOpen, handleClickDeleteOpen, searchTerm, setSearchTerm } = props;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: 'name') => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar className={classes.toolbarRoot}>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {t("Holidays")}
                    </Typography>
                    <TableSearchbar type="holidays" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                </Toolbar>
                <TableContainer>
                    <Table
                        size="small"
                        className={classes.table}
                        aria-labelledby="holidaysTable"
                        aria-label="holidays table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `table-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row.date}
                                            </TableCell>
                                            <TableCell>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickEditOpen(row.uuid)} size="large">
                                                            <LazyLoadIcon iconName='Edit' />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickDeleteOpen(row.uuid)} size="large">
                                                            <LazyLoadIcon iconName='Delete' />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
