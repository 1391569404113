import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import ColorSquare, { transparentBackgroundUrlBase64 } from './ColorSquare';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
        openButton: {
            borderRadius: "50%",
            height: 40,
            width: 40,
            minWidth: 0,
            minHeight: 0,
        },
        popoverContent: {
            padding: theme.spacing(1),
            width: 150,
            minHeight: 200,
        },
        colorButton: {
            width: "auto",
            height: "auto",
            minWidth: 0,
            minHeight: 0,
        },
        buttonLabel: {
            width: "auto",
            height: "auto",
        },
        buttonGrid: {
            width: 30,
            height: 30,
        },
    }),
);

interface ColorPickerProps {
    value: string;
    setValue: (color: string) => void;
}

export default function ColorPicker(props: ColorPickerProps) {
    const classes = useStyles();
    const { value, setValue } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [t] = useTranslation();

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChooseNewColor = (color: string) => {
        setValue(color);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'color-picker-popover' : undefined;
    const hasColor = value !== 'none';
    
    const colors: string[] = ["none", "ff0000", "00ff00", "0000ff", "ff00ff", "00ffff",
        "F44236", "B388FE", "009788", "FEC107", "FF8A81", "3D5AFE", "FFD600",
        "4CB050", "EF6C00", "EA1E63", "42A5F6", "8BC24A", "FE5722", "607D8B",
        "FF80AB", "0E47A1", "1C5E20", "8C6E63", "DF40FC", "00B8D2",
        "E23FFC", "00B8D4", "1C5E42", "202221", "673BB7", "016064"
    ];

    return (
        <Grid container style={{ width: "100%" }}>
            <Grid item xs container alignItems="center" justifyContent="flex-start">
                <Grid item>
                    <Typography sx={{ p: 2 }}>{t("Choose color")}</Typography>
                </Grid>
            </Grid>
            <Grid item xs container alignItems="center" justifyContent="flex-end">
                <Grid item>
                    <Button
                        aria-describedby={id}
                        variant="contained"
                        color="primary"
                        onClick={handleOpenPopover}
                        style={{
                            backgroundColor: hasColor ? `#${value}` : 'white',
                            backgroundImage: hasColor ? 'none' : `url(${transparentBackgroundUrlBase64})`
                        }}
                        sx={{
                            borderRadius: "50%",
                            height: 40,
                            width: 40,
                            minWidth: 0,
                            minHeight: 0,
                        }}
                    />
                </Grid>
            </Grid>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Grid
                    container wrap="wrap" alignContent="flex-start" alignItems="center" justifyContent="center"
                    sx={{
                        p: 1,
                        width: 150,
                        minHeight: 200,
                        gap: 1,
                    }}
                >
                    {colors.map((color, index) => (
                        <Grid
                            item key={index}
                            sx={{
                                width: 25,
                                height: 25,
                            }}
                        >
                            <Button
                                sx={{
                                    width: "auto",
                                    height: "auto",
                                    minWidth: 0,
                                    minHeight: 0,
                                    p: 0,
                                }}
                                onClick={() => handleChooseNewColor(color)}>
                                <ColorSquare color={color} size="normal" />
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </Grid>
    );
}