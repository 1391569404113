import React, { FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery } from 'react-query';
import { Alert } from '@mui/material';

import { useSessionContext } from 'contexts/SessionContext';
import { useCheckboxInput, useInput, useSelectInput } from 'hooks/input-hook';
import { locationsService } from 'services/company-management/locations.service';
import ColorPicker from 'components/ColorPicker';
import { ResponseMessage } from 'services/types';
import { DeviceControllerGetNamesResponse, DeviceGroupCreateRequest } from 'services/device-management/types';
import { deviceGroupService } from 'services/device-management/deviceGroup.service';
import { deviceService } from 'services/device-management/device.service';

interface DeviceRowProps {
    index: number;
    value: string;
    rows: string[];
    devices: DeviceControllerGetNamesResponse[];
    error?: boolean;
    handleRemoveLine: (index: number) => void;
    handleChangeSelect: (value: string, index: number) => void;
}

function DeviceRow(props: DeviceRowProps) {
    const { index, value, rows, devices, handleRemoveLine, handleChangeSelect, error } = props;

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        handleChangeSelect(event.target.value as string, index);
    };

    const [t] = useTranslation();

    return (
        <TableRow>
            <TableCell>
                <FormControl fullWidth error={!value.length && error}>
                    <InputLabel id="device-select-label">{t("Choose device")}</InputLabel>
                    <Select
                        fullWidth
                        labelId="device-select-label"
                        id="device-select"
                        value={value}
                        onChange={handleSelectChange}
                    >
                        {devices.map((device, index) => (
                            <MenuItem key={index} value={device.uuid} disabled={rows.includes(device.uuid)}>{device.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <IconButton onClick={() => handleRemoveLine(index)} size="large">
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

const useStyles = makeStyles((theme) => ({
    main: {
        minWidth: "500px",
        maxWidth: "500px",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formPaper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    formControl: {
        marginTop: theme.spacing(3),
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
    },
    table: {
        width: "100%",
    },
}));

interface AddDeviceGroupFormProps {
    editedGroup?: string;
    handleClose: () => void;
}

export default function AddDeviceGroupForm(props: AddDeviceGroupFormProps) {
    const classes = useStyles();
    const [sessionContext] = useSessionContext();
    const { data } = useQuery(
        "locationsService.getAll",
        () => locationsService.getAll(sessionContext.token),
        { retry: false, refetchOnWindowFocus: false }
    );
    const locations = data ?? [];

    const { editedGroup, handleClose } = props;
    const { value: name, bind: bindName, reset: resetName } = useInput("");
    const { value: description, bind: bindDescription, reset: resetDescription } = useInput("");
    const { value: location, bind: bindLocation, reset: resetLocation } = useSelectInput("");
    const [color, setColor] = React.useState<string>("3D5AFE");
    const { value: active, bind: bindActive, reset: resetActive } = useCheckboxInput(true);
    const [rows, setRows] = React.useState<string[]>([]);
    const [addDeviceGroupResponse, setAddDeviceGroupResponse] = React.useState<null | ResponseMessage>(null);
    const [devices, setDevices] = React.useState<DeviceControllerGetNamesResponse[]>([]);

    const [nameValidation, setNameValidation] = React.useState(false);
    const [locationValidation, setLocationValidation] = React.useState(false);
    const [devicesValidation, setDevicesValidation] = React.useState(false);

    const [t] = useTranslation();

    useEffect(() => {
        if (!editedGroup) return;

        deviceGroupService.getOne(editedGroup, sessionContext.token)
            .then(res => {
                if (!res) return;

                resetName(res.name);
                resetDescription(res.description?? "");
                resetLocation(res.location.uuid);
                setColor(res.color?? "none");
                resetActive(res.isActive);
                setRows(res.devices.map(device => device.uuid))
            })
            .catch(() => setAddDeviceGroupResponse({ status: 'error', message: "Couldn't fetch data" }))
    }, []);

    useEffect(() => {
        setAddDeviceGroupResponse(null)
    }, [name, description, location, color, active, rows]);

    useEffect(() => {
        setNameValidation(false)
    }, [name]);

    useEffect(() => {
        if (rows.filter(row => row === '').length === 0) setDevicesValidation(false);
    }, [rows]);

    useEffect(() => {
        if (!location) return;
        setLocationValidation(false);

        deviceService
            .getAll(sessionContext.token)
            .then(res => {
                setDevices(
                    res
                        .filter(device => device.location.uuid === location)
                        .map(({ uuid, name }) => ({uuid, name}))
                )
            })
            .catch(err => setAddDeviceGroupResponse({ status: 'error', message: "Couldn't fetch devices" }))

    }, [location]);

    const handleAddLine = async (): Promise<void> => {
        if (location === "") {
            setAddDeviceGroupResponse({ status: 'error', message: "You can add devices when location is chosen" })
            return;
        }

        if (devices.length > rows.length) setRows([...rows, ""]);
    };
    

    const handleRemoveLine = (index: number): void => {
        const newArray = [...rows];
        newArray.splice(index, 1);
        setRows([...newArray]);
    };

    const handleChangeSelect = (value: string, index: number): void => {
        const newArray = [...rows];
        newArray[index] = value;
        setRows([...newArray]);
    };

    const formValidate = (): boolean => {
        if (!name) {
            setAddDeviceGroupResponse({ status: 'error', message: 'Name is required!' });
            setNameValidation(true);
            return false;
        }

        if (!location) {
            setAddDeviceGroupResponse({ status: 'error', message: 'Location is required!' });
            setLocationValidation(true);
            return false;
        }

        if (rows.filter(row => row === '').length > 0) {
            setAddDeviceGroupResponse({ status: 'error', message: 'Devices must be chosen!' });
            setDevicesValidation(true);
            return false;
        }

        return true;
    }

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();
        
        setAddDeviceGroupResponse(null);

        if (!formValidate()) return;

        let reqBody: DeviceGroupCreateRequest = {
            name,
            locationUuid: location,
            isActive: active,
            devices: rows.map(uuid => ({ uuid })),
            color,
            description,
        };

        if (color === 'none') delete reqBody.color;

        if (description.length === 0) delete reqBody.description;

        try {
            if (!editedGroup) await deviceGroupService.createDeviceGroup(reqBody, sessionContext.token);
            else await deviceGroupService.updateDeviceGroup(editedGroup, reqBody, sessionContext.token);

            handleClose();
        } catch(e) {
            setAddDeviceGroupResponse({ status: 'error', message: 'Something went wrong' });
        }
    };

    return (
        <Container component="main" className={classes.main}>
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("Insert device group data")}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label={t("Name")}
                        name="name"
                        autoComplete="name"
                        autoFocus
                        error={nameValidation}
                        {...bindName}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="description"
                        label={t("Description")}
                        name="description"
                        autoComplete="description"
                        {...bindDescription}
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel id="location-select-label">{t("Choose location")}</InputLabel>
                        <Select
                            labelId="location-select-label"
                            id="location-select"
                            {...bindLocation}
                            disabled={rows.length > 0}
                            error={locationValidation}
                        >
                            {locations?.map((location, index) => (
                                <MenuItem key={index} value={location.uuid}>
                                    {location.name}
                                </MenuItem >
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <ColorPicker value={color} setValue={setColor} />
                    </FormControl>

                    <Grid container justifyContent="center">
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.formControl}
                                    control={<Switch {...bindActive} name="active-device-group" />}
                                    label={t("Active")}
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Box mt={2} />

                    <Grid container justifyContent="center">
                        {rows.length > 0 && <Grid item xs={8}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("Choose devices")}</TableCell>
                                            <TableCell >{t("Options")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <DeviceRow
                                                key={index}
                                                index={index}
                                                rows={rows}
                                                value={row}
                                                devices={devices}
                                                error={devicesValidation}
                                                handleRemoveLine={handleRemoveLine}
                                                handleChangeSelect={handleChangeSelect}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid> }
                        <Grid container item xs={4}
                            alignItems="center"
                            direction="column"
                        >
                            <Grid item >
                                <IconButton onClick={handleAddLine} size="large">
                                    <AddCircleIcon />
                                </IconButton>
                            </Grid>
                            <Grid item >
                                <Typography>
                                    {t("Add device")}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    {addDeviceGroupResponse ? <Box mt={2} mb={2}>

                        <Alert severity={addDeviceGroupResponse.status}>
                            {t(addDeviceGroupResponse.message)} 
                        </Alert>
                    
                    </Box> : <Box mt={2} />}
                    
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedGroup ? "Save" : "Add group")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}