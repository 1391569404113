import { apiUrl } from "../../config";

const sendEvacuationListEndpoint = (locationUuid: string, timezoneOffset?: number) => `${apiUrl}/evacuation-list/send-list?location=${locationUuid}${timezoneOffset ? `&timezoneOffset=${timezoneOffset}` : ""}`;
const getEvacuationListEmailsEndpoint = () => `${apiUrl}/evacuation-list/emails`;
const getEvacuationListEmailEndpoint = (uuid: string) => `${apiUrl}/evacuation-list/emails/${uuid}`;
const createEvacuationListEmailEndpoint = () => `${apiUrl}/evacuation-list/emails`;
const updateEvacuationListEmailEndpoint = (uuid: string) => `${apiUrl}/evacuation-list/emails/${uuid}`;
const deleteEvacuationListEmailEndpoint = (uuid: string) => `${apiUrl}/evacuation-list/emails/${uuid}`;

export {
    sendEvacuationListEndpoint,
    getEvacuationListEmailsEndpoint,
    getEvacuationListEmailEndpoint,
    createEvacuationListEmailEndpoint,
    updateEvacuationListEmailEndpoint,
    deleteEvacuationListEmailEndpoint,
};