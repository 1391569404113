import { apiUrl } from "../../config";

const getDevicesControllerEndpoint = () => `${apiUrl}/device-controllers`;
const getDeviceControllerEndpoint = (deviceId: string) => `${apiUrl}/device-controllers/${deviceId}`;
const createDeviceControllerEndpoint = () => `${apiUrl}/device-controllers`;
const updateDeviceControllerEndpoint = (deviceId: string) => `${apiUrl}/device-controllers/${deviceId}`;
const deleteDeviceControllerEndpoint = (deviceId: string) => `${apiUrl}/device-controllers/${deviceId}`;

export {
    getDevicesControllerEndpoint,
    getDeviceControllerEndpoint,
    createDeviceControllerEndpoint,
    updateDeviceControllerEndpoint,
    deleteDeviceControllerEndpoint
};