import { Card } from '@mui/material';

interface ColorSquareProps {
    color?: string;
    size?: "small" | "normal";
}

export const transparentBackgroundUrlBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAcSURBVChTY3iACv6jggGVhtIwAFUFAwMn/eABAPoLGLpCCvrHAAAAAElFTkSuQmCC";

export default function ColorSquare(props: ColorSquareProps) {
    const { color, size } = props;
    return (
        <Card sx={{
            backgroundColor: (!color || color === 'none') ? 'white' : `#${color}`,
            backgroundImage: (!color || color === 'none') ? `url(${transparentBackgroundUrlBase64})` : "none",
            height: size === 'small' ? 15 : 25,
            width: size === 'small' ? 15 : 25,
        }} />
    );
}