import React, { useEffect } from 'react';
import {
    Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { green } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

import { EventDetails, EventGetAllResponse, EventTypeResponse } from 'services/device-management/types';
import { addMinutes, format } from 'date-fns';
import TablePagination from 'components/TablePagination';

interface TypeChipProps {
    selected: boolean;
    type: EventTypeResponse;
    onSelect: (type: number) => void;
}

function TypeChip(props: TypeChipProps) {
    const { selected, type, onSelect } = props;
    const [t] = useTranslation();

    const handleSelect = () => {
        onSelect(type.eventTypeNumber);
    };

    function ChipStyleProps() {
        return selected ? { border: `2px solid ${green[500]}` } : {};
    }

    return (
        <Chip
            onClick={handleSelect}
            sx={{ m: 0.5, ...ChipStyleProps() }}
            label={t(type.eventTypeShortName)}
            id={type.eventTypeShortName}
        />
    );
}

interface Column {
    id: 'eventTypeShortName' | 'eventTypeName' | 'deviceName' | 'timestamp';
    label: string;
    minWidth?: number;
    align?: 'left' | 'center' | 'right';
    format?: (value: Date) => string;
}

const columns: Column[] = [
    { id: 'eventTypeShortName', label: 'Event short name' },
    { id: 'eventTypeName', label: 'Event name' },
    { id: 'deviceName', label: 'Device name' },
    {
        id: 'timestamp',
        label: 'Date of occurrence',
        format: d => format(addMinutes(d, d.getTimezoneOffset()), "yyyy-MM-dd HH:mm"),
    },
]

interface LogTableProps {
    rows: EventDetails[];
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    rowsPerPage: number;
    count: number;
}

function LogTable(props: LogTableProps) {
    const { rows, setRowsPerPage, setPage, page, rowsPerPage, count } = props;
    const [t] = useTranslation();

    const handleChangePage = (newPage: number) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    return (
        <Paper elevation={3}>
            <TableContainer sx={{ height: '100%' }}>
                <Table
                    sx={{ minWidth: 750, pl: 2, pr: 1 }}
                    aria-labelledby="devicesTable"
                    aria-label="devices table"
                    size='small'
                >
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{ minWidth: column.minWidth }}
                                >
                                    {t(column.label)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row, index) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={`${row.eventTypeShortName}${index}`}>
                            {columns.map((column) => {
                                const value = row[column.id];

                                return (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'object' ? column.format(value) : value}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow sx={{ height: 33 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[6, 10, 25]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

interface DashboardLocationLogsTableProps {
    rows?: EventGetAllResponse;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setSelectedLogs: React.Dispatch<React.SetStateAction<number[]>>;
    selectedLogs: number[];
}

export default function DashboardLocationLogsTable(props: DashboardLocationLogsTableProps) {
    const { rows, setRowsPerPage, setPage, selectedLogs, setSelectedLogs } = props;
    const [t] = useTranslation();

    useEffect(() => {
        if (!rows) return;

        const logTypesNumbers = rows.eventTypes.map(et => et.eventTypeNumber);
        const newSelectedLogs = selectedLogs.filter(l => logTypesNumbers.includes(l));

        if (
            newSelectedLogs.length === selectedLogs.length &&
            newSelectedLogs.every(log => selectedLogs.includes(log))
        ) return;

        setSelectedLogs(newSelectedLogs)
    }, [ rows ]);
    
    const onChipSelect = (type: number): void => {

        const foundIndex = selectedLogs.findIndex(log => log === type);
        if (foundIndex !== -1) {
            const newLogs = [...selectedLogs];
            newLogs.splice(foundIndex, 1);
            setSelectedLogs(newLogs);
        } else {
            setSelectedLogs([...selectedLogs, type]);
        }

        setPage(0);
    }

    return (
        <Grid container direction="column">
            <Grid item>
                {t("Logs")}
            </Grid>
            <Grid item container direction="column" spacing={1}>
                <Grid item container justifyContent="center">
                    {rows && rows.eventTypes.map((type, index) => (
                        <Grid item key={index}>
                            <TypeChip selected={selectedLogs.includes(type.eventTypeNumber)} type={type} onSelect={onChipSelect} />
                        </Grid>
                    ))}
                </Grid>
                <Grid item sx={{ maxWidth: '100%', mt: 1, width: "100%" }}>
                    {(!rows || (rows && rows.events.length === 0)) ? (
                        <Grid
                            container
                            justifyContent='center'
                        >
                            {t('No data')}
                        </Grid>
                    ) : (
                        <LogTable
                            rows={rows.events}
                            setRowsPerPage={setRowsPerPage}
                            setPage={setPage}
                            page={rows.page}
                            rowsPerPage={rows.pageSize}
                            count={rows.count}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
