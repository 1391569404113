import { genericGet } from "../genericService";
import { getMyPermissionsEndpoint, getPermissionsEndpoint } from "./endpoints";
import { PermissionGetAllResponse, PermissionGetMyResponse } from "./types";

const getPermissions = (token?: string): Promise<PermissionGetAllResponse[]> => {
    return genericGet<PermissionGetAllResponse[]>(getPermissionsEndpoint(), {}, token);
};

const getMyPermissions = (token?: string): Promise<PermissionGetMyResponse> => {
    return genericGet<PermissionGetMyResponse>(getMyPermissionsEndpoint(), {}, token);
};

export const permissionsService = {
    getPermissions,
    getMyPermissions,
}
