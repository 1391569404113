import { AlarmTableData } from 'services/alarms.service';
import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { green, red } from '@mui/material/colors';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof AlarmTableData;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Alarm name' },
  { id: 'event', numeric: false, disablePadding: false, label: 'Alarm event' },
  { id: 'devices', numeric: false, disablePadding: false, label: 'Devices' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof AlarmTableData) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof AlarmTableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const [t] = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          align={'center'}
          className={classes.actionsColumn}
        >
          {t("Actions")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actionsColumn: {
      width: "150px",
    },
  }),
);

interface AlarmsSettingsTableProps {
  rows: AlarmTableData[];
  handleClickEditOpen: (id: number) => void;
  handleClickDeleteOpen: (id: number) => void;
  others?: any;
}

export default function AlarmsSettingsTable(props: AlarmsSettingsTableProps) {
  const classes = useStyles();
  const { rows, handleClickDeleteOpen, handleClickEditOpen } = props;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof AlarmTableData>('name');
  const [t] = useTranslation();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AlarmTableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditAlarm = (e: React.MouseEvent<HTMLButtonElement>, row: AlarmTableData): void => {
    handleClickEditOpen(row.id);
  };

  const handleDeleteAlarm = (e: React.MouseEvent<HTMLButtonElement>, row: AlarmTableData): void => {
    handleClickDeleteOpen(row.id);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" id="alarmsTitle" component="div">
            {t("Alarms")}
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="alarms table"
            aria-label="alarms table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell component="th" scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.event}</TableCell>
                    <TableCell align="center">{row.devices}</TableCell>
                    <TableCell align="center">
                      <Typography
                        style={{ color: row.status === "Active" ? green[500] : red[500] }}
                      >
                        {row.status}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Grid container spacing={1}>
                        <Grid item xs>
                          <IconButton onClick={(event) => handleEditAlarm(event, row)} size="large">
                            <EditIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs>
                          <IconButton onClick={(event) => handleDeleteAlarm(event, row)} size="large">
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
