import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FlagIcon from './FlagIcon';
import { useTranslation } from 'react-i18next';
import { useSessionContext } from '../contexts/SessionContext';
import { authenticationService } from '../services/authentication.service';
import { Collapse, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { PermissionCategory } from 'services/account-management';
import Restricted from './Restricted';
import LazyLoadIcon from './LazyLoadIcon';

function ProfileMenu() {
  const [sessionContext, updateSessionContext] = useSessionContext();
  const [t, i18n] = useTranslation();

  const [languageOpen, setLanguageOpen] = useState(false);
  
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);
  const [showData, setShowData] = useState<boolean>(false);
  const openProfile = Boolean(anchorElProfile);

  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
    handleCloseProfile();
  }

  const handleMenuProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
    setShowData(true);
  }

  const handleCollapseLang = () => {
    setLanguageOpen(!languageOpen);
  }

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
    setLanguageOpen(false);
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenuProfile}
        color="inherit"
        size="large">
        <LazyLoadIcon iconName='AccountCircle' />
      </IconButton>
      {showData && (
        <>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElProfile}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openProfile}
            onClose={handleCloseProfile}
          >
            <MenuItem sx={{ py: 1 }}>
              {t('Signed in as')} {sessionContext.email}
            </MenuItem>

            <Divider sx={{ my: 1 }} />

            <MenuItem onClick={handleCollapseLang} sx={{ py: 1 }}>
              <ListItemText sx={{ flexGrow: 1 }}>{t("Choose language")}</ListItemText>
              <LazyLoadIcon iconName={languageOpen ? "ExpandLess" : "ExpandMore"} size='sm' />
            </MenuItem>

            <Collapse in={languageOpen} timeout="auto" unmountOnExit>
              <MenuItem onClick={() => changeLanguage('en')} sx={{ gap: 1, py: 1 }}>
                <ListItemIcon>
                  <FlagIcon name='en' />
                </ListItemIcon>
                <ListItemText primary={'English'} />
              </MenuItem>

              <MenuItem onClick={() => changeLanguage('pl')} sx={{ gap: 1, py: 1 }}>
                <ListItemIcon>
                  <FlagIcon name='pl' />
                </ListItemIcon>
                <ListItemText primary={'Polski'} />
              </MenuItem>
            </Collapse>

            <Restricted to={PermissionCategory.Settings}>
              <Divider sx={{ my: 1 }} />

              <MenuItem component={Link} to={"/settings"} onClick={handleCloseProfile} sx={{ py: 1 }}>
                <ListItemIcon> <LazyLoadIcon iconName='Settings' /> </ListItemIcon>
                <ListItemText primary={t('Settings')} />
              </MenuItem>
            </Restricted>

            <Divider sx={{ my: 1 }} />

            <MenuItem onClick={() => authenticationService.logout([sessionContext, updateSessionContext])} sx={{ py: 1 }}>
              <ListItemIcon> <LazyLoadIcon iconName='Lock' /> </ListItemIcon>
              <ListItemText primary={t('Log out')} />
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
}

export default ProfileMenu;
