// HOLIDAY
export type Holiday = {
  uuid: string;
  name: string;
  description?: string;
  date: string;
}

export type HolidayCreateRequest = {
  name: Holiday["name"];
  description?: Holiday["description"];
  date: Holiday["date"];
}

export type HolidayCreateResponse = {
  uuid: Holiday["uuid"];
  name: Holiday["name"];
  description?: Holiday["description"];
  date: Holiday["date"];
}

export type HolidayUpdateResponse = {
  uuid: Holiday["uuid"];
  name: Holiday["name"];
  description?: Holiday["description"];
  date: Holiday["date"];
}

export type HolidayGetResponse = {
  uuid: Holiday["uuid"];
  name: Holiday["name"];
  description?: Holiday["description"];
  date: Holiday["date"];
}

export type HolidayGetAllResponse = {
  uuid: Holiday["uuid"];
  name: Holiday["name"];
  description?: Holiday["description"];
  date: Holiday["date"];
}

export type HolidayGetAllNamesResponse = {
  uuid: Holiday["uuid"];
  name: Holiday["name"];
}

// SCHEDULES
export type Schedule = {
  uuid: string;
  name: string;
  description?: string;
  start: string;
  end: string;
  days: Days;
  verificationMethod: VerificationMethods;
}

export type ScheduleCreateRequest = {
  name: Schedule["name"];
  description?: Schedule["description"];
  start: Schedule["start"];
  end: Schedule["end"];
  days: Schedule["days"];
  verificationMethod: Schedule["verificationMethod"];
}

export type ScheduleCreateResponse = {
  uuid: Schedule["uuid"];
  name: Schedule["name"];
  description?: Schedule["description"];
  start: Schedule["start"];
  end: Schedule["end"];
  days: Schedule["days"];
  verificationMethod: Schedule["verificationMethod"];
}

export type ScheduleUpdateResponse = {
  uuid: Schedule["uuid"];
  name: Schedule["name"];
  description?: Schedule["description"];
  start: Schedule["start"];
  end: Schedule["end"];
  days: Schedule["days"];
  verificationMethod: Schedule["verificationMethod"];
}

export type ScheduleGetResponse = {
  uuid: Schedule["uuid"];
  name: Schedule["name"];
  description?: Schedule["description"];
  start: Schedule["start"];
  end: Schedule["end"];
  days: Schedule["days"];
  verificationMethod: Schedule["verificationMethod"];
}

export type ScheduleGetAllResponse = {
  uuid: Schedule["uuid"];
  name: Schedule["name"];
  description?: Schedule["description"];
  start: Schedule["start"];
  end: Schedule["end"];
  days: Schedule["days"];
  verificationMethod: Schedule["verificationMethod"];
}

export type ScheduleGetAllNamesResponse = {
  uuid: Schedule["uuid"];
  name: Schedule["name"];
}

export type ScheduleGetForTableResponse = {
  uuid: Schedule["uuid"];
  name: Schedule["name"];
  description: string;
  start: Schedule["start"];
  end: Schedule["end"];
  days: string[];
  verificationMethod: string;
}

export type ScheduleEditResponse = {
  uuid: Schedule["uuid"];
  name: Schedule["name"];
  description?: Schedule["description"];
  start: Schedule["start"];
  end: Schedule["end"];
  days: number[];
  verificationMethod: string;
}

export enum Days {
  Sunday = 0x01,
  Monday = 0x02,
  Tuesday = 0x04,
  Wednesday = 0x08,
  Thursday = 0x10,
  Friday = 0x20,
  Saturday = 0x40,
  Holidays = 0x80
}

export enum VerificationMethods {
  Card = 0x01,
  CardAndPin = 0x02,
  OpenAtTime = 0x03,
  Pin = 0x08,
  CardOrPin = 0x09,
}
