import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: theme.palette.error.main,
    },
    evacuationLink: {
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
}));

interface EvacuationDialogProps {
    handleClose: () => void;
    handleEvacuate: () => void;
};

export default function EvacuationDialog(props: EvacuationDialogProps) {
    const classes = useStyles();
    const [t] = useTranslation();
    const { handleClose, handleEvacuate } = props;

    const evacuationConditions: string[] = [
        "Evacuation condition 1",
        "Evacuation condition 2",
        "Evacuation condition 3",
        "Evacuation condition 4",
    ];

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("You are about to evacuate")}
                    </Typography>
                </Box>
                <Box pb={4} mt={2}>
                    <Typography component="ol" variant="h5">
                        {evacuationConditions.map((item, index) => (
                            <li key={index}>
                                {t(item)}
                            </li>
                        ))}
                    </Typography>
                </Box>

                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("The list of people to evacuate will be sent to email addresses entered in")}
                    </Typography>
                </Box>

                <Box pb={4} mt={2}>
                    <Typography component="ol" variant="h5">
                        <Link to={`/settings/evacuation`} color="inherit" component={RouterLink} className={classes.evacuationLink}>
                            {t("Settings -> Evacuation")}
                        </Link>
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={handleEvacuate}
                        >
                            {t("Evacuate")}
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={handleClose}
                        >
                            {t("Cancel")}
                        </Button>
                    </Grid>
                </Grid>



            </div>
        </Container>
    );
}