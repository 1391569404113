import { apiUrl } from "../../config";

const getAccessPermissionsEndpoint = () => `${apiUrl}/access-permissions`;
const createAccessPermissionsEndpoint = () => `${apiUrl}/access-permissions`;
const deleteAccessPermissionsEndpoint = () => `${apiUrl}/access-permissions`;

export {
  getAccessPermissionsEndpoint,
  createAccessPermissionsEndpoint,
  deleteAccessPermissionsEndpoint,
};
