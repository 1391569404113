export interface AlarmData {
    "id": number;
    "name": string;
    "event": string;
    "devices": string[];
    "status": string;
};

export interface AlarmTableData {
    "id": number;
    "name": string;
    "event": string;
    "devices": string;
    "status": string;
};

const rows: AlarmData[] = [
    {
        id: 1,
        event: "No communication",
        name: "Alarm 1",
        devices: ["Zakład 1", "Drzwi główne"],
        status: "Active"
    },
    {
        id: 2,
        event: "Unauthroized entry",
        name: "Alarm 2",
        devices: ["Zakład 1"],
        status: "Active"
    },
    {
        id: 3,
        event: "Door open",
        name: "Alarm 3",
        devices: ["Zakład 1", "Drzwi główne", "Drzwi boczne", "Hala 1"],
        status: "Not active"
    },
];

const getAll = (): AlarmData[] => {
    return rows;
};

const getAllTable = (term?: string): AlarmTableData[] => {
    const newRows = term === "" || term === undefined ? [...rows] : [...rows].filter(row => row.name.toLowerCase().includes(term.toLowerCase()));
    return newRows.map(row => {
        let truncatedDevices = "";
        const length = row.devices.length;
        if (length > 1) {
            truncatedDevices = `${row.devices[0]} ... +${length - 1}`
        } else {
            truncatedDevices = row.devices[0];
        };
        return { ...row, devices: truncatedDevices };
    });
};

const getJustNames = (ids: number[]): string => {
    return rows.filter(row => ids.includes(row.id)).map(row => row.name).join(', ');
};

const deleteAlarms = (ids: number[]): void => {
    console.log(rows.filter(row => ids.includes(row.id)));
};

export const alarmsService = {
    getAll,
    getAllTable,
    getJustNames,
    deleteAlarms,
};