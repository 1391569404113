import { apiUrl } from "../../config";

const getEmployeesEndpoint = (locationUuid?: string) => `${apiUrl}/employees${locationUuid ? `?locationUuid=${locationUuid}` : ""}`;
const getEmployeeEndpoint = (uuid: string) => `${apiUrl}/employees/${uuid}`;
const createEmployeesEndpoint = () => `${apiUrl}/employees`;
const updateEmployeesEndpoint = (uuid: string) => `${apiUrl}/employees/${uuid}`;
const deleteEmployeesEndpoint = (uuid: string) => `${apiUrl}/employees/${uuid}`;
const getRandomPinEndpoint = () => `${apiUrl}/pin/random`;

export {
  getEmployeesEndpoint,
  getRandomPinEndpoint,
  getEmployeeEndpoint,
  createEmployeesEndpoint,
  updateEmployeesEndpoint,
  deleteEmployeesEndpoint,
};
