import React, { FormEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { VariantType, useSnackbar } from 'notistack';
import { Box, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { permissionsService, rolesService, Permission, RoleCreateRequest, RoleGetResponse } from 'services/account-management';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

interface AddRoleFormProps {
    editedRole?: string;
    handleClose: () => void;
};

export default function AddRoleForm(props: AddRoleFormProps) {
    const classes = useStyles();
    const [sessionContext] = useSessionContext();
    const { editedRole, handleClose } = props;
    const [role, setRole] = React.useState<RoleGetResponse>({ name: "", uuid: "", category: "", permissions: [] });
    const [permissions, setPermissions] = React.useState<Permission[]>([]);
    const { value: roleName, setValue: setRoleName, bind: bindRoleName } = useInput("");
    const [ roleNameBeforeEdit, setRoleNameBeforeEdit ] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();
    const [ roleNameError, setRoleNameError ] = React.useState<boolean>(false);

    const [t] = useTranslation();

    useEffect(() => {
        if (editedRole) {
            rolesService.getRole(editedRole, sessionContext.token)
                .then(res => {
                    setRole(res);
                    setPermissions(res.permissions);
                    setRoleName(res.name);
                    setRoleNameBeforeEdit(res.name);
                })
                .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
        } else {
            permissionsService.getPermissions(sessionContext.token)
                .then(res => {
                    role.permissions = res.map(p => { return { ...p, isGranted: false } });
                    setRole(role);
                    setPermissions(role.permissions);
                })
                .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
        }
    }, [editedRole]);

    const validationOk = async (): Promise<boolean> => {
        setRoleNameError(false);

        if (!roleName) {
            handleSnackBar("Insert role name", "error");
            setRoleNameError(true);
            return false;
        }

        const allRolesName = await rolesService.getRoles(sessionContext.token).then(res => res.map(r => r.name.toLowerCase()));

        if (roleName !== roleNameBeforeEdit && allRolesName.includes(roleName.toLowerCase())) {
            handleSnackBar("The role name already existed", "error");
            setRoleNameError(true);
            return false;
        }
        
        return true;
    }

    const handleOnSubmit = async(e: FormEvent): Promise<void> => {
        e.preventDefault();

        if (!(await validationOk())) return;

        const newRole: RoleCreateRequest = {
            name: roleName,
            grantedPermissions: role.permissions.filter(r => r.isGranted).map(r => { return { uuid: r.uuid } })
        };
        if (editedRole) {
            rolesService.updateRole(editedRole, newRole, sessionContext.token)
                .then(res => {
                    handleSnackBar(ResponseSuccessMessages.EDIT, "success");
                    handleClose();
                })
                .catch(error => handleSnackBar(getCreateErrorText(error), 'error'));
        } else {
            rolesService.createRole(newRole, sessionContext.token)
                .then(res => {
                    handleSnackBar(ResponseSuccessMessages.ADD, "success");
                    handleClose();
                })
                .catch(error => handleSnackBar(getCreateErrorText(error), 'error'));
        }
    }

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newRole = { ...role };
        if (role) {
            if (e.target.checked) {
                const permission = newRole.permissions?.find(p => p.uuid === e.target.name);
                if (permission)
                    permission.isGranted = true;
            } else {
                const permission = newRole.permissions?.find(p => p.uuid === e.target.name);
                if (permission)
                    permission.isGranted = false;
            }
            setRole(newRole);
        }
    };

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} >
                    <Typography component="h1" variant="h5">
                        {t("Insert role data")}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="roleName"
                        label={t("Role name")}
                        name="roleName"
                        autoComplete="roleName"
                        autoFocus
                        error={roleNameError}
                        {...bindRoleName}
                    />

                    <Box mt={2} />
                    <TableContainer component={Paper}>
                        <Table aria-label="role table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>{t("Choose permission")}</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permissions.map(permission => (
                                    <TableRow key={permission.uuid}>
                                        <TableCell>

                                            <Switch
                                                color="primary"
                                                name={permission.uuid}
                                                checked={permission.isGranted}
                                                onChange={handleSwitchChange} />

                                        </TableCell>
                                        <TableCell width="100%" align="left">{t(permission.name)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedRole ? "Save" : "Add role")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container >
    );
}