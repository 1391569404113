import { apiUrl } from "../../config";

const getGuestTypesEndpoint = () => `${apiUrl}/guestTypes`;
const getGuestTypeEndpoint = (uuid: string) => `${apiUrl}/guestTypes/${uuid}`;
const createGuestTypesEndpoint = () => `${apiUrl}/guestTypes/add`;
const updateGuestTypesEndpoint = (uuid: string) => `${apiUrl}/guestTypes/${uuid}`;
const deleteGuestTypesEndpoint = (uuid: string) => `${apiUrl}/guestTypes/${uuid}`;

export {
  getGuestTypesEndpoint,
  getGuestTypeEndpoint,
  createGuestTypesEndpoint,
  updateGuestTypesEndpoint,
  deleteGuestTypesEndpoint,
};