import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createDeviceGroupEndpoint, deleteDeviceGroupEndpoint, getDeviceGroupEndpoint, getDeviceGroupsEndpoint, updateDeviceGroupEndpoint } from "./endpoints";
import { DeviceGroupCreateRequest, DeviceGroupCreateResponse, DeviceGroupGetAllResponse, DeviceGroupGetAllToTable, DeviceGroupGetResponse, DeviceGroupUpdateRequest, DeviceGroupUpdateResponse } from "./types";

export interface DeviceGroupData {
  id: string;
  color: string;
  name: string;
  description: string;
  controllers: string;
  status: string;
};

const getAll = async (token?: string): Promise<DeviceGroupGetAllResponse[]> => {
  return genericGet<DeviceGroupGetAllResponse[]>(getDeviceGroupsEndpoint(), {}, token);
}

const getOne = async (uuid: string, token?: string): Promise<DeviceGroupGetResponse | undefined> => {
  return genericGet<DeviceGroupGetResponse>(getDeviceGroupEndpoint(uuid), {}, token);
}

const deleteOne = (uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
  return genericDelete(deleteDeviceGroupEndpoint(uuid), token);
}

const deleteDeviceGroups = async (uuids: string[], token?: string): Promise<{status: number, statusText: string}> => {
  let status = 200;
  let statusText = "Success"

  await Promise.all(

    uuids.map(async uuid => deleteOne(uuid, token))

  ).catch((e: Error) => {
    status = Number(e.message);
    statusText = e.name;
  })
  
  return { status, statusText }
}

const createDeviceGroup = (deviceGroup: DeviceGroupCreateRequest, token?: string): Promise<DeviceGroupCreateResponse> => {
  return genericPost<DeviceGroupCreateResponse>(createDeviceGroupEndpoint(), deviceGroup, token);
};

const updateDeviceGroup = (uuid: string, deviceGroup: DeviceGroupUpdateRequest, token?: string): Promise<DeviceGroupUpdateResponse> => {
  return genericPut<DeviceGroupUpdateResponse>(updateDeviceGroupEndpoint(uuid), deviceGroup, token);
};

const getAllToTable = (token?: string): Promise<DeviceGroupGetAllToTable[]> => {
  return getAll(token)
          .then(res => res.map(({ uuid, name, location }) => ({ uuid, name, color: location.color })))
}

export const deviceGroupService = {
  getAll,
  getOne,
  deleteDeviceGroups,
  createDeviceGroup,
  updateDeviceGroup,
  getAllToTable,
};
