import { apiUrl } from "../../config";

const getLocationsEndpoint = () => `${apiUrl}/locations`;
const createLocationEndpoint = () => `${apiUrl}/locations`;
const getLocationEndpoint = (uuid: string) => `${apiUrl}/locations/${uuid}`;
const updateLocationEndpoint = (uuid: string) => `${apiUrl}/locations/${uuid}`;
const deleteLocationEndpoint = (uuid: string) => `${apiUrl}/locations/${uuid}`;

export {
    getLocationsEndpoint,
    getLocationEndpoint,
    createLocationEndpoint,
    updateLocationEndpoint,
    deleteLocationEndpoint
};