import { Box, Button, FormControl, FormLabel, Grid, IconButton, Input, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import { WorktimeShiftScheduleCreateRequest } from "services/setting-management/types";
import LocationSelect from "components/LocationSelect";
import { useEffect, useState } from "react";
import { EmployeeGetAllResponse, employeesService } from "services/company-management";
import { getGetErrorText } from "services/genericService";
import { VariantType, useSnackbar } from "notistack";
import { useSessionContext } from "contexts/SessionContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface AddWorkTimeStepThreeProps {
    departmentsError?: boolean;
    bindDepartmentsSelectedUuid: {
        value: string[];
        setValue: React.Dispatch<React.SetStateAction<string[]>>;
        onChange: (event: SelectChangeEvent<any>) => void;
    };
    bindSelectedEmployeesUuid: {
        value: string[];
        setValue: React.Dispatch<React.SetStateAction<string[]>>;
        onChange: (event: SelectChangeEvent<any>) => void;
    };
}

function AddWorkTimeStepThree(props: AddWorkTimeStepThreeProps) {
    const { bindDepartmentsSelectedUuid, bindSelectedEmployeesUuid } = props;
    const departmentsError = props.departmentsError ?? false;

    const [t] = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [sessionContext] = useSessionContext();
    const [ employees, setEmployees ] = useState<EmployeeGetAllResponse[]>([]);
    const selectedEmployeesUuid = bindSelectedEmployeesUuid.value;

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        employeesService.getAll(sessionContext.token)
            .then(res => res.sort((a, b) => a.lastName > b.lastName ? 1 : -1))
            .then(res => setEmployees(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };
    
    return (
        <>
            <Grid container spacing={4} justifyContent="flex-start">
                <Grid item xs={12}>
                    <Box mt={2}>
                        <LocationSelect
                            type="department"
                            multiple
                            noOverrideDefaultValues
                            error={departmentsError}
                            input={<Input />}
                            fullWidth
                            {...bindDepartmentsSelectedUuid}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={2}>
                        <FormControl fullWidth>
                            <InputLabel id="employee-select-label">{t("Choose employees")}</InputLabel>
                            <Select
                                labelId="employee-select-label"
                                id="employee-select"
                                multiple
                                input={<Input />}
                                MenuProps={MenuProps}
                                sx={{ minWidth: 200 }}
                                {...bindSelectedEmployeesUuid}
                            >
                                {employees.map((employee, index) => (
                                    <MenuItem
                                        key={employee.uuid} value={employee.uuid}
                                        sx={{
                                            fontWeight: selectedEmployeesUuid.indexOf(employee.uuid) === -1 ? 400 : 500
                                        }}
                                    >
                                        {employee.lastName} {employee.firstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default AddWorkTimeStepThree;
