import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, RouteComponentProps } from 'react-router-dom';

import GuestsSettings from './components/guest/GuestsSettings';
import SystemSettings from './components/SystemSettings';
import AlarmsSettings from './components/AlarmsSettings';
import EvacuationSettings from './components/evacuation/EvacuationSettings';
import ImportSettings from './components/ImportSettings';
import WorktimeSettings from './components/worktime/WorktimeSettings';
import Restricted from 'components/Restricted';
import { PermissionCategory } from 'services/account-management';

interface MatchParams {
    type: string;
};

interface SettingsSecondaryViewProps extends RouteComponentProps<MatchParams> {
};


export default function SettingsSecondaryView(props: SettingsSecondaryViewProps) {
    const [t] = useTranslation();

    const getComponent = (): JSX.Element => {
        switch(props.match.params.type) {
            case "system": return <SystemSettings />
            case "guests": return <GuestsSettings />
            case "alarms": return <AlarmsSettings />
            case "evacuation": return <EvacuationSettings />
            // case "import": return <ImportSettings />
            case "work-time": return <WorktimeSettings />
            default: return <React.Fragment />
        }
    };

    return (
        <Restricted to={PermissionCategory.Settings}>
            <Box m={3}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Button
                            component={Link}
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                            to="/settings">
                            {t("Back")}
                        </Button>
                    </Grid>
                    <Grid item >
                        {getComponent()}         
                    </Grid>
                </Grid>
            </Box>
        </Restricted>
    );
}