import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createDeviceControllerEndpoint, deleteDeviceControllerEndpoint, getDeviceControllerEndpoint, getDevicesControllerEndpoint, updateDeviceControllerEndpoint } from "./endpoints";
import { Device, DeviceControllerCreateRequest, DeviceControllerCreateResponse, DeviceControllerGetAllResponse, DeviceControllerGetAllSerialNumbers, DeviceControllerGetForTableResponse, DeviceControllerGetNamesResponse, DeviceControllerGetResponse, DeviceControllerUpdateRequest, DeviceControllerUpdateResponse, DeviceCreate } from "./types";

const getAll = async (token?: string): Promise<DeviceControllerGetAllResponse[]> => {
    return genericGet<DeviceControllerGetAllResponse[]>(getDevicesControllerEndpoint(), {}, token);
}

const getOne = async (uuid: string, token?: string): Promise<DeviceControllerGetResponse> => {
    return genericGet<DeviceControllerGetResponse>(getDeviceControllerEndpoint(uuid), {}, token);
}

const getNames = async (token?: string): Promise<DeviceControllerGetNamesResponse[]> => {
    const results = await genericGet<DeviceControllerGetNamesResponse[]>(getDevicesControllerEndpoint(), {}, token);
    return results.map(row => ({ uuid: row.uuid, name: row.name }));
};

const createDevice = (device: DeviceControllerCreateRequest, token?: string): Promise<DeviceControllerCreateResponse> => {
    return genericPost<DeviceControllerCreateResponse>(createDeviceControllerEndpoint(), device, token);
};

const updateDevice = (deviceId: string, device: DeviceControllerUpdateRequest, token?: string): Promise<DeviceControllerUpdateResponse> => {
    return genericPut<DeviceControllerUpdateResponse>(updateDeviceControllerEndpoint(deviceId), device, token);
};

const deleteDevice = (deviceId: string, token?: string): Promise<{}> => {
    return genericDelete(deleteDeviceControllerEndpoint(deviceId), token);
};

const getAllForTable = async (token?: string): Promise<DeviceControllerGetForTableResponse[]> => {
    return genericGet<DeviceControllerGetAllResponse[]>(getDevicesControllerEndpoint(), {}, token)
        .then(res => res.filter(dc => dc.isActivated))
        .then(res => {
            return convertGetAllToTable(res);
        });
};

const getAllSerialNumbersToSelect = async (token?: string): Promise<DeviceControllerGetAllSerialNumbers[]> => {
    return genericGet<DeviceControllerGetAllResponse[]>(getDevicesControllerEndpoint(), {}, token)
        .then(res => convertGetAllSerialNumberSelect(res));
};

const getOneForEdit = (uuid: string, token?: string): Promise<DeviceControllerCreateRequest> => {
    return genericGet<DeviceControllerGetResponse>(getDeviceControllerEndpoint(uuid), {}, token)
        .then(res => {
            return convertGetOneToCreate(res);
        });
}

const convertGetAllToTable = (list: DeviceControllerGetAllResponse[]): DeviceControllerGetForTableResponse[] => {
    const res: DeviceControllerGetForTableResponse[] = [];
    list.map(device => {
        res.push({
            uuid: device.uuid,
            serialNumber: device.serialNumber,
            description: device.description ? device.description : 'No description',
            type: device.type,
            location: device.location?.name?? "",
            status: device.status ? 'Online' : 'Offline',
        });
    });
    return res;
}

const convertGetAllSerialNumberSelect = (list: DeviceControllerGetAllResponse[]): DeviceControllerGetAllSerialNumbers[] => {
    return list.map((device): DeviceControllerGetAllSerialNumbers => ({
        uuid: device.uuid,
        serialNumber: device.serialNumber,
        isActivated: device.isActivated,
    }))
}

const convertGetOneToCreate = (deviceController: DeviceControllerGetResponse): DeviceControllerCreateRequest => {
    const newDeviceController: DeviceControllerCreateRequest = {
        locationUuid: deviceController?.location?.uuid ?? "",
        description: deviceController.description ? deviceController.description : '',
        evacuationListEnabled: deviceController.evacuationListEnabled,
        isWorkTimeReportingEnabled: deviceController.isWorkTimeReportingEnabled,
        devices: [
            ...deviceController.devices.map((device: Device, index: number) => convertDeviceToCreate(device, index))
        ]
    }
    return newDeviceController;
}

const convertDeviceToCreate = (device: Device, index: number): DeviceCreate => {
    return {
        name: device.name,
        deviceGroupUuid: undefined,
        doorUnlockDuration: device.doorUnlockDuration?? 3,
        alarmDuration: device.alarmDuration?? 5,
        fireAlarmDuration: device.fireAlarmDuration?? 0,
        openTimeoutEnabled: device.openTimeoutEnabled?? false,
        openTimeout: device.openTimeout?? 10,
        securityPinEnabled: device.securityPinEnabled?? false,
        securityPin: device.securityPin?? "",
        deviceAccessSchedules: device.deviceAccessSchedules.map(sch => {
            return { uuid: sch.uuid };
        }),
        order: device.order?? index
    }
}

export const deviceControllerService = {
    getAll,
    getOne,
    getNames,
    createDevice,
    updateDevice,
    deleteDevice,
    getAllForTable,
    getAllSerialNumbersToSelect,
    getOneForEdit,
}