import { apiUrl } from "../../config";

const getWorktimeSchedulesEndpoint = () => `${apiUrl}/worktime-schedules`;
const getWorktimeScheduleEndpoint = (uuid: string) => `${apiUrl}/worktime-schedules/${uuid}`;
const createWorktimeScheduleEndpoint = () => `${apiUrl}/worktime-schedules`;
const updateWorktimeScheduleEndpoint = (uuid: string) => `${apiUrl}/worktime-schedules/${uuid}`;
const deleteWorktimeScheduleEndpoint = (uuid: string) => `${apiUrl}/worktime-schedules/${uuid}`;

const getEmployeeWorktimeScheduleEndpoint = (uuid: string) => `${apiUrl}/employee/${uuid}/worktime-schedules`;
const updateEmployeeWorktimeScheduleEndpoint = (uuid: string) => `${apiUrl}/employee/${uuid}/worktime-schedules`;

export {
    getWorktimeSchedulesEndpoint,
    getWorktimeScheduleEndpoint,
    createWorktimeScheduleEndpoint,
    updateWorktimeScheduleEndpoint,
    deleteWorktimeScheduleEndpoint,
    getEmployeeWorktimeScheduleEndpoint,
    updateEmployeeWorktimeScheduleEndpoint,
};