import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar, VariantType } from 'notistack';

import DeleteDialog from 'components/DeleteDialog';
import { departmentsService } from 'services/company-management/departments.service';
import { useSessionContext } from 'contexts/SessionContext';
import { DepartmentGetDataShortResponse } from 'services/company-management';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import DepartmentsTable from './DepartmentsTable';
import AddDepartmentForm from './AddDepartmentForm';
import DialogOverlay from 'components/DialogOverlay';

export default function MyCompanyDepartmentsTab() {
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [departmentId, setDepartmentId] = React.useState<string>('')
    const [departments, setDepartments] = React.useState<DepartmentGetDataShortResponse[]>([]);
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        refreshList();
    }, []);

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickDeleteOpen = (id: string): void => {
        setDepartmentId(id);
        setDeleteOpen(true);
    };

    const handleClickEditOpen = (id: string): void => {
        setDepartmentId(id);
        setEditOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        refreshList();
    };

    const handleDelete = () => {
        departmentsService.deleteDepartment(departmentId, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
            })
            .catch((err: Error) => handleSnackBar(getDeleteErrorText(parseInt(err.message)), "error"));
    };

    const refreshList = () => {
        departmentsService.getDataShort(sessionContext.token)
            .then(res => {
                setDepartments(res);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    };

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add department")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Box mt={2}>
                <DepartmentsTable
                    rows={departments}
                    handleEdit={handleClickEditOpen}
                    handleDeleteDepartment={handleClickDeleteOpen}
                />
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add department")}>
                <AddDepartmentForm handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit department")}>
                <AddDepartmentForm editedDepartmentId={departmentId} handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete department")}>
                <DeleteDialog type="department" names={[departments.find(dept => dept.id === departmentId)?.name ?? '']} handleDelete={handleDelete} handleClose={handleClose} />
            </DialogOverlay>

        </React.Fragment>
    );
}