import React, { FormEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { VariantType, useSnackbar } from 'notistack';
import { Box, Grid } from '@mui/material';

import { useInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import { evacuationListEmailService } from 'services/setting-management/evacuationList.service';
import { getCreateErrorText, ResponseSuccessMessages } from 'services/genericService';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

interface AddEvacuationEmailFormProps {
    editedEmail?: string;
    handleClose: () => void;
};

export default function AddEvacuationEmailForm(props: AddEvacuationEmailFormProps) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [sessionContext] = useSessionContext();
    const { editedEmail, handleClose } = props;
    const { value: email, setValue: setEmail, bind: bindEmail } = useInput("");
    const [ emailError, setEmailError ] = React.useState<boolean>(false);

    const [t] = useTranslation();

    useEffect(() => {
        if (editedEmail) {
            evacuationListEmailService.getEvacuationListEmail(editedEmail, sessionContext.token)
                .then(res => setEmail(res.email))
                .catch(err => 
                    handleSnackBar(getCreateErrorText(err), 'error')
                )
        }
    }, [editedEmail]);

    const validationOk = async (): Promise<boolean> => {
        setEmailError(false);

        const mailFormat = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!mailFormat.test(email)) {
            setEmailError(true);
            handleSnackBar("Wrong email address", 'error')
            return false;
        }
        
        return true;
    }

    const handleOnSubmit = async(e: FormEvent): Promise<void> => {
        e.preventDefault();

        if (!(await validationOk())) return;

        const newEmail = { email }

        if (editedEmail) {
            evacuationListEmailService.updateEvacuationListEmail(newEmail, editedEmail, sessionContext.token)
                .then(() => {
                    handleSnackBar(ResponseSuccessMessages.EDIT, "success");
                    handleClose();
                })
                .catch(error => {
                    if (error === 409 || error?.status === 409) {
                        setEmailError(true);
                        handleSnackBar("Email already address", 'error')
                    }
                    else handleSnackBar(getCreateErrorText(error), 'error')
                });
        } else {
            evacuationListEmailService.createEvacuationListEmail(newEmail, sessionContext.token)
                .then(() => {
                    handleSnackBar(ResponseSuccessMessages.ADD, "success");
                    handleClose();
                })
                .catch(error => {
                    if (error === 409 || error?.status === 409) {
                        setEmailError(true);
                        handleSnackBar("Email already address", 'error')
                    }
                    else handleSnackBar(getCreateErrorText(error), 'error')
                });
        }
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} >
                    <Typography component="h1" variant="h5">
                        {t("Insert e-mail")}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="emailName"
                    label={t("E-mail")}
                    name="emailName"
                    autoComplete="emailName"
                    autoFocus
                    error={emailError}
                    {...bindEmail}
                />
                  <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedEmail ? "Save" : "Add e-mail")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container >
    );
}