export type BasicReportProps<T> = {
  locationUuid: string;
  filter: string;
  employeeFilter?: string;
  sortOrder: 'asc' | 'desc';
  sortOrderBy: T;
  startDate: Date | null;
  endDate: Date | null;
  token?: string;
}

export type GetReportProps<T> = BasicReportProps<T> & {
  page: number;
  pageSize: number;
}

export type DownloadReportProps<T> = BasicReportProps<T> & {
  type?: 'csv' | 'xlsx';
  lang?: string;
}

export interface ReportPaginationData<T> {
  page: number;
  pageSize: number;
  count: number;
  sortOrder: 'asc' | 'desc';
  sortOrderBy: number;
  rows: T[];
}

export enum ReportEventsSortOrderBy {
  deviceName = 1,
  timestamp = 2,
  eventTypeName = 3,
  employeeId = 4,
  employeeName = 5,
  entryStatus = 6,
}

export enum ReportWorkTimeSortOrderBy {
  employeeId = 1,
  employeeName = 2,
  employeeDepartment = 3,
  day = 4,
  entranceTime = 5,
  exitTime = 6,
  normalTime = 7,
  weekDay = 9,
}
