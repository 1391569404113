import { apiUrl } from "../../config";

const getDepartmentsEndpoint = () => `${apiUrl}/departments`;
const getDepartmentEndpoint = (uuid: string) => `${apiUrl}/departments/${uuid}`;
const createDepartmentEndpoint = () => `${apiUrl}/departments`;
const updateDepartmentEndpoint = (uuid: string) => `${apiUrl}/departments/${uuid}`;
const deleteDepartmentEndpoint = (uuid: string) => `${apiUrl}/departments/${uuid}`;

export {
    getDepartmentsEndpoint,
    getDepartmentEndpoint,
    createDepartmentEndpoint,
    updateDepartmentEndpoint,
    deleteDepartmentEndpoint
};