import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { useCheckboxInput, useInput, useNumberInput } from 'hooks/input-hook';
import AddAlarmFormDeviceTable from './AddAlarmFormDeviceTable';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        formContainer: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        secondPaper: {
            padding: theme.spacing(2),
            width: "100%",
        },
        title: {
            width: "100%",
            color: "white",
            padding: theme.spacing(2),
            textAlign: "center",
        },
        label: {
            paddingTop: theme.spacing(6),
        },
    }));

interface AddAlarmFormProps {
    alarmId?: number;
    handleClose: () => void;
}

export default function AddAlarmForm(props: AddAlarmFormProps) {
    const classes = useStyles();
    const { alarmId, handleClose } = props;
    const [selected, setSelected] = React.useState<string[]>([]);
    const { value: alarmName, bind: bindAlarmName } = useInput("");
    const { value: activeChecked, bind: bindActiveChecked } = useCheckboxInput(true);
    const [event, setEvent] = React.useState<string>("noCommunication");
    const { value: emailList, bind: bindEmailList } = useInput("");
    const { value: emailText, bind: bindEmailText } = useInput("");
    const { value: alarmDelayedChecked, bind: bindAlarmDelayedChecked } = useCheckboxInput(false);
    const { value: alarmDelayedBy, bind: bindAlarmDelayedBy } = useNumberInput(10, 1, 60);
    const { value: sendMessageEveryChecked, bind: bindSendMessageEveryChecked } = useCheckboxInput(false);
    const { value: sendMessageEveryInterval, bind: bindSendMessageEveryInterval } = useNumberInput(1, 1, 120);
    const { value: notificationAmountChecked, bind: bindNotificationAmountChecked } = useCheckboxInput(false);
    const { value: notificationNumberAmount, bind: bindNotificationNumberAmount } = useNumberInput(1, 1, 10);
    const { value: messageAfterChecked, bind: bindMessageAfterChecked } = useCheckboxInput(false);

    const alarmEvents: { label: string, value: string, id: number }[] = [
        {
            label: "No communication",
            value: "noCommunication",
            id: 1,
        },
        {
            label: "Unauthorized lock breach",
            value: "unauthorizedEntry",
            id: 2,
        },
        {
            label: "Open doors",
            value: "openDoors",
            id: 3,
        },
        {
            label: "PWE button pressed",
            value: "pwePressed",
            id: 4,
        },
        {
            label: "Wrong keycard",
            value: "wrongKeycard",
            id: 5,
        },
        {
            label: "Wrong PIN",
            value: "wrongPin",
            id: 6,
        },
        {
            label: "Doors opened remotely",
            value: "doorsOpenedRemotely",
            id: 7,
        },
    ];

    const [t] = useTranslation();

    useEffect(() => {

    }, [alarmId]);

    const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEvent((event.target as HTMLInputElement).value);
    };

    const handleAddClick = () => {

    };

    const handleRemoveClick = () => {

    };



    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid container spacing={2} direction="column" justifyContent="space-around" alignItems="center" className={classes.formContainer}>
                <Grid container item justifyContent="space-around" >
                    <Grid item>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            id="alarmName"
                            label={t("Alarm name")}
                            name="alarmName"
                            autoComplete="alarmName"
                            autoFocus
                            {...bindAlarmName}
                        />
                    </Grid>
                </Grid>
                <Grid container item justifyContent="space-around">
                    <Box display="block">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>{t('Not active')}</Grid>
                            <Grid item>
                                <Switch color='primary' {...bindActiveChecked} name="activeChecked" />
                            </Grid>
                            <Grid item>{t('Active')}</Grid>
                        </Grid>
                    </ Box>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <AddAlarmFormDeviceTable alarmId={alarmId} selected={selected} setSelected={setSelected} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h5" className={classes.title} style={{ backgroundColor: "#FF6B01" }}>
                            {t("Choose event")}
                        </Typography>
                        <Paper className={classes.secondPaper}>
                            <RadioGroup aria-label="alarmEvent" name="alarmEvent" value={event} onChange={handleEventChange}>
                                {alarmEvents.map(event => (
                                    <FormControlLabel key={event.value} value={event.value} control={<Radio />} label={t(event.label)} />
                                ))}
                            </RadioGroup>
                        </Paper>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h5" className={classes.title} style={{ backgroundColor: "#008D4C" }}>
                            {t("Set action")}
                        </Typography>
                        <Paper className={classes.secondPaper}>
                            <FormControl component="fieldset">
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    id="emailList"
                                    label={t("Send e-mails to")}
                                    name="emailList"
                                    autoComplete="emailList"
                                    multiline
                                    {...bindEmailList}
                                />
                                <FormHelperText>
                                    {t("List of e-mails which will receive notifications of this alarm (separate each e-mail with a comma)")}
                                </FormHelperText>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    id="emailText"
                                    label={t("Text of the e-mail message")}
                                    name="emailText"
                                    autoComplete="emailText"
                                    multiline
                                    {...bindEmailText}
                                />
                                <FormHelperText>
                                    {t("What the e-mail will look like")}
                                </FormHelperText>
                                <Box mt={2}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Checkbox
                                                name="alarm-delayed"
                                                color="primary"
                                                {...bindAlarmDelayedChecked}
                                            />
                                        </Grid>
                                        <Grid item className={classes.label}>
                                            <Typography>{t("Delay alarm by")}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="number"
                                                name="alarmDelayedBy"
                                                InputProps={{ inputProps: { min: "1", max: "99" } }}
                                                disabled={!alarmDelayedChecked}
                                                {...bindAlarmDelayedBy}
                                            />
                                        </Grid>
                                        <Grid item className={classes.label}>
                                            <Typography>{t("minutes (max 60)")}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Checkbox
                                                name="send-message-every"
                                                color="primary"
                                                {...bindSendMessageEveryChecked}
                                            />
                                        </Grid>
                                        <Grid item className={classes.label}>
                                            <Typography>{t("Send message every")}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="number"
                                                name="sendMessageEveryInterval"
                                                InputProps={{ inputProps: { min: "1", max: "999" } }}
                                                disabled={!sendMessageEveryChecked}
                                                {...bindSendMessageEveryInterval}
                                            />
                                        </Grid>
                                        <Grid item className={classes.label}>
                                            <Typography>{t("minutes (max 120)")}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Checkbox
                                                name="notificationAmount"
                                                color="primary"
                                                {...bindNotificationAmountChecked}
                                            />
                                        </Grid>
                                        <Grid item className={classes.label}>
                                            <Typography>{t("Number of notifications of notifications")}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="number"
                                                name="notificationNumberAmount"
                                                InputProps={{ inputProps: { min: "1", max: "99" } }}
                                                disabled={!notificationAmountChecked}
                                                {...bindNotificationNumberAmount}
                                            />
                                        </Grid>
                                        <Grid item className={classes.label}>
                                            <Typography>{t("max 10")}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Checkbox
                                                name="message-after-alarm-ends"
                                                color="primary"
                                                {...bindMessageAfterChecked}
                                            />
                                        </Grid>
                                        <Grid item className={classes.label}>
                                            <Typography>{t("Send message after alarm ends")}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container spacing={2} direction="row-reverse">
                    <Grid item lg={2} md={3} xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                            {t("Save")}
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={3} xs>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}>
                            {t("Cancel")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}