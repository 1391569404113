import { Grid, Typography } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useTranslation } from "react-i18next";

import { ActiveGetResponse } from "services/device-management/types";
import EvacuationButton from "pages/settings/components/evacuation/EvacuationButton";
import LazyLoadIcon from "components/LazyLoadIcon";

const iconSize = {
    width: 50,
    height: 50,
};

interface DashboardCurrentlyActiveTableProps {
    actives?: ActiveGetResponse;
    location: string;
}

export default function DashboardCurrentlyActiveTable(props: DashboardCurrentlyActiveTableProps) {
    const [t] = useTranslation();
    const { actives, location } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {t("Currently inside")}
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid item>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    {t('everyone')}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <LazyLoadIcon iconName="People" sx={iconSize} />
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <Typography variant="h6">{(actives?.employees ?? 0) + (actives?.guests ?? 0)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    {t('Employees')}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <LazyLoadIcon iconName="PersonOutline" sx={iconSize} />
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <Typography variant="h6">{actives?.employees ?? 0}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    {t('Guests')}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <LazyLoadIcon iconName="Person" sx={iconSize} />
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <Typography variant="h6">{actives?.guests ?? 0}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <EvacuationButton location={location} isWideButton />
            </Grid>
        </Grid>
    );
}
