import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createDepartmentEndpoint, deleteDepartmentEndpoint, getDepartmentEndpoint, getDepartmentsEndpoint, updateDepartmentEndpoint } from "./endpoints";
import { DepartmentCreateRequest, DepartmentCreateResponse, DepartmentGetAllResponse, DepartmentGetDataShortResponse, DepartmentGetNamesResponse, DepartmentUpdateRequest, DepartmentUpdateResponse } from "./types";

export interface DepartmentData {
    "id": number;
    uuid: string;
    "color"?: string;
    "name": string;
    "alias": string;
    "description": string;
    "locationId": number;
    "parentDepartmentId": number
};

export interface DepartmentDataShort {
    "id": number;
    uuid: string;
    "color"?: string;
    "name": string;
    "parentDepartmentId": number
};

const getAll = (token?: string): Promise<DepartmentGetAllResponse[]> => {
    return genericGet<DepartmentGetAllResponse[]>(getDepartmentsEndpoint(), {}, token);
};

const getNames = (token?: string): Promise<DepartmentGetNamesResponse[]> => {
    return getAll(token)
        .then(res => {
            return res.map(dept => {
                return { id: dept.id, uuid: dept.uuid, name: dept.name };
            });
        });
};

const createDepartment = (dept: DepartmentCreateRequest, token?: string): Promise<DepartmentCreateResponse> => {
    return genericPost<DepartmentCreateResponse>(createDepartmentEndpoint(), dept, token);
};

const updateDepartment = (uuid: string, dept: DepartmentUpdateRequest, token?: string): Promise<DepartmentUpdateResponse> => {
    return genericPut<DepartmentUpdateResponse>(updateDepartmentEndpoint(uuid), dept, token);
};

const getDataShort = (token?: string): Promise<DepartmentGetDataShortResponse[]> => {
    return getAll(token)
        .then(res => {
            return res.map(dept => {
                return { id: dept.id, uuid: dept.uuid, color: dept.color, name: dept.name, parentDepartmentId: dept.parentDepartmentId };
            });
        });
};

const getDepartment = (uuid: string, token?: string): Promise<DepartmentGetAllResponse> => {
    return genericGet<DepartmentGetAllResponse>(getDepartmentEndpoint(uuid), {}, token);
};

const deleteDepartment = (id: string, token?: string): Promise<{status: number, statusText: string}> => {
    return genericDelete(deleteDepartmentEndpoint(id), token);
};

export const departmentsService = {
    getAll,
    getNames,
    getDataShort,
    createDepartment,
    updateDepartment,
    getDepartment,
    deleteDepartment    
};