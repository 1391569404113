import { FormControl, FormHelperText, InputLabel, Link, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useSessionContext } from 'contexts/SessionContext';
import { useSnackbar, VariantType } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { DepartmentGetNamesResponse, LocationGetNamesResponse, locationsService } from 'services/company-management';
import { departmentsService } from 'services/company-management/departments.service';
import { getGetErrorText } from 'services/genericService';

interface LocationSelectProps {
    type?: "location" | "department";
    value: string | string[];
    setValue: React.Dispatch<any>;
    onChange: (e: SelectChangeEvent<any>) => void;
    required?: boolean;
    error?: boolean;
    multiple?: boolean;
    input?: JSX.Element;
    withBottomSpace?: boolean;
    noOverrideDefaultValues?: boolean;
    fullWidth?: boolean;
}

export default function LocationSelect(props: LocationSelectProps) {
    const [sessionContext, setSessionContext] = useSessionContext();
    const { t } = useTranslation();
    const { type, value, setValue, onChange, required, error, multiple, withBottomSpace, noOverrideDefaultValues, fullWidth } = props;
    const [valuesToSelect, setValuesToSelect] = React.useState<LocationGetNamesResponse[] | DepartmentGetNamesResponse[]>([]);

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };
    
    useEffect(() => {
        if (type === 'department') fetchDepartments();
        else fetchLocations();
    }, []);
    
    const fetchLocations = () => {
        locationsService.getNames(sessionContext.token)
            .then(res => {
                setValuesToSelect(res);
                if (res.length > 0 && !noOverrideDefaultValues) {
                    if (sessionContext.locationUuid && res.map(l => l.uuid).includes(sessionContext.locationUuid)) {
                        setValue(multiple ? [sessionContext.locationUuid] : sessionContext.locationUuid)
                    } else {
                        setValue(multiple ? [res[0].uuid] : res[0].uuid);
                    }
                }
            })
            .catch(err => {
                handleSnackBar(getGetErrorText(err), 'error');
                setTimeout(fetchLocations, 10 * 1000);
            })
    }
    
    const fetchDepartments = () => {
        departmentsService.getNames(sessionContext.token)
            .then(res => {
                setValuesToSelect(res);
                if (noOverrideDefaultValues) return;

                if (typeof value === 'string') {
                    if (res.length) setValue("0")
                    else setValue("")
                } else {
                    setValue([])
                }
            })
            .catch(err => {
                handleSnackBar(getGetErrorText(err), "error");
                setTimeout(fetchDepartments, 10 * 1000);
            })
    }

    const handleChange = (event: SelectChangeEvent<string | string[]>) => {
        onChange(event);

        if (type === 'department') return;
        const locationUuid: (string | undefined) = multiple ? (event.target.value as string[])[0] : (event.target.value as string);
        if (locationUuid === undefined) return;
        localStorage.setItem("selectedLocation", locationUuid)
        setSessionContext({ ...sessionContext, locationUuid })
    }

    return (
        <FormControl
            sx={{
                ...(fullWidth ? {
                    width: "100%",
                } : {
                    minWidth: 200,
                }),
                marginBottom: !!withBottomSpace ? "23px" : 0
            }}
            required={required}
            variant='standard'
        >
            <InputLabel id={`${type ?? "location"}-select-label`}>
                {t(valuesToSelect.length ? `Choose ${type ?? "location"}` : `No ${type ?? "location"}s`)}
            </InputLabel>
            <Select
                fullWidth
                labelId={`${type ?? "location"}-select-label`}
                id={`${type ?? "location"}-select`}
                displayEmpty
                disabled={!valuesToSelect.length}
                value={value}
                onChange={handleChange}
                error={error}
                multiple={multiple}
            >
                { !multiple && type === 'department' && <MenuItem value="0">{t("All")}</MenuItem> }
                {valuesToSelect.map((val, index) => (
                    <MenuItem key={index} value={val.uuid}>
                        {val.name}
                    </MenuItem >
                ))}
            </Select>
            {!valuesToSelect.length && (
                <FormHelperText>
                    {`${t(`Add ${type ?? "location"}`)} ${t("in")} `}
                    <Link
                        to={`/company/${type ?? "location"}s`}
                        color="inherit"
                        component={RouterLink}
                        sx={{
                            textDecoration: "underline",
                            fontWeight: "bold"
                        }}
                        >{t("My company")}</Link>
                </FormHelperText>
            )}
        </FormControl>
    );
}