import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: theme.palette.error.main,
    },
}));

interface DeleteDialogProps {
    type: "department" | "device groups" | "employees" | "guests" | "guest types" | "locations" | "roles" | "schedule" | "holidays" | "accounts" | "alarms" | "device" | "e-mail" | "work time schedule";
    names: string[];
    handleDelete: () => void;
    handleClose: () => void;
}

export default function DeleteDialog(props: DeleteDialogProps) {
    const classes = useStyles();
    const { type, names, handleDelete, handleClose } = props;
    const [t] = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {`${t("You are about to delete")} ${names.join(', ')}.`}
                    </Typography>
                </Box>

                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("This action is irreversable")}
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            color="error"
                            onClick={handleDelete}
                        >
                            {t(`Delete ${type}`)}
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={handleClose}
                        >
                            {t("Cancel")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}