import { apiUrl } from "../config";
import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createLocationEndpoint, getLocationEndpoint, getLocationsEndpoint, updateLocationEndpoint } from "./endpoints";
import { LocationCreateRequest, LocationCreateResponse, LocationGetAllResponse, LocationGetNamesResponse, LocationGetResponse } from "./types";

const getAll = async (token?: string, term?: string): Promise<LocationGetAllResponse[]> => {
  return genericGet<LocationGetAllResponse[]>(getLocationsEndpoint(), {}, token);
}

const getOne = async (uuid: string, token?: string): Promise<LocationGetResponse | undefined> => {
  return genericGet<LocationGetResponse>(getLocationEndpoint(uuid), {}, token);
}

const getNames = async (token?: string): Promise<LocationGetNamesResponse[]> => {
  const results = await genericGet<LocationGetAllResponse[]>(getLocationsEndpoint(), {}, token);
  return results.map(row => ({ uuid: row.uuid, name: row.name }));
};

const deleteLocations = async (ids: string[], token?: string): Promise<{status: number, statusText: string}[]> => {
  const urls: string[] = ids.map(id => `${apiUrl}/locations/${id}`);
  const responses: {status: number, statusText: string}[] = [];

  await Promise.all(urls.map(async (url) => {
    await genericDelete(url, token)
      .then(res => responses.push(res))
      .catch(err => responses.push(err));
  }));
  
  return responses;
};

const createLocation = async (location: LocationCreateRequest, token?: string): Promise<LocationCreateResponse> => {
  return genericPost(createLocationEndpoint(), location, token);
};

const updateLocation = async (uuid: string, location: LocationCreateRequest, token?: string): Promise<LocationCreateResponse> => {
  return genericPut(updateLocationEndpoint(uuid), location, token);
};

export const locationsService = {
  getAll,
  getNames,
  getOne,
  deleteLocations,
  createLocation,
  updateLocation,
}