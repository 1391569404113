import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Box, Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoleGetAllResponse } from 'services/account-management';
import TableSearchbar from 'components/TableSearchbar';
import LazyLoadIcon from 'components/LazyLoadIcon';

interface RolesTableProps {
    rows: RoleGetAllResponse[];
    handleClickEditOpen: (id: string) => void;
    handleClickDeleteOpen: (id: string) => void;
    searchTerm: string,
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
}

export default function RolesTable(props: RolesTableProps) {
    const { rows, handleClickEditOpen, handleClickDeleteOpen, searchTerm, setSearchTerm } = props;
    const [t] = useTranslation();

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <Toolbar sx={{ pl: 2, pr: 1 }}>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                        {t("Roles")}
                    </Typography>
                    <TableSearchbar type="roles" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                </Toolbar>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="rolesTable"
                        aria-label="roles table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="left"
                                >
                                    {t("Name")}
                                </TableCell>
                                <TableCell
                                    align="left"
                                >
                                    {t("Permissions")}
                                </TableCell>
                                <TableCell
                                    align={'center'}
                                    sx={{ width: 150 }}
                                >
                                    {t("Actions")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        key={row.uuid}
                                    >
                                        <TableCell component="th" scope="row" >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.permissions.filter(p => p.isGranted).map(p => p.name).join(", ")}
                                        </TableCell>

                                        <TableCell align="center">
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                    <Tooltip title={row.isDefaultRole ? `${t("Cannot edit default roles")}` : ""}>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => handleClickEditOpen(row.uuid)}
                                                                disabled={row.isDefaultRole}
                                                                size="large">
                                                                <LazyLoadIcon iconName='Edit' />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs>
                                                    <Tooltip title={row.isDefaultRole ? `${t("Cannot delete default roles")}` : ""}>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => handleClickDeleteOpen(row.uuid)}
                                                                disabled={row.isDefaultRole}
                                                                size="large">
                                                                <LazyLoadIcon iconName='Delete' />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
