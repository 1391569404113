import React, { FormEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '@mui/material';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink} from 'react-router-dom';

import { authenticationService, ResponseMessage } from 'services/authentication.service';
import { useSnackbar, VariantType } from 'notistack';
import { getGetErrorText } from 'services/genericService';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    maxWidth: "500px",
  },
  gridBox: {
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
  },
}));

export default function ResetPasswordView() {
  const [email, setEmail] = React.useState('');
  const [result, setResult] = React.useState<null | ResponseMessage>(null);
  const {t} = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (message: string, variant: VariantType) => {
      enqueueSnackbar(t(message), { variant, preventDuplicate: true });
  };

  function handleOnSubmit(e: FormEvent) {
    e.preventDefault();

    authenticationService.requestResetPassword(email)
      .then(res => {
        setEmail('');
        handleSnackBar(t("Email sent to {{email}}", { email }), "success");
      })
      .catch(res => handleSnackBar(getGetErrorText(res.status), "error"))
  };

  const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setResult(null);
  };

  return (
    <Box className={classes.gridBox}>
      <Avatar sx={{ margin: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("Reset password")}
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("Email Address")}
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleInputChange}
        />
        {!!result
          && <>
            <Box mt={2} />
            <Alert severity={result.status}>{result.message}</Alert>
          </>}
        <Box mt={1}>
          <Link component={RouterLink} to="/login" variant="body2" underline='hover'>
            {t("Back to login")}
          </Link>
        </Box>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          {t("Send")}
        </Button>
      </form>
    </Box>
  );
}