import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createScheduleEndpoint, deleteScheduleEndpoint, getScheduleEndpoint, getSchedulesEndpoint, updateScheduleEndpoint } from "./endpoints";
import { ScheduleCreateRequest, ScheduleCreateResponse, ScheduleEditResponse, ScheduleGetAllNamesResponse, ScheduleGetAllResponse, ScheduleGetForTableResponse, ScheduleGetResponse, ScheduleUpdateResponse, VerificationMethods } from "./types";

const decomposeDaysToNames = (sum: number): string[] => {
    const fromSunToHol: string[] = sum.toString(2).split('').reverse();
    let res: string[] = [];
    fromSunToHol.map((day, index) => {
        if (day === '1') {
            switch (index) {
                case 0:
                    res.push('Sunday');
                    break;
                case 1:
                    res.push('Monday');
                    break;
                case 2:
                    res.push('Tuesday');
                    break;
                case 3:
                    res.push('Wednesday');
                    break;
                case 4:
                    res.push('Thursday');
                    break;
                case 5:
                    res.push('Friday');
                    break;
                case 6:
                    res.push('Saturday');
                    break;
                case 7:
                    res.push('Holidays');
                    break;
            }
        };
    });
    return res;
}

const decomposeDaysToNumber = (sum: number): number[] => {
    return sum.toString(2).split('').reverse().map(Number);;
}

const convertDate = (date: string): string => {
    return date.substr(0, 5);
}

const convertGetAllToTable = (list: ScheduleGetAllResponse[]): ScheduleGetForTableResponse[] => {
    const newList: ScheduleGetForTableResponse[] = [];
    list.map(schedule => {
        const newDesc: string = schedule.description ? schedule.description : 'No description';
        const newDays: string[] = decomposeDaysToNames(schedule.days);
        const newVerificationMethod = VerificationMethods[schedule.verificationMethod];
        newList.push({
            uuid: schedule.uuid,
            name: schedule.name,
            description: newDesc,
            start: convertDate(schedule.start),
            end: convertDate(schedule.end),
            days: newDays,
            verificationMethod: newVerificationMethod
        })
    });
    return newList;
}

const getAllSchedules = (token?: string): Promise<ScheduleGetForTableResponse[]> => {
    return genericGet<ScheduleGetAllResponse[]>(getSchedulesEndpoint(), {}, token).then(res => {
        return convertGetAllToTable(res);
    });
}

const getAllSchedulesNames = (token?: string): Promise<ScheduleGetAllNamesResponse[]> => {
    return genericGet<ScheduleGetAllResponse[]>(getSchedulesEndpoint(), {}, token).then(res => {
        return res.map(schedule => {
            return { uuid: schedule.uuid, name: schedule.name };
        });
    });
}

const getSchedule = (uuid: string, token?: string): Promise<ScheduleEditResponse> => {
    return genericGet<ScheduleGetResponse>(getScheduleEndpoint(uuid), {}, token)
    .then(res => {
        return {
            uuid: res.uuid,
            name: res.name,
            description: res.description ? res.description : '',
            start: convertDate(res.start),
            end: convertDate(res.end),
            days: decomposeDaysToNumber(res.days),
            verificationMethod: VerificationMethods[res.verificationMethod]
        }
    });
}

const createSchedule = (schedule: ScheduleCreateRequest, token?: string): Promise<ScheduleCreateResponse> => {
    return genericPost<ScheduleCreateResponse>(createScheduleEndpoint(), schedule, token);
}

const updateSchedule = (uuid: string, schedule: ScheduleCreateRequest, token?: string): Promise<ScheduleUpdateResponse> => {
    return genericPut<ScheduleUpdateResponse>(updateScheduleEndpoint(uuid), schedule, token);
}

const deleteSchedule = (scheduleId: string, token?: string): Promise<{ status: number, statusText: string }> => {
    return genericDelete(deleteScheduleEndpoint(scheduleId), token);
}

export const schedulesService = {
    getAllSchedules,
    getAllSchedulesNames,
    getSchedule,
    createSchedule,
    updateSchedule,
    deleteSchedule,
}