import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Grid, lighten } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Location, LocationGetAllResponse } from "services/company-management/types";
import ColorSquare from 'components/ColorSquare';
import TableSearchbar from 'components/TableSearchbar';
import LazyLoadIcon from 'components/LazyLoadIcon';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends "uuid" | "name" | "city">(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: "uuid" | "name" | "city";
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Location name' },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: "uuid" | "name" | "city") => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: "uuid" | "name" | "city") => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all locations' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          align={'center'}
          className={classes.actionsColumn}
        >
          {t("Actions")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.mode === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleGroupDelete: CallableFunction;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, searchTerm, setSearchTerm } = props;

  const handleGroupDelete = (e: React.MouseEvent<HTMLButtonElement>): void => {
    props.handleGroupDelete(e);
  }
  const { t } = useTranslation();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <>
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {t("Selected")} {numSelected}
          </Typography>
          <IconButton aria-label="delete" onClick={handleGroupDelete} size="large">
            <LazyLoadIcon iconName='Delete' />
          </IconButton>
        </>
      ) : (
        <>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {t("Locations")}
          </Typography>
          <TableSearchbar type="locations" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actionsColumn: {
      width: "150px",
    },
  }),
);

interface LocationsTableProps {
  rows: LocationGetAllResponse[];
  selected: string[];
  setSelected: (list: string[]) => void;
  handleClickEditOpen: (uuid: string) => void;
  handleClickDeleteOpen: (idList: string[]) => void;
  others?: any;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

export default function LocationsTable(props: LocationsTableProps) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<"uuid" | "name" | "city">('name');
  const { rows, selected, setSelected, handleClickEditOpen, handleClickDeleteOpen, searchTerm, setSearchTerm } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: "uuid" | "name" | "city") => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.uuid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, uuid: string) => {
    const selectedIndex = selected.indexOf(uuid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleEditAccount = (e: React.MouseEvent<HTMLButtonElement>, row: Location): void => {
    handleClickEditOpen(row.uuid);
  }

  const handleDeleteAccount = (e: React.MouseEvent<HTMLButtonElement>, uuid: string): void => {
    handleClickDeleteOpen([uuid]);
  }

  const handleGroupDelete = (e: React.MouseEvent<HTMLButtonElement>): void => {
    handleClickDeleteOpen(selected);
  };

  const isSelected = (uuid: string) => selected.indexOf(uuid) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleGroupDelete={handleGroupDelete}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="locationsTable"
            aria-label="locations table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.uuid);
                  const labelId = `locations-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uuid}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.uuid)}>
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" onClick={(event) => handleClick(event, row.uuid)}
                      >
                        <Grid container spacing={2}>
                          {row.color && <Grid item>
                            <ColorSquare color={row.color} />
                          </Grid>}
                          <Grid item>
                            {row.name}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="left" onClick={(event) => handleClick(event, row.uuid)}
                      >{row.city}</TableCell>

                      <TableCell align="center">
                        <Grid container spacing={1}>
                          <Grid item xs>
                            <IconButton onClick={(event) => handleEditAccount(event, row)} size="large">
                              <LazyLoadIcon iconName='Edit' />
                            </IconButton>
                          </Grid>
                          <Grid item xs>
                            <IconButton onClick={(event) => handleDeleteAccount(event, row.uuid)} size="large">
                              <LazyLoadIcon iconName='Delete' />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
