import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, IconButton, Toolbar, Typography, AppBar, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar, VariantType } from 'notistack';

import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { HolidayGetAllResponse } from 'services/schedules-management/types';
import { useSessionContext } from 'contexts/SessionContext';
import { holidaysService } from 'services/schedules-management/holidays.service';
import DeleteDialog from 'components/DeleteDialog';
import HolidaysTable from './HolidaysTable';
import AddHolidayForm from './AddHolidayForm';
import DialogOverlay from 'components/DialogOverlay';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    deleteAppBar: {
        position: 'relative',
        backgroundColor: theme.palette.error.main
    },
    appBarSpacer: theme.mixins.toolbar,
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function SchedulesHolidaysTab() {
    const classes = useStyles();
    const theme = useTheme();
    const [t] = useTranslation();
    const [rows, setRows] = React.useState<HolidayGetAllResponse[]>([]);
    const [addOpen, setAddOpen] = React.useState<boolean>(false);
    const [editOpen, setEditOpen] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [editedRowId, setEditedRowId] = React.useState<string>('');
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sessionContext] = useSessionContext();
    const { enqueueSnackbar } = useSnackbar();
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    useEffect(() => {
        refreshList();
    }, [editedRowId]);

    const handleClose = () => {
        setEditedRowId('');
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        refreshList();
    }

    const handleClickAddOpen = () => {
        setEditedRowId('');
        setAddOpen(true);
    }

    const handleClickEditOpen = (id: string) => {
        setEditedRowId(id);
        setEditOpen(true);
    }

    const handleClickDeleteOpen = (id: string) => {
        setEditedRowId(id);
        setDeleteOpen(true);
    }

    const refreshList = () => {
        holidaysService.getAllHolidays(sessionContext.token).then(res => {
            setRows(res);
        })
        .catch(err => {
            handleSnackBar(getGetErrorText(err), "error");
        });
    }

    const handleDelete = () => {
        holidaysService.deleteHoliday(editedRowId, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
                setEditedRowId('');
            })
            .catch(err => {
                handleSnackBar(getDeleteErrorText(err), "error");
            });
    };

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const containsSearchTerm = (holiday: HolidayGetAllResponse, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return holiday.name.toLowerCase().includes(term) ||
                holiday.date.toLowerCase().includes(term);
    };

    return <>
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item container alignItems="center" xs>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                        {t("Add holiday")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>

        <Box mt={2}>
            <HolidaysTable
                rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                handleClickEditOpen={handleClickEditOpen}
                handleClickDeleteOpen={handleClickDeleteOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
        </Box>

        <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add holiday")}>
            <AddHolidayForm handleClose={handleClose} />
        </DialogOverlay>

        <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit holiday")}>
            <AddHolidayForm handleClose={handleClose} editedHoliday={editedRowId} />
        </DialogOverlay>

        <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete holiday")}>
            <DeleteDialog type='holidays' handleDelete={handleDelete} names={[rows.find(row => row.uuid === editedRowId)?.name ?? '']} handleClose={handleClose} />
        </DialogOverlay>
    </>;
}
