import React, { FormEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useInput } from '../../hooks/input-hook';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar, VariantType } from 'notistack';

import { authenticationService } from 'services/authentication.service';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        maxWidth: "500px",
    },
    gridBox: {
        verticalAlign: "middle",
        marginTop: "auto",
        marginBottom: "auto",
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface MatchParams {
    id: string;
    guid: string;
}

interface RegisterConfirmationViewProps extends RouteComponentProps<MatchParams> {
}

export default function RegisterConfirmationView(props: RegisterConfirmationViewProps) {
    const { id, guid } = props.match.params;
    const { value: password, bind: bindPassword } = useInput("");
    const { value: repeatPassword, bind: bindrepeatPassword } = useInput("");

    const [ isPassDisabled, setIsPassDisabled ] = React.useState<boolean>(false);
    const [ isErrorOnFields, setIsErrorOnFields ] = React.useState<boolean>(false);
    const [ redirect, setRedirect ] = React.useState<boolean>(false);

    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        setIsErrorOnFields(false);
    }, [password, repeatPassword]);

    const handleRedirect = () => {
        if (isPassDisabled) setRedirect(true)
    }
    
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        if (password.length === 0) {
            handleSnackBar(t("Password must be provided"), "error");
            setIsErrorOnFields(true);
            return;
        }

        if (password.length < 8) {
            handleSnackBar(t("Password length must be greater then or equal to 8 characters"), "error");
            setIsErrorOnFields(true);
            return;
        }

        if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(password)) {
            handleSnackBar(t("Password does not contain at least one lowercase letter, one uppercase letter, a digit and a special character"), "error");
            setIsErrorOnFields(true);
            return;
        }
        
        if (password !== repeatPassword) {
            handleSnackBar(t("Both passwords need to be the same"), "error");
            setIsErrorOnFields(true);
            return;
        }

        const res = await authenticationService.registerConfirmation(id, guid, password);
        setIsErrorOnFields(false);
        if (res.status === 'success') {
            setIsPassDisabled(true)
        }
    };

    if (redirect) return <Redirect to="/login" />

    return (
        <Box className={classes.gridBox}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {t("Set your password")}
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                {!isPassDisabled ? (
                    <>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label={t("New password")}
                            type="password"
                            name="password"
                            autoFocus
                            disabled={isPassDisabled}
                            error={isErrorOnFields}
                            {...bindPassword}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="repeat-password"
                            label={t("Repeat new password")}
                            type="password"
                            name="repeat-password"
                            disabled={isPassDisabled}
                            error={isErrorOnFields}
                            {...bindrepeatPassword}
                        />
                    </>
                ) : (
                    <Alert severity={"success"} >
                        {t("Account successfully created")}
                    </Alert>
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleRedirect}
                >
                    {t(isPassDisabled ? "Sign in" : "Confirm")}
                </Button>
            </form>
        </Box>
    );
}