import React, { useEffect } from 'react';
import { Box, Button, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addMonths, endOfMonth, min, sub } from 'date-fns';
import LocationSelect from 'components/LocationSelect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateRangePicker from 'components/DateRangePicker';
import { DateRange } from 'components/DateRangePicker/types';

export type LogType = "UA" | "EB" | "NC" | "WP" | "PU";
export type RangeType = 'day' | 'week' | 'month' | 'other';
export const ranges: RangeType[] = ["day", "week", "month"];

interface DashboardTopBarProps {
    setLocation: React.Dispatch<React.SetStateAction<string>>;
    bindLocation: {
        value: string;
        onChange: (e: SelectChangeEvent<any>) => void;
    };
    showFromDate: Date | null;
    setShowFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
    showToDate: Date | null;
    setShowToDate: React.Dispatch<React.SetStateAction<Date | null>>;
}

function DashboardTopBar(props: DashboardTopBarProps) {
    const { t } = useTranslation();
    const { setLocation, bindLocation, showFromDate, setShowFromDate, showToDate, setShowToDate } = props;
    const [ dateRange, setDateRange ] = React.useState<DateRange>({
        startDate: showFromDate ?? new Date(),
        endDate: showToDate ?? new Date(),
    });

    useEffect(() => { showFromDate && setDateRange(dateRange => ({ ...dateRange, startDate: showFromDate })) }, [showFromDate]);
    useEffect(() => { showToDate && setDateRange(dateRange => ({ ...dateRange, endDate: showToDate })) }, [showToDate]);

    const makeDate = (): string => {
        const date = new Date();
        return `${t(dates[date.getDay()])}, ${date.getDate()} ${t(months[date.getMonth()])} ${date.getFullYear()}`;
    };

    const handleChangeDateRange = (dateRange: DateRange) => {
        // setDateRange(dateRange)

        if (dateRange.startDate) setShowFromDate(dateRange.startDate)
        if (dateRange.endDate) setShowToDate(dateRange.endDate)
    }

    const dates: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const months: string[] = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    
    return (
        <Grid display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" flexDirection="row" flexWrap="wrap" rowGap={1}>
                <Box width={200} flexGrow={1}>
                    <LocationSelect setValue={setLocation} {...bindLocation} />
                </Box>

                <Box flexShrink={0}>
                    <DateRangePicker
                        dateRange={dateRange}
                        onChange={handleChangeDateRange}
                        maxDaysBetween={31}
                    />
                </Box>
            </Box>
            <Grid display="flex" justifyContent="flex-end" marginTop={3}>
                <Typography variant="h5" align='right'>{makeDate()}</Typography>
            </Grid>
        </Grid>
    );
}

export default DashboardTopBar;