import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, IconButton, Toolbar, Typography, AppBar, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GuestDataTable, guestsService } from 'services/account-management';
import DeleteDialog from 'components/DeleteDialog';
import { useSessionContext } from 'contexts/SessionContext';
import AddGuestForm from '../all-guests/AddGuestForm';
import UnregisterGuestDialog from './UnregisterGuestDialog';
import { useSelectInput } from 'hooks/input-hook';
import { ResponseSuccessMessages, getCreateErrorText, getDeleteErrorText, getGetErrorText } from 'services/genericService';
import { useSnackbar, VariantType } from 'notistack';
import RegisteredGuestsTable from './RegisteredGuestsTable';
import DialogOverlay from 'components/DialogOverlay';

export default function MyGuestsRegisteredGuestsTab() {
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const [unregisterOpen, setUnregisterOpen] = React.useState(false);
    const [selectedUuid, setSelectedUuid] = React.useState<string>("");
    const [rows, setRows] = React.useState<GuestDataTable[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const { value: location, setValue: setLocation, bind: bindLocation } = useSelectInput("");
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    useEffect(() => {
        refreshData()
    }, [location]);

    const refreshData = () => {
        if (!location) return;
        
        guestsService.getAllRegistered(location, sessionContext.token)
            .then(guests => {
                setRows(
                    guests.map(guest => {
                        return {
                            ...guest,
                            guestType: guest.guestType?.name ?? "",
                            name: `${guest.firstName} ${guest.lastName}`,
                            supervisor: guest.supervisor? `${guest.supervisor.firstName} ${guest.supervisor.lastName}` : "",
                            isRegistered: guest.isRegistered ? "true" : "false",
                            company: guest.company ?? "",
                            pin: guest.pin ?? "-",
                            cardNumber: guest.cardNumber ?? "-",
                        }
                    })
                );
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }

    const handleClickUnregisterOpen = (selectedUuid: string) => {
        setSelectedUuid(selectedUuid);
        setUnregisterOpen(true);
    };

    const handleClose = () => {
        setUnregisterOpen(false);
        setSelectedUuid("");
        refreshData();
    };

    const containsSearchTerm = (guest: GuestDataTable, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return (
            guest.name.toLowerCase().includes(term) ||
            guest.company.toLowerCase().includes(term) ||
            (guest.supervisor ? guest.supervisor.toLowerCase().includes(term) : false) ||
            guest.phoneNumber.toString().toLowerCase().includes(term) ||
            guest.cardNumber.toLowerCase().includes(term)
        );
    };

    const handleUnregister = () => {
        guestsService.unregisterGuest(selectedUuid, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.UNREGISTER, 'success');
                handleClose();
            })
            .catch(err => handleSnackBar(getCreateErrorText(err), 'error'));
    };

    return <>
        <RegisteredGuestsTable
            rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
            handleClickUnregisterOpen={handleClickUnregisterOpen}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            bindLocation={bindLocation}
            setLocation={setLocation}
        />

        <DialogOverlay isOpen={unregisterOpen} handleClose={handleClose} title={t("Unregister guest")}>
            <UnregisterGuestDialog selected={rows.filter(row => selectedUuid.includes(row.uuid))} handleClose={handleClose} handleUnregister={handleUnregister} />
        </DialogOverlay>
    </>;
}
