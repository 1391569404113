import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import TableSearchbar from 'components/TableSearchbar';
import { useSessionContext } from 'contexts/SessionContext';
import ColorSquare from 'components/ColorSquare';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            width: '100%',
        },
        title: {
            flex: '1 1 100%',
        },
        fixedWidthColumn: {
            width: 150,
        },
        gridTable: {
            width: '100%',
        },
        toolbarContainer: {
            // height: 330,
            width: '50%',
        },
        searchbarContainer: {
            width: '50%',
            // minWidth: 160,
            padding: theme.spacing(2),
            marginTop: '-16px',
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        fixedTableHeight: {
            maxHeight: `calc(100vh - 200px - 64px)`,
            minHeight: 110
        }
    }),
);

interface ManageAccessDeviceTableProps {
    rows: { uuid: string, name: string, color?: string }[];
    selected: string[];
    setSelected: (list: string[]) => void;
    deviceGroupsChecked: boolean;
    setDeviceGroupsChecked: (value: boolean) => void;
    fetchRows: (ifFetchDeviceGroup: boolean) => void;
}

export default function ManageAccessDeviceTable(props: ManageAccessDeviceTableProps) {
    const [sessionContext] = useSessionContext();
    const classes = useStyles();
    const { rows, fetchRows, selected, setSelected, deviceGroupsChecked, setDeviceGroupsChecked } = props;
    const [t] = useTranslation();
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue: boolean = event.target.checked;
        setDeviceGroupsChecked(newValue);
        setSelected([]);
        fetchRows(newValue)
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.uuid);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const containsSearchTerm = (row: { uuid: string, name: string }, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        if (searchTerm = "") return true;
        return row.name.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>
            <Toolbar className={classes.toolbar} >
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {t(deviceGroupsChecked ? "DEVICE GROUP LIST" : "DEVICE LIST")}
                </Typography>
                <TableSearchbar type={deviceGroupsChecked ? "device groups" : "devices"} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </Toolbar>
            <Grid container direction="column" alignItems="center">
                {/* <Grid item xs={12}>
                    <Box mt={1}>
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>{t("Devices")}</Grid>
                            <Grid item>
                                <Switch color="primary" value={deviceGroupsChecked} onChange={handleSwitchChange} />
                            </Grid>
                            <Grid item>{t("Device groups")}</Grid>
                        </Grid>
                    </Box>
                </Grid> */}
                {/* <Grid container justify="flex-end" className={classes.searchbarContainer}>
                    <Grid item>
                        <TableSearchbar type={deviceGroupsChecked ? "device groups" : "devices"} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                    </Grid>
                </Grid> */}
                <Grid item spacing={2}
                    className={classes.gridTable}
                >
                    <TableContainer className={classes.fixedTableHeight}>
                        <Table
                            aria-labelledby="tableTitle"
                            aria-label="devices table"
                            className={classes.gridTable}
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selected.length > 0 && selected.length < rows.length}
                                            checked={rows.length > 0 && selected.length === rows.length}
                                            onChange={handleSelectAllClick}
                                            inputProps={{ 'aria-label': 'select all devices' }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                    >
                                        {t(deviceGroupsChecked ? "Device groups" : "All Devices")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                            >
                                {rows.filter(row => containsSearchTerm(row, searchTerm)).map((row, index) => {
                                    const isItemSelected = isSelected(row.uuid);
                                    const labelId = `device-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.uuid)}>
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                align="left"
                                                scope="row"
                                                onClick={(event) => handleClick(event, row.uuid)}
                                            >
                                                <Grid container spacing={2}>
                                                    {row.color && <Grid item>
                                                        <ColorSquare color={row.color} />
                                                    </Grid>}
                                                    <Grid item>
                                                        {row.name}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
