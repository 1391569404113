import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Copyright from './Copyright';
import { Button, Theme, Typography } from '@mui/material';
import FlagIcon from './FlagIcon';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4, 1),
    display: 'flex',
    flexDirection: 'column',
    height: "75%",
  },
  flag: {
    margin: theme.spacing(1),
  },
}));

interface UnauthorizedAppProps{
    children?: React.ReactNode;
};

export default function UnauthorizedAppLayout(props:UnauthorizedAppProps) {
  const classes = useStyles();
  const [, i18n] = useTranslation();

  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
  }

  return (
    <Grid container component="main" className={classes.root} >
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      <Grid container xs={false} sm={6} md={7} alignContent='center' alignItems='center' justifyContent='center'>
        <Grid container xs={false} sm={8} alignContent="center" alignItems='center' justifyContent='center'>
          <img src="http://protectimo.com/assets/img/Protectimo_color.svg" alt="protectimo"/>
          <Typography align='center' variant='h6'>Nowoczesny System Kontroli Dostępu w Chmurze</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {props.children}
        </div>
        
        <Grid container justifyContent='center'>
          <Grid item onClick={() => changeLanguage('en')} className={classes.flag}>
            <Button>
              <FlagIcon name='en' size="md" />
            </Button>
          </Grid>
          
          <Grid item onClick={() => changeLanguage('pl')} className={classes.flag}>
            <Button>
              <FlagIcon name='pl' size="md" />
            </Button>
          </Grid>
        </Grid>
        <Copyright />
      </Grid>
    </Grid>
  );
};

