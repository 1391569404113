export enum EvacuationListSort {
    SURNAME = 1,
    DEPARTMENTS = 2,
}

export type EvacuationListSettings = {
    uuid: string;
    sortBy: EvacuationListSort;
    generateOnAlarm: boolean;
}

export type EvacuationListSettingsUpdateRequest = {
    sortBy: EvacuationListSettings["sortBy"],
    generateOnAlarm: EvacuationListSettings["generateOnAlarm"],
};

export type EvacuationListSettingsGetResponse = {
    uuid: EvacuationListSettings["uuid"],
    sortBy: EvacuationListSettings["sortBy"],
    generateOnAlarm: EvacuationListSettings["generateOnAlarm"],
};
