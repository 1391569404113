import { genericGet } from "services/genericService";
import { downloadEventsEndpoint, downloadSummarizedWorkTimeEndpoint, getEventsEndpoint, getSummarizedWorkTimeEndpoint } from "./endpoints";
import { DownloadReportProps, GetReportProps, ReportEventData, ReportEventsSortOrderBy, ReportPaginationData, ReportSummarizedWorkTimeData, ReportSummarizedWorkTimeSortOrderBy, ReportSummarizedWorkTimeTableData } from "./types";
import { getRange, getWorkTime } from "./report.helpers";

const getAllEventReports = (props: GetReportProps<ReportEventsSortOrderBy>): Promise<ReportPaginationData<ReportEventData>> => {
    const {
        locationUuid,
        filter,
        sortOrder,
        sortOrderBy,
        page,
        pageSize,
        startDate,
        endDate,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    return genericGet<ReportPaginationData<ReportEventData>>(getEventsEndpoint(locationUuid, filter, sortOrder, sortOrderBy, page, pageSize, start, end), {}, token);
}

const downloadAllEventReports = (props: DownloadReportProps<ReportEventsSortOrderBy>): Promise<Blob> => {
    const {
        locationUuid,
        filter,
        sortOrder,
        sortOrderBy,
        startDate,
        endDate,
        type,
        lang,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    return genericGet<Blob>(
        downloadEventsEndpoint(locationUuid, filter, sortOrder, sortOrderBy, start, end, type, lang),
        { responseType: 'blob' },
        token
    )
}

const getAllSummarizedWorkTimeReports = async (props: GetReportProps<ReportSummarizedWorkTimeSortOrderBy>): Promise<ReportPaginationData<ReportSummarizedWorkTimeTableData>> => {
    const {
        locationUuid,
        filter,
        employeeFilter,
        sortOrder,
        sortOrderBy,
        page,
        pageSize,
        startDate,
        endDate,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    const list = await genericGet<ReportPaginationData<ReportSummarizedWorkTimeData>>(getSummarizedWorkTimeEndpoint(locationUuid, filter, sortOrder, sortOrderBy, page, pageSize, employeeFilter, start, end), {}, token);

    return ({
        ...list,
        rows: list.rows.map(wt => ({
            employeeId: wt.employeeId,
            employeeDepartment: wt.employeeDepartment,
            employeeName: `${wt.employeeLastName} ${wt.employeeFirstName}`,
            workTime: wt.workTimeInSeconds ? getWorkTime(wt.workTimeInSeconds) : "",
            normalTime: getWorkTime(wt.normalTimeInSeconds),
            cameLateBy: wt.cameLateByInSeconds ? getWorkTime(wt.cameLateByInSeconds) : "",
            leftEarlierBy: wt.leftEarlierByInSeconds ? getWorkTime(wt.leftEarlierByInSeconds) : "",
            nightHours: wt.nightHoursInSeconds ? getWorkTime(wt.nightHoursInSeconds) : "",
            breakTime: wt.breakTimeInSeconds ? getWorkTime(wt.breakTimeInSeconds) : "",
        })),
    })
}

const downloadAllSummarizedWorkTimeReports = (props: DownloadReportProps<ReportSummarizedWorkTimeSortOrderBy>): Promise<Blob> => {
    const {
        locationUuid,
        filter,
        employeeFilter,
        sortOrder,
        sortOrderBy,
        startDate,
        endDate,
        type,
        lang,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    return genericGet<Blob>(
        downloadSummarizedWorkTimeEndpoint(locationUuid, filter, sortOrder, sortOrderBy, employeeFilter, start, end, type, lang),
        { responseType: 'blob' },
        token
    )
}

export const reportsService = {
    getAllEventReports,
    downloadAllEventReports,
    getAllSummarizedWorkTimeReports,
    downloadAllSummarizedWorkTimeReports,
}
