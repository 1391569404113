import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ColorSquare from 'components/ColorSquare';
import { DepartmentGetDataShortResponse } from 'services/company-management';
import LazyLoadIcon from 'components/LazyLoadIcon';

interface RowProps {
  departments: DepartmentGetDataShortResponse[];
  row: DepartmentGetDataShortResponse;
  handleEdit: (id: string) => void;
  handleDeleteDepartment: (id: string) => void;
}

function Row(props: RowProps) {
  const { departments, row, handleEdit, handleDeleteDepartment } = props;
  const [open, setOpen] = React.useState(false);
  const children = departments.filter(childDept => childDept.parentDepartmentId === row.id);
  return (
    <React.Fragment>
      <TableRow >
        <TableCell sx={{ width: 50 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {!children[0] ? null : <LazyLoadIcon iconName={open ? "KeyboardArrowUp" : "KeyboardArrowDown"} />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container spacing={3}>
            {row.color && <Grid item>
              <ColorSquare color={row.color} />
            </Grid>}
            <Grid item>
              {row.name}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right" sx={{ width: 150 }}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <IconButton onClick={() => handleEdit(row.id)} size="large">
                <LazyLoadIcon iconName='Edit' />
              </IconButton>
            </Grid>
            {!(children.length > 0) && <Grid item>
              <IconButton onClick={() => handleDeleteDepartment(row.id)} size="large">
                <LazyLoadIcon iconName='Delete' />
              </IconButton>
            </Grid>}
          </Grid>
        </TableCell>
      </TableRow>

      {!!children[0] && <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table aria-label="children-table">
                <TableBody>
                  {children.map(childRow => (
                    <Row key={childRow.id} row={childRow} departments={departments} handleEdit={handleEdit} handleDeleteDepartment={handleDeleteDepartment} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      }
    </React.Fragment>
  );
}

interface DepartmentsTableProps {
  rows: DepartmentGetDataShortResponse[];
  handleEdit: (id: string) => void;
  handleDeleteDepartment: (id: string) => void;
}

export default function DepartmentsTable(props: DepartmentsTableProps) {
  const { rows, handleEdit, handleDeleteDepartment } = props;
  const [t] = useTranslation();

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          {t("Departments")}
        </Typography>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table aria-label="departments table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Department")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.filter((row: DepartmentGetDataShortResponse) => row.parentDepartmentId === null).map((row: DepartmentGetDataShortResponse) => (
              <Row key={row.name} row={row} departments={rows} handleEdit={handleEdit} handleDeleteDepartment={handleDeleteDepartment} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
