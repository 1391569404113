import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { Box, Grid, lighten, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

import { GuestDataTable } from "services/account-management";
import TableSearchbar from "components/TableSearchbar";
import LocationSelect from "components/LocationSelect";
import LazyLoadIcon from "components/LazyLoadIcon";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof GuestDataTable;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "company", numeric: false, disablePadding: false, label: "Company" },
  {
    id: "supervisor",
    numeric: false,
    disablePadding: false,
    label: "Supervisor",
  },
  {
    id: "guestType",
    numeric: false,
    disablePadding: false,
    label: "Guest type",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone number",
  },
  { id: "cardNumber", numeric: true, disablePadding: false, label: "Card ID" },
  { id: "pin", numeric: true, disablePadding: false, label: "PIN" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof GuestDataTable
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof GuestDataTable) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const [t] = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align={"center"} className={classes.actionsColumn}>
          {t("Actions")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      borderBottom: "1px solid #dddddd",
    },
    guestsTypeRoot: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    highlight:
      theme.palette.mode === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
    guestTypeCounter: {
      textAlign: "center",
      [theme.breakpoints.down(400)]: {
        fontSize: "0.8em",
      },
    },
    formControl: {
      width: 200,
      [theme.breakpoints.down("sm")]: {
        width: 100,
      },
      [theme.breakpoints.down(400)]: {
        maxWidth: 70,
      },
    },
    formControlLabel: {
      maxWidth: 180,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9em",
        marginTop: -5,
        maxWidth: 80,
      },
      [theme.breakpoints.down(400)]: {
        fontSize: "0.7em",
        maxWidth: 60,
      },
    },
    formControlSearchbar: {
      width: "100%",
      minWidth: 100,
      maxWidth: 250,
      [theme.breakpoints.down(400)]: {
        minWidth: 50,
      },
      "& label": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.9em",
        },
        [theme.breakpoints.down(400)]: {
          fontSize: "0.7em",
        },
      },
    },
    registerButton: {
      rotate: "180deg",
    },
  })
);

interface EnhancedTableToolbarProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  bindLocation: {
    value: string;
    onChange: (e: SelectChangeEvent<string>) => void;
  };
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { searchTerm, setSearchTerm, setLocation, bindLocation } = props;

  const [t] = useTranslation();

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("Registered guests")}
        </Typography>
        <Box mr={2}>
          <LocationSelect setValue={setLocation} {...bindLocation} />
        </Box>
        <Box className={classes.formControlSearchbar}>
          <TableSearchbar
            type="guests"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Box>
      </Toolbar>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    actionsColumn: {
      width: "150px",
    },
    registerButton: {
      rotate: "180deg",
    },
  })
);

interface RegisteredGuestsTableProps {
  handleClickUnregisterOpen: (selectedUuid: string) => void;
  rows: GuestDataTable[];
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  bindLocation: {
    value: string;
    onChange: (e: SelectChangeEvent<string>) => void;
  };
}

export default function RegisteredGuestsTable(
  props: RegisteredGuestsTableProps
) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof GuestDataTable>("name");
  const {
    handleClickUnregisterOpen,
    rows,
    searchTerm,
    setSearchTerm,
    setLocation,
    bindLocation,
  } = props;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof GuestDataTable
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleUnregisterGuest = (
    e: React.MouseEvent<HTMLButtonElement>,
    uuid: string
  ): void => {
    handleClickUnregisterOpen(uuid);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          bindLocation={bindLocation}
          setLocation={setLocation}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="guestsTable"
            aria-label="guests table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        <Grid container spacing={2} wrap="nowrap">
                          <Grid item>{row.name}</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">{row.company}</TableCell>
                      <TableCell align="center">{row.supervisor}</TableCell>
                      <TableCell align="center">{row.guestType}</TableCell>
                      <TableCell align="center">{row.phoneNumber}</TableCell>
                      <TableCell align="center">{row.cardNumber}</TableCell>
                      <TableCell align="center">{row.pin}</TableCell>

                      <TableCell align="center">
                        <Grid container spacing={1}>
                          <Grid item xs>
                            <IconButton
                              onClick={(event) =>
                                handleUnregisterGuest(event, row.uuid)
                              }
                              size="small"
                              className={classes.registerButton}
                            >
                              <LazyLoadIcon iconName="ExitToApp" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
