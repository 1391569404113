import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface DeleteEmployeesDialogProps {
    selected: string[];
    handleClose: () => void;
}

export default function ConfirmInviteDialog(props: DeleteEmployeesDialogProps) {
    const classes = useStyles();
    const [names, setNames] = React.useState<string>("names");
    const { selected, handleClose } = props;

    // useEffect(() => {
    //     setNames(employeesService.getJustNames(selected));
    // }, [selected[0]]);

    const [t] = useTranslation();

    const handleInvite = () => {
        console.log("employees invoited: ", selected)
        // employeesService.inviteEmployees(selected);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {selected[0]
                            ? `${t("You are about to invite")} ${names}.`
                            : t("Select employees to invite")}
                    </Typography>
                </Box>

                {selected[0]
                    && <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleInvite}
                            >
                                {t("Invite")}
                            </Button>
                        </Grid>
                    </Grid>}
                {!selected[0]
                    && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={handleClose}
                    >
                        {t("Back")}
                    </Button>}
            </div>
        </Container>
    );
}