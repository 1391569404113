import React, { useEffect } from 'react';
import { Button, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, RouteComponentProps } from 'react-router-dom';

import TableSearchbar from 'components/TableSearchbar';
import { useSelectInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import ReportsEventsTable from './components/ReportsEventsTable';
import AddReportForm from './components/AddReportForm';
import { useSnackbar, VariantType } from 'notistack';
import { getCreateErrorText, getGetErrorText } from 'services/genericService';
import { ReportPaginationData } from 'services/report-management/types';
import { differenceInDays } from 'date-fns';
import ReportsWorktimeTable from './components/worktime/ReportsWorktimeTable';
import Restricted from 'components/Restricted';
import { PermissionCategory } from 'services/account-management';
import DashboardTopBar from 'pages/dashboard/components/DashboardTopBar';
import { employeesService } from 'services/company-management';
import { EmployeeDataShort } from 'services/company-management/employees.service';
import LazyLoadIcon from 'components/LazyLoadIcon';
import ReportsSummarizedWorktimeTable from './components/ReportsSummarizedWorktimeTable';
import { reportsService, worktimeService } from 'services/report-management';

interface MatchParams {
    type: "events" | "guests" | "work-time" | "sum-work-time" | "alarms" | "add";
}

const employeeListEnabled: MatchParams['type'][] = [ 'work-time' ];

const editListEnabled: MatchParams['type'][] = [ 'work-time' ];

interface ReportsSecondaryViewProps extends RouteComponentProps<MatchParams> {
}

export default function ReportsSecondaryView(props: ReportsSecondaryViewProps) {
    const type = props.match.params.type;

    const [sessionContext] = useSessionContext();
    const [t] = useTranslation();

    const [showFromDate, setShowFromDate] = React.useState<Date | null>(new Date());
    const [showToDate, setShowToDate] = React.useState<Date | null>(new Date());
    const { value: location, setValue: setLocation, bind: bindLocation } = useSelectInput("");
    const [rows, setRows] = React.useState<ReportPaginationData<any> | undefined>(undefined);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [ page, setPage ] = React.useState<number>(0);
    const [ pageSize, setPageSize ] = React.useState<number | "auto">(10);
    const [ orderBy, setOrderBy ] = React.useState<number>(1);
    const [ order, setOrder ] = React.useState<'asc' | 'desc'>('desc');
    const [ employees, setEmployees ] = React.useState<EmployeeDataShort[]>([]);
    const { value: selectedEmployee, setValue: setSelectedEmployee, bind: bindSelectedEmployee } = useSelectInput("0");
    const [ isOpenEditMode, setIsOpenEditMode ] = React.useState<boolean>(false);
    const [ selectedRows, setSelectedRows ] = React.useState<number[]>([]);

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    const downloadFile = (blob: Blob, fileFormat: 'xlsx' | 'csv') => {
        const url = window.URL.createObjectURL(blob as Blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${t(getReportName()).replace(/\s/g, "_")}_${(new Date()).toLocaleString().replace(/(\s|,|:|\.)/g, "")}.${fileFormat}`;
        document.body.appendChild(a);
        a.click();    
        a.remove();
    };

    useEffect(() => {
        setPage(0);
        if (pageSize === 'auto') setPageSize(10)
    }, [ location, showFromDate, showToDate, pageSize ]);

    useEffect(() => {
        setSelectedRows([])
        if (!location) return;

        fetchData()
    }, [ location, showFromDate, showToDate, page, pageSize, searchTerm, order, orderBy, selectedEmployee ]);

    useEffect(() => {
        setPage(0);
        setPageSize(pageSize => pageSize === 'auto' ? 10 : pageSize);
    }, [searchTerm]);

    useEffect(() => {
        setSelectedEmployee("0");
        if (employeeListEnabled.includes(type)) employeesService.getAllShortVersion({ locationUuid: location }, sessionContext.token)
            .then(setEmployees)
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }, [ location ]);

    useEffect(() => {
        setPage(0);
        setPageSize(pageSize => pageSize === 'auto' ? 10 : pageSize);
    }, [selectedEmployee])
    
    const fetchData = (): void => {
        const diff = showToDate && showFromDate && differenceInDays(showToDate, showFromDate);

        if (diff && diff > 32) return;
        
        const reportProps = {
            locationUuid: location,
            filter: searchTerm.toLowerCase(),
            token: sessionContext.token,
            sortOrder: order,
            sortOrderBy: orderBy,
            page,
            pageSize: pageSize !== 'auto' ? pageSize : (diff ?? 10) + 1,
            startDate: showFromDate,
            endDate: showToDate,
            ...(selectedEmployee !== "0" ? { employeeFilter: selectedEmployee } : {}),
        };

        (() => {
            switch (type) {
                default:
                case "events": return reportsService.getAllEventReports(reportProps)
                // case "guests": return setRows(reportsServiceOld.getAllGuestPassReports());
                case "work-time": return worktimeService.getAllWorkTimeReports(reportProps)
                case "sum-work-time": return reportsService.getAllSummarizedWorkTimeReports(reportProps)
                // case "alarms": return setRows(reportsServiceOld.getAllAlarmReports());
            }
        })()
            .then(res => setRows(res))
            .catch(err => handleSnackBar(getCreateErrorText(err), 'error'))
    }

    const handleDownload = () => {
        const fileFormat: 'xlsx' | 'csv' = 'xlsx';

        const reportProps = {
            locationUuid: location,
            filter: searchTerm.toLowerCase(),
            sortOrder: order,
            sortOrderBy: orderBy,
            startDate: showFromDate,
            endDate: showToDate,
            type: fileFormat,
            lang: t("__cur_lang__"),
            token: sessionContext.token,
            ...(selectedEmployee !== "0" ? { employeeFilter: selectedEmployee } : {}),
        };
        
        (() => {
            switch (type) {
                default: 
                case "events": return reportsService.downloadAllEventReports(reportProps)
                // case "guests": return setRows(reportsServiceOld.getAllGuestPassReports());
                case "work-time": return worktimeService.downloadAllWorkTimeReports(reportProps)
                case "sum-work-time": return reportsService.downloadAllSummarizedWorkTimeReports(reportProps)
                // case "alarms": return setRows(reportsServiceOld.getAllAlarmReports());
            }
        })()
            .then(res => downloadFile(res, fileFormat))
            .catch(err => handleSnackBar(getCreateErrorText(err), 'error'))
    }

    const getComponent = (): JSX.Element => {
        const params = {
            rows,
            setRowsPerPage: setPageSize,
            setPage: setPage,
            setOrderBy,
            setOrder,
        }

        switch (type) {
            case "events": return <ReportsEventsTable {...params} />
            // case "guests": return <ReportsGuestPassesTable rows={(rows as GuestPassReportData[]).filter(row => containsSearchTerm(row, searchTerm))} />
            case "work-time": return <ReportsWorktimeTable {...params} selectedEmployee={selectedEmployee} selectedAuto={pageSize === 'auto'} selectedRows={selectedRows} setSelectedRows={setSelectedRows} isOpenEditMode={isOpenEditMode} handleClose={handleClose} locationUuid={location} />
            case "sum-work-time": return <ReportsSummarizedWorktimeTable {...params} />
            // case "alarms": return <ReportsAlarmsTable rows={(rows as AlarmReportData[]).filter(row => containsSearchTerm(row, searchTerm))} />
            case "add": return <AddReportForm />
            default: return <React.Fragment />
        }
    };

    const getReportName = () => {
        switch (type) {
            case "events": return "Events";
            case "guests": return "Guest passes";
            case "work-time": return "Work time";
            case "alarms": return "Alarms";
            default: return "Report";
        }
    }

    // const containsSearchTerm = (row: any, searchTerm: string): boolean => {
    //     const term = searchTerm.toLowerCase();
    //     if (term === "") return true;        
    //     switch (type) {
    //         case "events":
    //             return row.deviceName.toLowerCase().includes(term) ||
    //                     row.timestamp.toLowerCase().includes(term) ||
    //                     row.eventTypeName.toLowerCase().includes(term) ||
    //                     row.employeeId.toLowerCase().includes(term) ||
    //                     row.employeeName.toLowerCase().includes(term);
    //         case "guests": return row.name.toLowerCase().includes(term);
    //         case "work-time": return row.employeeId.toLowerCase().includes(term);
    //         case "alarms": return row.name.toLowerCase().includes(term);
    //         case "add": return row.entranceName.toLowerCase().includes(term);
    //         default: return true;
    //     };
    // };

    const handleOpenEdit = () => {
        setIsOpenEditMode(true)
    }

    const handleClose = () => {
        setIsOpenEditMode(false);
        fetchData();
    }
    
    return (
        <Restricted to={PermissionCategory.Reports}>
            <Container sx={{ pt: 2, pb: 4 }}>
                <Grid container direction="column" spacing={1}>
                    <Grid item xs={12}>
                        <Button
                            component={Link}
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                            to="/reports">
                            {t("Back")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {type !== "add" && (
                            <Paper sx={{ p: 2, mb: 2 }}>
                                <DashboardTopBar
                                    setLocation={setLocation}
                                    bindLocation={bindLocation}
                                    showFromDate={showFromDate}
                                    setShowFromDate={setShowFromDate}
                                    showToDate={showToDate}
                                    setShowToDate={setShowToDate}
                                />
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{ maxWidth: "100% !important" }}>
                        <Paper sx={{ p: 2, mb: 2 }}>
                            {type !== "add" && (
                                <Grid container justifyContent="space-between" alignItems='center' sx={{ mb: 2 }} spacing={2}>
                                    <Grid item flexGrow={1}>
                                        {employeeListEnabled.includes(type) && (
                                            <FormControl sx={{ minWidth: 200 }} variant='standard'>
                                                <InputLabel id="employee-select-label">
                                                    {t("Choose employee")}
                                                </InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="employee-select-label"
                                                    id="employee-select"
                                                    {...bindSelectedEmployee}
                                                >
                                                    <MenuItem value="0">
                                                        {t("Everyone")}
                                                    </MenuItem >
                                                    {employees.map(employee => (
                                                        <MenuItem key={employee.uuid} value={employee.uuid}>
                                                            {employee.name}
                                                        </MenuItem >
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={1}>
                                            {editListEnabled.includes(type) && (
                                                <Grid item>
                                                    <Tooltip title={!selectedRows.length ? `${t("First select the row")}` : ""}>
                                                        <span>
                                                            <IconButton disabled={!selectedRows.length} aria-label="edit_report" onClick={handleOpenEdit}>
                                                                <LazyLoadIcon iconName='Edit' />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <IconButton aria-label="download_report" onClick={handleDownload}>
                                                    <LazyLoadIcon iconName='GetApp' />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <TableSearchbar type={type} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                                    </Grid>
                                </Grid>
                            )}
                            {getComponent()}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Restricted>
    );
}
