import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createHolidayEndpoint, deleteHolidayEndpoint, getHolidayEndpoint, getHolidaysEndpoint, updateHolidayEndpoint } from "./endpoints";
import { HolidayCreateRequest, HolidayCreateResponse, HolidayGetAllNamesResponse, HolidayGetAllResponse, HolidayGetResponse, HolidayUpdateResponse } from "./types";

const getAllHolidays = (token?: string): Promise<HolidayGetAllResponse[]> => {
    return genericGet<HolidayGetAllResponse[]>(getHolidaysEndpoint(), {}, token).then(res => res);
}

const getAllHolidaysNames = (token?: string): Promise<HolidayGetAllNamesResponse[]> => {
    return genericGet<HolidayGetAllResponse[]>(getHolidaysEndpoint(), {}, token).then(res => {
        return res.map(holiday => {
            return {uuid: holiday.uuid, name: holiday.name};
        });
    });
}

const getHoliday = (id: string, token?: string): Promise<HolidayGetResponse> => {
    return genericGet<HolidayGetResponse>(getHolidayEndpoint(id), {}, token);
}

const createHoliday = (holiday: HolidayCreateRequest, token?: string): Promise<HolidayCreateResponse> => {
    return genericPost<HolidayCreateResponse>(createHolidayEndpoint(), holiday, token);
}

const updateHoliday = (uuid: string, holiday: HolidayCreateRequest, token?: string): Promise<HolidayUpdateResponse> => {
    return genericPut<HolidayUpdateResponse>(updateHolidayEndpoint(uuid), holiday, token);
}

const deleteHoliday = (holidayId: string, token?: string): Promise<{status: number, statusText: string}> => {
    return genericDelete(deleteHolidayEndpoint(holidayId), token);
}

export const holidaysService = {
    getAllHolidays,
    getAllHolidaysNames,
    getHoliday,
    createHoliday,
    updateHoliday,
    deleteHoliday,
}