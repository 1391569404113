import { apiUrl } from "services/config";

const getWorkTimeEndpoint = () => `${apiUrl}/work-time-registration/reports`;
const downloadWorkTimeEndpoint = () => `${apiUrl}/work-time-registration/reports/download`;

const getWorkTimeCustomEntryEndpoint = (uuid: string) => `${apiUrl}/work-time-registration/report-custom-entry/${uuid}`;
const createWorkTimeCustomEntryEndpoint = () => `${apiUrl}/work-time-registration/report-custom-entry`;
const updateWorkTimeCustomEntryEndpoint = (uuid: string) => `${apiUrl}/work-time-registration/report-custom-entry/${uuid}`;
const deleteWorkTimeCustomEntryEndpoint = (uuid: string) => `${apiUrl}/work-time-registration/report-custom-entry/${uuid}`;

export {
  getWorkTimeEndpoint,
  downloadWorkTimeEndpoint,

  getWorkTimeCustomEntryEndpoint,
  createWorkTimeCustomEntryEndpoint,
  updateWorkTimeCustomEntryEndpoint,
  deleteWorkTimeCustomEntryEndpoint,
};
