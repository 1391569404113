import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useCheckboxInput, useInput, useNumberInput, useRadioInput } from 'hooks/input-hook';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        maxWidth: 1000,
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontSize: 25,
    },
    label: {
        paddingTop: 48,
    },
}));


export default function SystemSettings() {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const { value: language, bind: bindLanguage } = useRadioInput(i18n.language);
    const { value: loginType, bind: bindLoginType } = useRadioInput("oneStep");
    const { value: passwordType, bind: bindPasswordType } = useRadioInput("moderate");
    const { value: passwordExpiresChecked, bind: bindPasswordExpiresChecked } = useCheckboxInput(false);
    const { value: passwordExpiresAfter, bind: bindPasswordExpiresAfter } = useNumberInput(60, 1, 999);
    const { value: blockUserChecked, bind: bindBlockUserChecked } = useCheckboxInput(false);
    const { value: blockUserTries, bind: bindBlockUserTries } = useNumberInput(3, 1, 10);
    const { value: blockUserTime, bind: bindBlockUserTime } = useNumberInput(60, 1, 999);
    const { value: logOutUserChecked, bind: bindLogOutUserChecked } = useCheckboxInput(false);
    const { value: logOutUserAfter, bind: bindLogOutUserAfter } = useNumberInput(60, 1, 999);
    const { value: aesEncryptionChecked, bind: bindAesEncryptionChecked } = useCheckboxInput(false);
    const { value: aesEncryptionValue, bind: bindAesEncryptionValue } = useInput("");

    const getPasswordDetails = (key: string): string => {
        switch (key) {
            case "weak": return "weakPasswordRequirements";
            case "moderate": return "moderatePasswordRequirements";
            case "strong": return "strongPasswordRequirements";
            default: return "";
        };
    };

    return (
        <Paper className={classes.paper}>
            <Typography component="h3" className={classes.title}>
                {t("System settings")}
            </Typography>
            <Box mt={3}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("Choose language")}</FormLabel>
                    <RadioGroup row aria-label="language" name="language" {...bindLanguage}>
                        <FormControlLabel value="pl" control={<Radio />} label="Polski" />
                        <FormControlLabel value="en" control={<Radio />} label="English" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("Choose login type")}</FormLabel>
                    <RadioGroup row aria-label="login-type" name="login-type" {...bindLoginType}>
                        <FormControlLabel value="oneStep" control={<Radio />} label={t("One-step")} />
                        <FormControlLabel value="twoStep" control={<Radio />} label={t("Two-step")} />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormControl component="fieldset" >
                    <FormLabel component="legend">{t("Password settings")}</FormLabel>
                    <RadioGroup row aria-label="password-requirements" name="password-requirements" {...bindPasswordType}>
                        <FormControlLabel value="weak" control={<Radio />} label={t("Weak")} />
                        <FormControlLabel value="moderate" control={<Radio />} label={t("Moderate")} />
                        <FormControlLabel value="strong" control={<Radio />} label={t("Strong")} />
                    </RadioGroup>
                    <FormHelperText>{t(getPasswordDetails(passwordType))}</FormHelperText>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Checkbox
                                name="password-expires"
                                color="primary"
                                {...bindPasswordExpiresChecked}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("Password expires in")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                name="passwordExpiresAfter"
                                InputProps={{ inputProps: { min: "1", max: "999" } }}
                                disabled={!passwordExpiresChecked}
                                {...bindPasswordExpiresAfter}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("days")}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Checkbox
                                name="block-user"
                                color="primary"
                                {...bindBlockUserChecked}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("Block user after")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                name="bindBlockUserTries"
                                InputProps={{ inputProps: { min: "1", max: "99" } }}
                                disabled={!blockUserChecked}
                                {...bindBlockUserTries}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("unsuccessful tries, for")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                name="blockUserTime"
                                InputProps={{ inputProps: { min: "1", max: "999" } }}
                                disabled={!blockUserChecked}
                                {...bindBlockUserTime}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("min")}</Typography>
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("Other")}</FormLabel>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Checkbox
                                name="log-out-user"
                                color="primary"
                                {...bindLogOutUserChecked}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("Log out user after")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                name="logOutAfter"
                                InputProps={{ inputProps: { min: "1", max: "999" } }}
                                disabled={!logOutUserChecked}
                                {...bindLogOutUserAfter}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("minutes of inactivity")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Checkbox
                                name="use-encryption"
                                color="primary"
                                {...bindAesEncryptionChecked}
                            />
                        </Grid>
                        <Grid item className={classes.label}>
                            <Typography>{t("Use AES128 encryption")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                name="logOutAfter"
                                disabled={!aesEncryptionChecked}
                                {...bindAesEncryptionValue}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2} direction="row-reverse">
                    <Grid item lg={2} md={3} xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                            {t("Save")}
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={3} xs>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            href="/settings">
                            {t("Cancel")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper >
    );
}