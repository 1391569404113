import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { ReportEventData, ReportEventsSortOrderBy, ReportPaginationData } from 'services/report-management/types';
import TablePagination from 'components/TablePagination';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof ReportEventData>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof ReportEventData;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'deviceName', numeric: false, label: 'Entrance name' },
    { id: 'timestamp', numeric: false, label: 'Event date' },
    { id: 'eventTypeName', numeric: false, label: 'Event type' },
    { id: 'employeeId', numeric: false, label: 'Identifier' },
    { id: 'employeeName', numeric: false, label: 'First and last name' },
    { id: 'entryStatus', numeric: false, label: 'Event status' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ReportEventData) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof ReportEventData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const [t] = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {(headCell.id in ReportEventsSortOrderBy) ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.label)}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            t(headCell.label)
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

interface ReportsEventsTableProps {
    rows?: ReportPaginationData<ReportEventData>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number | "auto">>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<number>>;
}

export default function ReportsEventsTable(props:ReportsEventsTableProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { rows, setRowsPerPage, setPage, setOrder, setOrderBy } = props;

    const orderByLabel = (property: keyof ReportEventData): ReportEventsSortOrderBy => {
        if (property === 'employeeId') return ReportEventsSortOrderBy.employeeId;
        if (property === 'employeeName') return ReportEventsSortOrderBy.employeeName;
        if (property === 'entryStatus') return ReportEventsSortOrderBy.entryStatus;
        if (property === 'eventTypeName') return ReportEventsSortOrderBy.eventTypeName;
        if (property === 'timestamp') return ReportEventsSortOrderBy.timestamp;
        return ReportEventsSortOrderBy.deviceName;
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReportEventData) => {
        const isAsc = rows?.sortOrder === 'asc';
        const newOrderLabel = orderByLabel(property);

        setOrder(newOrderLabel === rows?.sortOrderBy && !isAsc ? 'asc' : 'desc');
        setOrderBy(newOrderLabel);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const emptyRows = (rows && rows.count) ? rows.pageSize - Math.min(rows.pageSize, rows.count - rows.page * rows.pageSize) : 0;
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="eventReportsTable"
                        aria-label="event reports table"
                        size='small'
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={rows?.sortOrder === 'desc' ? "asc" : "desc"}
                            orderBy={ReportEventsSortOrderBy[rows?.sortOrderBy ?? ReportEventsSortOrderBy.timestamp]}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {rows && (rows.rows as ReportEventData[])
                                .map((row, index) => {
                                    const labelId = `report-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row.deviceName}
                                            </TableCell>
                                            <TableCell >{row.timestamp}</TableCell>
                                            <TableCell >{row.eventTypeName}</TableCell>
                                            <TableCell >{row.employeeId}</TableCell>
                                            <TableCell>{row.employeeName}</TableCell>
                                            <TableCell>{t(row.entryStatus)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {rows && !!rows.count && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    page={rows.page}
                    rowsPerPage={rows.pageSize}
                    count={rows.count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </Paper>
        </div>
    );
}
