type FlagIconProps = {
    name: "pl" | "en",
    size?: "sm" | "md" | "lg",
};

function FlagIcon (props: FlagIconProps) {
    const { name, size } = props;
    const multi = size === 'lg' ? 2 : (size === 'md' ? 1.5 : 1);

    const nameToFlagFileName = (name: "pl" | "en") => {
        if (name === 'en') return "union_jack_wave";
        return "poland_flag_wave";
    }

    return (
        <img alt={name} src={`/images/${nameToFlagFileName(name)}.png`} loading="lazy" width={`${multi * 32}px`} height={`${multi * 24}px`} />
    )
}

export default FlagIcon;