import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Theme, useTheme } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';

import { useInput, useMultiselectInput, useSelectInput } from 'hooks/input-hook';
import { departmentsService } from 'services/company-management/departments.service';
import { locationsService } from 'services/company-management/locations.service';
import { useSessionContext } from 'contexts/SessionContext';
import { DepartmentGetNamesResponse, LocationGetNamesResponse } from 'services/company-management';
import { getGetErrorText } from 'services/genericService';
import LocationSelect from 'components/LocationSelect';

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appBarSpacer: theme.mixins.toolbar,
    selectEmpty: {
        marginTop: theme.spacing(3),
    },
}));

interface AddEmployeeFormProps {
    handleAddEmployee: (firstName: string, lastName: string, location: string[], departments: string[]) => void;
    handleClose: () => void;
}

export default function AddEmployeeForm(props: AddEmployeeFormProps) {
    const classes = useStyles();
    const theme = useTheme();
    const { handleAddEmployee, handleClose } = props;
    const { value: firstName, bind: bindFirstName } = useInput("");
    const { value: lastName, bind: bindLastName } = useInput("");
    const { value: locations, setValue: setLocations, bind: bindLocations } = useMultiselectInput([]);
    const { value: departmentsSelected, setValue: setDepartmentsSelected, bind: bindDepartmentsSelected } = useMultiselectInput([]);

    const [ firstNameError, setFirstNameError ] = React.useState<boolean>(false);
    const [ lastNameError, setLastNameError ] = React.useState<boolean>(false);
    const [ locationError, setLocationError ] = React.useState<boolean>(false);
    const [ departmentsError, setDepartmentsError ] = React.useState<boolean>(false);

    const [t] = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    useEffect(() => {
        setFirstNameError(false);
    }, [firstName]);

    useEffect(() => {
        setLastNameError(false);
    }, [lastName]);

    const onAddEmployee = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        resetErrorLog();
        if (!ifValidateOk()) return;

        handleAddEmployee(firstName, lastName, locations, departmentsSelected);
    };

    const resetErrorLog = (): void => {
        setFirstNameError(false);
        setLastNameError(false);
        setLocationError(false);
        setDepartmentsError(false);
    }

    const ifValidateOk = (): boolean => {
        let temp = false;
        
        if (firstName.length === 0) {
            setFirstNameError(true);
            temp = true;
        }

        if (lastName.length === 0) {
            setLastNameError(true);
            temp = true;
        }

        if (locations.length === 0) {
            setLocationError(true);
            temp = true;
        }

        if (departmentsSelected.length === 0) {
            setDepartmentsError(true);
            temp = true;
        }
        
        if (temp) {
            handleSnackBar("You have to fill in all spaces", "error")
            return false;
        }

        return true;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {t("Insert employee data")}
                </Typography>
                <form className={classes.form}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label={t("First name")}
                        name="firstName"
                        autoComplete="firstName"
                        autoFocus
                        error={firstNameError}
                        {...bindFirstName}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label={t("Last name")}
                        name="lastName"
                        autoComplete="lastName"
                        error={lastNameError}
                        {...bindLastName}
                    />

                    <FormControl fullWidth className={classes.selectEmpty} required>
                        <LocationSelect
                            required
                            multiple
                            error={locationError}
                            setValue={setLocations}
                            {...bindLocations}
                        />
                    </FormControl>

                    <FormControl fullWidth className={classes.selectEmpty} required>
                        <LocationSelect
                            type="department"
                            required
                            multiple
                            error={departmentsError}
                            setValue={setDepartmentsSelected}
                            input={<Input />}
                            {...bindDepartmentsSelected}
                        />
                    </FormControl>
                    <Grid container alignItems="center" spacing={2} style={{ marginTop: 16 }}>
                        <Grid item xs>
                            <Button
                                onClick={onAddEmployee}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary">
                                {t("Add employee")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}>
                                {t("Cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}