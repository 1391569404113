import { Box, Grid, Switch, TextField, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

interface AddWorkTimeStepOneProps {
    bindName: any;
    bindDayStart: any;
    bindNightHoursFrom: any;
    bindNightHoursTo: any;
    bindBreakTime: any;
    bindRoundTime: any;
    bindRoundDownChecked: any;
}

function AddWorkTimeStepOne(props: AddWorkTimeStepOneProps) {
    const [t] = useTranslation();
    const { bindDayStart, bindName, bindNightHoursFrom, bindNightHoursTo, bindBreakTime, bindRoundTime, bindRoundDownChecked } = props;

    return (
        <>
            <TextField
                variant="standard"
                margin="normal"
                required
                id="scheduleName"
                label={t("Schedule name")}
                name="scheduleName"
                autoComplete="scheduleName"
                autoFocus
                fullWidth
                {...bindName}
            />
            <Grid item container xs={12} spacing={2} sx={{ p: 2 }}>
                <Grid container item spacing={2}>
                    <Grid item sx={{ mt: 1 }}>
                        <Typography>{t("Day start")}</Typography>
                    </Grid>
                    <Grid item>
                        <TimePicker
                            sx={{ width: 120 }}
                            {...bindDayStart}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item sx={{ mt: 1 }}>
                        <Typography>{t("Night hours from")}</Typography>
                    </Grid>
                    <Grid item>
                        <TimePicker
                            sx={{ width: 120 }}
                            {...bindNightHoursFrom}
                        />
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                        <Typography>{t("to")}</Typography>
                    </Grid>
                    <Grid item>
                        <TimePicker
                            sx={{ width: 120 }}
                            {...bindNightHoursTo}
                        />
                    </Grid>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item sx={{ pt: 4 }}>
                        <Typography>{t("Break time length")}</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            type="number"
                            name="breakTime"
                            variant='standard'
                            InputProps={{ inputProps: { min: "1", max: "999" } }}
                            {...bindBreakTime}
                        />
                    </Grid>
                    <Grid item sx={{ pt: 4 }}>
                        <Typography>{t("minutes")}</Typography>
                    </Grid>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item sx={{ pt: 4 }}>
                        <Typography>{t("Round times to")}</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            type="number"
                            name="roundTime"
                            variant='standard'
                            InputProps={{ inputProps: { min: "1", max: "99" } }}
                            {...bindRoundTime}
                        />
                    </Grid>
                    <Grid item sx={{ pt: 4 }}>
                        <Typography>{t("minutes")}</Typography>
                    </Grid>
                    <Grid item sx={{ pt: 4 }}>
                        <Box display="block">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>{t('Up')}</Grid>
                                <Grid item>
                                    <Switch color='primary' name="roundDownChecked" {...bindRoundDownChecked} />
                                </Grid>
                                <Grid item>{t('Down')}</Grid>
                            </Grid>
                        </ Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddWorkTimeStepOne;
