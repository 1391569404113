import { AppBar, Box, Button, Dialog, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSessionContext } from 'contexts/SessionContext';
import { useSnackbar, VariantType } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestTypeData, guestTypesService } from 'services/account-management/guestTypes.service';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import GuestsSettingsTable from './GuestsSettingsTable';
import AddGuestSettingsForm from './AddGuestSettingsForm';
import CloseIcon from '@mui/icons-material/Close';
import DeleteDialog from 'components/DeleteDialog';
import DialogOverlay from 'components/DialogOverlay';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    deleteAppBar: {
        position: 'relative',
        backgroundColor: theme.palette.error.main
    },
}));

export default function GuestsSettings() {
    const [sessionContext] = useSessionContext();
    const classes = useStyles();
    const [t] = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ rows, setRows ] = React.useState<GuestTypeData[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [editedRow, setEditedRow] = React.useState<string>("");
    
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        guestTypesService.getAll(sessionContext.token)
            .then(res => setRows(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }
    
    const containsSearchTerm = (guestType: GuestTypeData, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return guestType.name.toLowerCase().includes(term);
    };
    
    const handleClickEditOpen = (selected: string) => {
        setEditedRow(selected);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (selected: string) => {
        setEditedRow(selected);
        setDeleteOpen(true);
    };

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        refreshData();
    };

    const handleDelete = () => {
        guestTypesService.deleteGuestType(editedRow, sessionContext.token)
            .then(res => {
                handleClose();
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), 'error'));
    };
    
    return (
        <>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add guest type")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            
            <Box mt={2}>
                <GuestsSettingsTable
                    rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickDeleteOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add guest type")}>
                <AddGuestSettingsForm handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit guest type")}>
                <AddGuestSettingsForm editedGuestType={editedRow} handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete guest types")}>
                <DeleteDialog type="guest types" handleDelete={handleDelete} names={rows.filter(row => row.uuid === editedRow).map(row => row.name)} handleClose={handleClose} />
            </DialogOverlay>
        </>
    );
}
