import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { ChartData } from "chart.js";
import { memo, useEffect, useRef, useState } from "react";
import { EventAlarmsGetResponse } from "services/device-management/types";
import { addHours, addMinutes, format } from "date-fns";
import { ChartLabel } from "./ChartLabel";

const useStyles = makeStyles((theme) => ({
    graph: {
        width: '100%!important',
        maxHeight: 250,
    },
}));

interface DashboardAlarmsChartProps {
    alarms?: EventAlarmsGetResponse;
    isLessThenOneDayRange: boolean;
}

function DashboardAlarmsChart(props: DashboardAlarmsChartProps) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { alarms, isLessThenOneDayRange } = props;
    const [ data, setData ] = useState<ChartData | undefined>(undefined);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        const range = alarms?.range ?? [];

        setData({
            labels: range.map((r, i) => {
                if (i % 2 || !r.day) return "";
                const date = addMinutes(new Date(r.day), (new Date()).getTimezoneOffset());

                return format(date, isLessThenOneDayRange ? "HH:mm" : "dd.MM")
            }),
            datasets: [
                {
                    label: t("Door alarms"),
                    data: range.map(r => r.doorAlarms),
                    fill: true,
                    backgroundColor: "rgba(45, 162, 45,0.5)",
                    borderSkipped: "bottom",
                    borderRadius: 16,
                    animation: false,
                    // borderColor: "rgba(75,192,192,1)",
                    // borderWidth: 2,
                    // borderRadius: 5,
                    // borderSkipped: false,
                },
                {
                    label: t("Device alarms"),
                    data: range.map(r => r.deviceAlarms),
                    fill: false,
                    backgroundColor: "rgba(162,45,45,0.5)",
                    borderSkipped: "bottom",
                    borderRadius: 16,
                    animation: false,
                    // borderColor: "rgba(150,40,200,1)",
                    // borderWidth: 2,
                    // borderRadius: 5,
                    // borderSkipped: false,
                },
            ],
        })
    }, [ alarms, isLessThenOneDayRange, t ]);
    
    return (
        <Grid container direction="column">
            <Grid item>
                {t("Alarms total")}
            </Grid>
            <ChartLabel
                chartRef={chartRef}
                data={data}
            />
            <Grid item>
                <Bar
                    data={data}
                    className={classes.graph}
                    options={{                        
                        // responsive: true,
                        // maintainAspectRatio: true,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        scales: {
                            x: {
                                grid: {
                                    lineWidth: 1,
                                    //drawBorder: false

                                }
                            },
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    precision: 0,
                                },
                                grid: {
                                    lineWidth: 1,
                                    //drawBorder: false
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                              callbacks: {
                                title: (tooltipItems: any[]) => {
                                    const { dataIndex } = tooltipItems[0];
                                    const day = alarms?.range[dataIndex]?.day;
                                    if (!day) return "";
                                    const date = addMinutes(new Date(day), (new Date()).getTimezoneOffset());

                                    if (isLessThenOneDayRange)
                                        return `${format(date, "HH:mm")} - ${format(addHours(date, 1), "HH:mm")}`;

                                    return format(date, "dd.MM.yyyy");
                                },
                              }
                            },
                            legend: {
                                display: false,
                            }
                        }
                    }}
                    ref={chartRef}
                />
            </Grid>
        </Grid>
    );
}

const propsAreEqual = (prevData: DashboardAlarmsChartProps, nextData: DashboardAlarmsChartProps) =>
    JSON.stringify(prevData.alarms?.range) === JSON.stringify(nextData.alarms?.range);

export default memo(DashboardAlarmsChart, propsAreEqual);
