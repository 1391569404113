import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, Dialog, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DeviceGroupData, deviceGroupService } from 'services/device-management/deviceGroup.service';
import DeleteDialog from 'components/DeleteDialog';
import { useSessionContext } from 'contexts/SessionContext';
import DeviceGroupsTable from './DeviceGroupsTable';
import AddDeviceGroupForm from './AddDeviceGroupForm';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    deleteAppBar: {
        position: 'relative',
        backgroundColor: theme.palette.error.main
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(6) + 1,
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginLeft: theme.spacing(8) + 1,
            marginRight: 'auto',
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function MyDevicesDeviceGroupsTab() {
    const classes = useStyles();
    const theme = useTheme();
    const [sessionContext] = useSessionContext();
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deletedDeviceGroup, setDeletedDeviceGroup] = React.useState<string[]>([]);
    const [editedId, setEditedId] = React.useState<string>("");
    const [rows, setRows] = React.useState<DeviceGroupData[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const xsmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        deviceGroupService.getAll(sessionContext.token)
            .then(res => {
                setRows(res.map(r => ({
                    id: r.uuid,
                    color: r.color ?? "none",
                    name: r.name,
                    description: r.description ?? "",
                    controllers: (r.devices.length === 0 ? "" : "+") + r.devices.length,
                    status: r.isActive ? "Active" : "Not active",
                })));
            })
            .catch(e => console.log(e));
    }, [deletedDeviceGroup]);

    const [t] = useTranslation();

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickEditOpen = (id: string): void => {
        setEditedId(id);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (groups: string | string[]) => {
        setDeletedDeviceGroup(typeof groups === 'string' ? [groups] : groups);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setDeletedDeviceGroup([]);
        setEditedId("");
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
    };

    const handleDelete = async () => {
        await deviceGroupService.deleteDeviceGroups(deletedDeviceGroup, sessionContext.token)
        
        setDeletedDeviceGroup([]);
        handleClose();
    };

    const containsSearchTerm = (group: DeviceGroupData, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return group.name.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add group")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Box mt={2}>
                <DeviceGroupsTable
                    rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickDeleteOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Box>

            <Dialog fullScreen open={addOpen} onClose={handleClose} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Add group")}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AddDeviceGroupForm handleClose={handleClose} />
            </Dialog>

            <Dialog fullScreen open={editOpen} onClose={handleClose} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Edit group")}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AddDeviceGroupForm editedGroup={editedId} handleClose={handleClose} />
            </Dialog>

            <Dialog fullScreen open={deleteOpen} onClose={handleClose} >
                <AppBar className={classes.deleteAppBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Delete group")}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DeleteDialog
                    type="device groups"
                    names={rows.filter(row => deletedDeviceGroup.includes(row.id)).map(row => row.name)}
                    handleDelete={handleDelete}
                    handleClose={handleClose} />
            </Dialog>

        </React.Fragment>
    );
}