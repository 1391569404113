export interface Report {
  uuid: string;
  deviceName: string;
  timestamp: string;
  eventTypeName: string;
  employeeId: string;
  employeeName: string;
  employeeFirstName: string;
  employeeLastName: string;
  entryStatus: string;
  employeeDepartment: string;
  day: string;
  weekDay: number;
  scheduleStart: string;
  scheduleEnd: string;
  entranceTime: string;
  exitTime: string;
  normalTime: string;
  workTime: string;
  workTimeInSeconds: number;
  normalTimeInSeconds: number;
  cameLateBy: string;
  cameLateByInSeconds: number;
  leftEarlierBy: string;
  leftEarlierByInSeconds: number;
  afterHours: string;
  nightHours: string;
  nightHoursInSeconds: number;
  breakTimeInSeconds: number;
  breakTime: string;
  customEntryUuid?: string;
  description?: string;
};

export interface ReportEventData {
  deviceName: Report['deviceName'];
  timestamp: Report['timestamp'];
  eventTypeName: Report['eventTypeName'];
  employeeId: Report['employeeId'];
  employeeName: Report['employeeName'];
  entryStatus: Report['entryStatus'];
}

export interface ReportSummarizedWorkTimeData {
  employeeId: Report['employeeId'];
  employeeFirstName: Report['employeeFirstName'];
  employeeLastName: Report['employeeLastName'];
  employeeDepartment: Report['employeeDepartment'];
  normalTimeInSeconds?: Report['normalTimeInSeconds'];
  workTimeInSeconds?: Report['workTimeInSeconds'];
  cameLateByInSeconds?: Report['cameLateByInSeconds'];
  leftEarlierByInSeconds?: Report['leftEarlierByInSeconds'];
  nightHoursInSeconds?: Report['nightHoursInSeconds'];
  breakTimeInSeconds?: Report['breakTimeInSeconds'];
}

export interface ReportSummarizedWorkTimeTableData {
  employeeId: Report['employeeId'];
  employeeName: Report['employeeName'];
  employeeDepartment: Report['employeeDepartment'];
  workTime: Report['workTime'];
  normalTime: Report['normalTime'];
  cameLateBy: Report['cameLateBy'];
  leftEarlierBy: Report['leftEarlierBy'];
  nightHours: Report['nightHours'];
  breakTime: Report['breakTime'];
}

export enum ReportSummarizedWorkTimeSortOrderBy {

}
