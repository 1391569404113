import React, { FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch } from '@mui/material';

import { useCheckboxInput, useInput, useMultiselectInput, useSelectInput } from 'hooks/input-hook';
import { guestsService } from 'services/account-management';
import { GuestCreateRequest, GuestUpdateRequest } from 'services/account-management/types/guest';
import { useSessionContext } from 'contexts/SessionContext';
import { employeesService } from 'services/company-management';
import { GuestTypeData, guestTypesService } from 'services/account-management/guestTypes.service';
import { EmployeeDataShort } from 'services/company-management/employees.service';
import { useSnackbar, VariantType } from 'notistack';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import LocationSelect from 'components/LocationSelect';
import { set } from 'date-fns/esm';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import LazyLoadIcon from 'components/LazyLoadIcon';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
}));

interface AddGuestFormProps {
    editedGuest?: string;
    handleClose: () => void;
};

export default function AddGuestForm(props: AddGuestFormProps) {
    const classes = useStyles();
    const [sessionContext] = useSessionContext();
    const { editedGuest, handleClose } = props;
    const [ supervisors, setSupervisors ] = useState<EmployeeDataShort[]>([]);
    const [ guestTypes, setGuestTypes ] = useState<GuestTypeData[]>([]);
    const { value: firstName, setValue: setFirstName, bind: bindFirstName } = useInput("");
    const { value: lastName, setValue: setLastName, bind: bindLastName } = useInput("");
    const { value: company, setValue: setCompany, bind: bindCompany } = useInput("");
    const { value: guestTypeUuid, setValue: setGuestTypeUuid, bind: bindGuestTypeUuid } = useSelectInput("");
    const { value: supervisor, bind: bindSupervisor } = useSelectInput("");
    const { value: cardId, bind: bindCardId } = useInput("", /^\d*$/);
    const { value: reusable, bind: bindReusable } = useCheckboxInput(false);
    const { value: locations, setValue: setLocations, bind: bindLocations } = useMultiselectInput([]);
    const [validUntilDate, setValidUntilDate] = React.useState<Date | null>(set(new Date(), { hours: 23, minutes: 59 }));
    const { value: phone, setValue: setPhone, bind: bindPhone } = useInput("");
    const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
    const { value: PIN, bind: bindPIN, setValue: setPIN } = useInput("", /^\d{0,4}$/);

    const [ guestTypeError, setGuestTypeError ] = useState(false);
    const [ supervisorError, setSupervisorError ] = useState(false);
    const [ cardIdError, setCardIdError ] = useState(false);
    const [ validDateError, setValidDateError ] = useState(false);
    const [ firstNameError, setFirstNameError ] = useState(false);
    const [ lastNameError, setLastNameError ] = useState(false);
    const [ companyError, setCompanyError ] = useState(false);
    const [ phoneError, setPhoneError ] = useState(false);
    const [ locationError, setLocationError ] = React.useState<boolean>(false);
    const [ emailError, setEmailError ] = React.useState<boolean>(false);
    const [ pinError, setPinError ] = React.useState<boolean>(false);
    const [ guestTypeAutofocus, setGuestTypeAutofocus ] = React.useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const [t] = useTranslation();

    useEffect(() => {
        refreshData();
    }, []);

    // TODO dodac w api sprawdzanie też pinu gości
    const handleRandomPin = () => {
        employeesService.getRandomPin(sessionContext.token)
            .then(res => setPIN(res.pin))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }
    
    const refreshData = () => {
        if (!editedGuest) employeesService.getAll(sessionContext.token)
            .then(res => res.filter(data => (
                // data.location?.uuid === locationUuid &&
                (data.visitorsAllowed === undefined || !!data.visitorsAllowed)
            )))
            .then(res => {
                let temp: EmployeeDataShort[] = [];

                res.forEach(({ uuid, firstName, lastName }) => {
                    temp.push({
                        uuid,
                        name: `${firstName} ${lastName}`
                    })
                })
                
                setSupervisors(temp);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))

        guestTypesService.getAll(sessionContext.token)
            .then(res => {
                setGuestTypes(res)
                setGuestTypeAutofocus(true);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
        
        if (!editedGuest) return;

        guestsService.getGuest(editedGuest, sessionContext.token)
            .then(res => {
                if (!res) throw Error("Not found");

                setFirstName(res.firstName);
                setLastName(res.lastName);
                setCompany(res.company);
                setEmail(res.email);
                if (res.guestType) setGuestTypeUuid(res.guestType.uuid);
                // if (res.supervisor) setSupervisor(res.supervisor.uuid);
                // setCardId(res.cardNumber);
                // setReusable(!res.oneTime);
                // setValidUntil((!res.oneTime && res?.expirationDate?.toString()?.substring(0, 10)) ?? "");
                setPhone(res.phoneNumber);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const resetErrorLog = () => {
        setGuestTypeError(false);
        setSupervisorError(false);
        setCardIdError(false);
        setValidDateError(false);
        setFirstNameError(false);
        setLastNameError(false);
        setCompanyError(false);
        setPhoneError(false);
        setEmailError(false);
        setPinError(false);
    }
    
    function validateOk(): boolean {
        resetErrorLog();
        let temp = false;

        if (!guestTypeUuid.length) {
            setGuestTypeError(true);
            temp = true;
        }

        if (!editedGuest) {
            if (!locations.length) {
                setLocationError(true);
                temp = true;
            }

            if (!supervisor.length) {
                setSupervisorError(true);
                temp = true;
            }

            if (!cardId.length && !PIN.length) {
                setCardIdError(true);
                setPinError(true);
                temp = true;
            }

            if (!validUntilDate && reusable) {
                setValidDateError(true);
                temp = true;
            }
        }

        if (!firstName.length) {
            setFirstNameError(true);
            temp = true;
        }

        if (!lastName.length) {
            setLastNameError(true);
            temp = true;
        }

        if (!phone.length || phone.length > 9) {
            setPhoneError(true);
            temp = true;
        }

        if (temp) {
            handleSnackBar("You have to fill in all spaces", "error")
            return false;
        }

        const cardFormat = /^\d*$/;
        if (!cardFormat.test(cardId) && !editedGuest) {
            setCardIdError(true);
            handleSnackBar("Wrong card format", 'error')
            return false;
        }

        const mailFormat = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (email.length > 0 && !mailFormat.test(email)) {
            setEmailError(true);
            handleSnackBar("Wrong email address", 'error')
            return false;
        }

        // const phoneFormat = /^(\d{3}-?){3}$/;
        const phoneFormat = /^\+?(\d*-?\d*)*$/;
        if (!phoneFormat.test(phone)) {
            setPhoneError(true);
            handleSnackBar("Wrong phone format", 'error')
            return false;
        }
        
        return true;
    }

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        if (!validateOk()) return;

        const req: GuestUpdateRequest = {
            firstName,
            lastName,
            guestTypeUuid,
            company: company.length ? company : undefined,
            phoneNumber: Number(phone.toString().replaceAll("-", "")),
        };

        if (editedGuest) {
            guestsService.updateGuest(editedGuest, req, sessionContext.token)
                .then(() => {
                    handleSnackBar(ResponseSuccessMessages.EDIT, 'success')
                    handleClose()
                })
                .catch(err => {
                    if (err.status === 409) handleSnackBar(err.data.message ?? "", 'warning')
                    else handleSnackBar(getCreateErrorText(err), 'error')
                });
        } else {
            const reqCreate: GuestCreateRequest = {
                ...req,
                locations: locations.map(locationUuid => ({ locationUuid })),
                cardNumber: cardId.length ? cardId : undefined,
                pin: PIN.length ? PIN : undefined,
                supervisorUuid: supervisor,
                oneTime: !reusable,
                expirationDate: reusable && validUntilDate ? validUntilDate : undefined,
            };
            
            guestsService.createGuest(reqCreate, sessionContext.token)
                .then(() => {
                    handleSnackBar(ResponseSuccessMessages.ADD, 'success')
                    handleClose()
                })
                .catch(err => {
                    if (err === 409) {
                        handleSnackBar("Pin or card number is already in use", 'warning')
                        setPinError(true)
                        setCardIdError(true)
                    }
                    else handleSnackBar(getCreateErrorText(err), 'error')
                });
        }
    };

    const handleDateChange = (date: Date | null) => {
        setValidUntilDate(date);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("Insert guest data")}
                    </Typography>
                </Box>
                <form className={classes.formControl} noValidate onSubmit={handleOnSubmit}>
                    <FormControl fullWidth sx={{ width: '100%', mt: 1 }} required variant='standard'>
                        <InputLabel id="guest-type-select-label">
                            {guestTypes.length === 0 ? t("No guest types") : t("Choose guest type")}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="guest-type-select-label"
                            id="guest-type-select"
                            error={guestTypeError}
                            disabled={guestTypes.length === 0}
                            autoFocus={guestTypeAutofocus}
                            {...bindGuestTypeUuid}
                        >
                            {guestTypes ? guestTypes.map((type, index) => (
                                <MenuItem key={index} value={type.uuid}>
                                    {type.name}
                                </MenuItem >
                            )) : ""}
                        </Select>
                        {guestTypes.length === 0 && <FormHelperText>{t("Add guest type in Settings -> My guests")}</FormHelperText>}
                    </FormControl>
                    {!editedGuest && <FormControl fullWidth sx={{ mt: 3 }} required variant='standard'>
                        <InputLabel id="supervisor-select-label">
                            {supervisors.length === 0 ? t("No supervisors") : t("Choose supervisor")}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="supervisor-select-label"
                            id="supervisor-select"
                            error={supervisorError}
                            disabled={supervisors.length === 0}
                            {...bindSupervisor}
                        >
                            {supervisors && supervisors.map((supervisor, index) => (
                                <MenuItem key={index} value={supervisor.uuid ?? "00000000-0000-0000-0000-000000000000"}>
                                    {supervisor.name ?? t("nd")}
                                </MenuItem >
                            ))}
                        </Select>
                        {supervisors.length === 0 && (
                            <>
                                <FormHelperText>
                                    {t("Add employee in My company -> Employees")}
                                </FormHelperText>
                                <FormHelperText>
                                    {t("Then grand him to supervisor by editing him")}
                                </FormHelperText>
                            </>
                        )}
                    </FormControl>}
                    {!editedGuest && <FormControl fullWidth sx={{ mt: 3 }} required>
                        <LocationSelect
                            required
                            multiple
                            error={locationError}
                            setValue={setLocations}
                            {...bindLocations}
                        />
                    </FormControl>}
                    {!editedGuest && <>
                        <Box mt={3}>
                            <Grid component="label" container item xs={12} justifyContent="center" alignItems="center" spacing={1}>
                                <Grid item>{t('One time only')}</Grid>
                                <Grid item>
                                    <Switch color='primary' name="reusableSwitch" {...bindReusable} />
                                </Grid>
                                <Grid item>{t('Long term')}</Grid>
                            </Grid>
                        </Box>
                        <DateTimePicker
                            onChange={handleDateChange}
                            value={validUntilDate}
                            disabled={!reusable}
                            minDate={new Date()}
                            format='MM/dd/yyyy HH:mm'
                            label={t("Valid until")}
                            ampm={false}
                            sx={{ width: "100%", mt: 3 }}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                        />
                        <TextField
                            variant="standard"
                            margin="none"
                            required={!PIN.length}
                            fullWidth
                            id="cardId"
                            label={t("Card ID")}
                            name="cardId"
                            autoComplete="cardId"
                            error={cardIdError}
                            {...bindCardId}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required={!cardId.length}
                            fullWidth
                            id="PIN"
                            label={t("PIN")}
                            name="PIN"
                            autoComplete="PIN"
                            error={pinError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="get random pin"
                                            onClick={handleRandomPin}
                                            onMouseDown={e => e.preventDefault()}
                                            size="large">
                                            <LazyLoadIcon iconName='Casino' />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...bindPIN}
                        />
                    </>}
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label={t("First name")}
                        name="firstName"
                        autoComplete="firstName"
                        error={firstNameError}
                        {...bindFirstName}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label={t("Last name")}
                        name="lastName"
                        autoComplete="lastName"
                        error={lastNameError}
                        {...bindLastName}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        id="phone"
                        label={t("Phone number")}
                        name="phone"
                        autoComplete="phone"
                        error={phoneError}
                        {...bindPhone}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        multiline
                        id="company"
                        label={t("Company")}
                        name="company"
                        autoComplete="company"
                        error={companyError}
                        {...bindCompany}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="email"
                        label={t("Email Address")}
                        name="email"
                        autoComplete="email"
                        error={emailError}
                        {...bindEmail}
                    />
                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedGuest ? "Save" : "Register")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}