import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { getMonth, getYear } from 'date-fns';
import { getMonths } from '../utils';
import LazyLoadIcon from 'components/LazyLoadIcon';

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  locale?: Locale;
  displayMode: "normal" | "months" | "years";
  setDisplayMode: React.Dispatch<React.SetStateAction<"normal" | "months" | "years">>;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  locale,
  setDisplayMode,
  displayMode,
}: HeaderProps) => {
  const MONTHS = typeof locale !== 'undefined'
    ? [0,1,2,3,4,5,6,7,8,9,10,11].map(d => locale.localize?.month(d, {width: 'abbreviated', context: 'standalone'}))
    : getMonths();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{ padding: '5px' }}>
        <IconButton
          sx={{
            padding: '10px',
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <LazyLoadIcon iconName='ChevronLeft' />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography onClick={() => setDisplayMode("months")} variant='subtitle1' sx={{ cursor: "pointer" }}>{MONTHS[getMonth(date)]}</Typography>
      </Grid>

      <Grid item>
        <Typography onClick={() => setDisplayMode("years")} variant='subtitle1' sx={{ cursor: "pointer" }}>{getYear(date)}</Typography>
      </Grid>
      <Grid item sx={{ padding: '5px' }}>
        <IconButton
          sx={{
            padding: '10px',
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          <LazyLoadIcon iconName='ChevronRight' />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;