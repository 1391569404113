import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Box, Button, Checkbox, Container, Dialog, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputLabel, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import { useCheckboxInput, useInput, useRadioInput } from 'hooks/input-hook';
import AddRoleForm from './AddEvacuationEmailForm';
import { EvacuationListEmail, evacuationListEmailService } from 'services/setting-management/evacuationList.service';
import { useSessionContext } from 'contexts/SessionContext';
import { useSnackbar, VariantType } from 'notistack';
import { getCreateErrorText, getDeleteErrorText, ResponseSuccessMessages } from 'services/genericService';
import DeleteDialog from 'components/DeleteDialog';
import { EvacuationListSettingsGetResponse, EvacuationListSettingsUpdateRequest, EvacuationListSort } from 'services/setting-management/types';
import { evacuationListSettingsService } from 'services/setting-management/evacuationListSettings.service';
import DialogOverlay from 'components/DialogOverlay';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontSize: 25,
    },
    label: {
        color: "black",
    },
    biggerDivider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    actionsColumn: {
        width: "150px",
    },
    appBar: {
        position: 'relative',
    },
}));


export default function EvacuationSettings() {
    const classes = useStyles();
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();

    const { value: sortEvacuationBy, setValue: setSortEvacuationBy, bind: bindSortEvacuationBy } = useRadioInput("1");
    const { value: createListAfterFireDetectedChecked, setValue: setCreateListAfterFireDetectedChecked, bind: bindCreateListAfterFireDetectedChecked } = useCheckboxInput(false);
    const [ rows, setRows ] = useState<EvacuationListEmail[]>([]);
    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [editedId, setEditedId] = React.useState<string>();

    const radioLabels = [
        { value: EvacuationListSort.SURNAME.toString(), label: t("Sort by name") },
        { value: EvacuationListSort.DEPARTMENTS.toString(), label: t("Sort by department") },
    ];

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        updateSettings();
    }, [sortEvacuationBy, createListAfterFireDetectedChecked]);

    const loadData = () => {
        evacuationListEmailService.getAll(sessionContext.token)
            .then(res => setRows(res))
            .catch(err => handleSnackBar(getCreateErrorText(err), 'error'))
        
        evacuationListSettingsService.getEvacuationListSettings(sessionContext.token)
            .then(res => {
                setSortEvacuationBy(res.sortBy.toString());
                setCreateListAfterFireDetectedChecked(res.generateOnAlarm);
            })
            .catch(err => {})
    }

    const updateSettings = () => {
        let sortBy = EvacuationListSort.SURNAME;
        if (sortEvacuationBy === EvacuationListSort.DEPARTMENTS.toString()) sortBy = EvacuationListSort.DEPARTMENTS;
        
        const dept: EvacuationListSettingsUpdateRequest = {
            sortBy,
            generateOnAlarm: createListAfterFireDetectedChecked,
        };

        evacuationListSettingsService.updateEvacuationListSettings(dept, sessionContext.token);
    }

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };
    
    const handleClickEditOpen = (id: string): void => {
        setEditedId(id);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (id: string): void => {
        setEditedId(id);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setDeleteOpen(false);
        setEditOpen(false);
        loadData();
    };

    const handleDelete = () => {
        evacuationListEmailService.deleteEvacuationListEmail((editedId as string), sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), "error"));
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h6" id="tableTitle" component="div">
                    {t("Evacuation settings")}
                </Typography>
                <Box mt={2}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t("Sort list by")}</FormLabel>
                        <RadioGroup aria-label="sort-evacuation-list-by" name="sort-evacuation-list-by" {...bindSortEvacuationBy}>
                            {radioLabels.forEach(radioLabel => (
                                <FormControlLabel value={radioLabel.value} control={<Radio />} label={radioLabel.label} />
                            ))}
                            <FormControlLabel value="1" control={<Radio />} label={t("Sort by name")} />
                            <FormControlLabel value="2" control={<Radio />} label={t("Sort by department")} />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Checkbox
                                name="create-list-after-fire-detected"
                                color="primary"
                                id="evacuation-list-fire-detection-checkbox"
                                style={{padding: 0}}
                                {...bindCreateListAfterFireDetectedChecked}
                            />
                        </Grid>
                        <Grid item>
                            <InputLabel className={classes.label} htmlFor='evacuation-list-fire-detection-checkbox'>
                                {t("Create evacuation list after device detect fire")}
                            </InputLabel>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary"  onClick={handleClickAddOpen}>
                            {t("Add e-mail")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Box mt={2}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" id="tableTitle" component="div">
                        {t("Send evacuation list to")}
                        <FormHelperText>
                            {t("List of e-mails which will receive the evacuation list")}
                        </FormHelperText>
                    </Typography>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="rolesTable"
                            aria-label="roles table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                    >
                                        {t("E-mail")}
                                    </TableCell>
                                    <TableCell
                                        align={'center'}
                                        className={classes.actionsColumn}
                                    >
                                        {t("Actions")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rows.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={row.uuid}
                                        >
                                            <TableCell component="th" scope="row" >
                                                {row.email}
                                            </TableCell>

                                            <TableCell align="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickEditOpen(row.uuid)} size="large">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickDeleteOpen(row.uuid)} size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add e-mail")}>
                <AddRoleForm handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit e-mail")}>
                <AddRoleForm handleClose={handleClose} editedEmail={editedId} />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete e-mail")}>
                <DeleteDialog type="e-mail" handleDelete={handleDelete} names={[rows.find(r => r.uuid === editedId)?.email ?? '']} handleClose={handleClose} />
            </DialogOverlay>
        </div>
    );
}