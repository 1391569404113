import React, { useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';

import { locationsService } from 'services/company-management/locations.service';
import { LocationGetAllResponse } from "services/company-management/types";
import { useSessionContext } from 'contexts/SessionContext';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { Box, Button, CssBaseline, Grid } from '@mui/material';
import LocationsTable from './LocationsTable';
import DialogOverlay from 'components/DialogOverlay';
import AddLocationForm from './AddLocationForm';
import DeleteDialog from 'components/DeleteDialog';

function MyCompanyLocationsTab() {
    const [sessionContext] = useSessionContext();
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [editedRowId, setEditedRowId] = React.useState<string>("");
    const [locationsSelected, setLocationsSelected] = React.useState<string[]>([])
    const [rows, setRows] = React.useState<LocationGetAllResponse[]>([]);
    const [t] = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    useEffect(() => {
        locationsService.getAll(sessionContext.token)
            .then(res => {
                setRows(res);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }, [editedRowId]);

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickEditOpen = (id: string) => {
        setEditedRowId(id);
        setEditOpen(true);
    };

    const handleClickSingleDeleteOpen = (idList: string[]) => {
        setLocationsSelected(idList);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        setEditedRowId("");
        refreshList();
    };

    const handleDelete = async () => {
        await locationsService.deleteLocations(locationsSelected, sessionContext.token)
            .then(res => {
                if (res.every(req => req.status === 200)) {
                    handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                    setLocationsSelected([]);
                } else {
                    handleSnackBar(getDeleteErrorText(423), "error");
                    setLocationsSelected([]);
                }
                handleClose();
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), 'error'));
    };

    const refreshList = async () => {
        locationsService.getAll(sessionContext.token)
            .then(res => {
                setRows(res);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    };

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const containsSearchTerm = (location: LocationGetAllResponse, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return location.name.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add location")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Box mt={2}>
                <LocationsTable
                    rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                    selected={locationsSelected}
                    setSelected={setLocationsSelected}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickSingleDeleteOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add location")}>
                <AddLocationForm handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit location")}>
                <AddLocationForm editedLocation={editedRowId} handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete location")}>
                <DeleteDialog
                    type="locations"
                    handleDelete={handleDelete}
                    names={rows.filter(row => locationsSelected.includes(row.uuid)).map(row => row.name)}
                    handleClose={handleClose}
                />
            </DialogOverlay>
        </React.Fragment>
    );
}

export default MyCompanyLocationsTab;
