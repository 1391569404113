import { apiUrl } from "../../config";

const getDeviceGroupsEndpoint = () => `${apiUrl}/device-groups`;
const getDeviceGroupEndpoint = (deviceGroupId: string) => `${apiUrl}/device-groups/${deviceGroupId}`;
const createDeviceGroupEndpoint = () => `${apiUrl}/device-groups`;
const updateDeviceGroupEndpoint = (deviceGroupId: string) => `${apiUrl}/device-groups/${deviceGroupId}`;
const deleteDeviceGroupEndpoint = (deviceGroupId: string) => `${apiUrl}/device-group/${deviceGroupId}`;

export {
  getDeviceGroupsEndpoint,
  getDeviceGroupEndpoint,
  createDeviceGroupEndpoint,
  updateDeviceGroupEndpoint,
  deleteDeviceGroupEndpoint,
};
