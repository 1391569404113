import PermissionContext from 'contexts/PermissionContext';
import { useContext } from 'react';
import { PermissionCategory } from 'services/account-management';

const usePermission = (permission: PermissionCategory | PermissionCategory[]) => {
    const {isAllowedTo} = useContext(PermissionContext);

    if (Array.isArray(permission)) {
        let temp = false;

        permission.forEach(p => isAllowedTo(p) && (temp = true))

        return [temp, false];
    }
    return [ isAllowedTo(permission), false ];
}

export default usePermission;
