import { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSessionContext } from 'contexts/SessionContext';
import AddWorkTimeSchedule from './AddWorkTimeSchedule';
import { WorktimeScheduleSettingsTable } from 'services/setting-management/types/worktimeSchedules';
import { useSnackbar, VariantType } from 'notistack';
import { worktimeSchedulesService } from 'services/setting-management/worktimeSchedules.service';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import DeleteDialog from 'components/DeleteDialog';
import DialogOverlay from 'components/DialogOverlay';
import WorktimeSettingsTable from './WorktimeSettingsTable';

export default function WorktimeSettings() {
    const [sessionContext] = useSessionContext();
    const [t] = useTranslation();

    const [ schedules, setSchedules ] = useState<WorktimeScheduleSettingsTable[]>([]);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editedRow, setEditedRow] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        worktimeSchedulesService.getAll(sessionContext.token)
            .then(res => setSchedules(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }

    const handleDelete = () => {
        worktimeSchedulesService.deleteWorktimeSchedule(editedRow, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), "error"));
    }

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        refreshData();
    }

    const handleClickAddOpen = () => setAddOpen(true)

    const handleClickEditOpen = (uuid: string) => {
        setEditedRow(uuid);
        setEditOpen(true);
    }

    const handleClickDeleteOpen = (uuid: string) => {
        setEditedRow(uuid);
        setDeleteOpen(true);
    }
    
    const containsSearchTerm = (row: WorktimeScheduleSettingsTable) => {
        const term = searchTerm.toLowerCase();
        return row.name.toLowerCase().includes(term);
    }

    return <>
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item container alignItems="center" xs>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                        {t("Add work time schedule")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        
        <Box mt={2}>
            <WorktimeSettingsTable
                rows={searchTerm ? schedules.filter(containsSearchTerm) : schedules}
                handleClickEditOpen={handleClickEditOpen}
                handleClickDeleteOpen={handleClickDeleteOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
        </Box>

        <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add work time schedule")}>
            <AddWorkTimeSchedule
                handleClose={handleClose}
            />
        </DialogOverlay>

        <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit work time schedule")}>
            <AddWorkTimeSchedule
                selectedUuid={editedRow}
                handleClose={handleClose}
            />
        </DialogOverlay>

        <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete work time schedule")}>
            <DeleteDialog type="work time schedule" handleDelete={handleDelete} names={schedules.filter(schedule => schedule.uuid === editedRow).map(schedule => schedule.name)} handleClose={handleClose} />
        </DialogOverlay>
    </>;
}