import { genericGet, genericPut } from "../genericService";
import { getEvacuationListSettingsEndpoint, updateEvacuationListSettingsEndpoint } from "./endpoints";
import { EvacuationListSettingsGetResponse, EvacuationListSettingsUpdateRequest } from "./types";

const getEvacuationListSettings = async(token?: string): Promise<EvacuationListSettingsGetResponse> => {
  return await genericGet<EvacuationListSettingsGetResponse>(getEvacuationListSettingsEndpoint(), {}, token);
};

const updateEvacuationListSettings = async(dept: EvacuationListSettingsUpdateRequest, token?: string): Promise<EvacuationListSettingsGetResponse> => {
  return await genericPut<EvacuationListSettingsGetResponse>(updateEvacuationListSettingsEndpoint(), dept, token);
};

export const evacuationListSettingsService = {
  getEvacuationListSettings,
  updateEvacuationListSettings,
};

