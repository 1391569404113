import { apiUrl } from "../../config";

const getGuestsEndpoint = (locationUuid?: string, showRegisteredOnly?: boolean) => `${apiUrl}/guests?${locationUuid ? `locationUuid=${locationUuid}` : ""}${showRegisteredOnly ? `&showRegisteredOnly=true` : ""}`;
const getGuestEndpoint = (uuid: string) => `${apiUrl}/guests/${uuid}`;
const createGuestEndpoint = () => `${apiUrl}/guests`;
const updateGuestEndpoint = (uuid: string) => `${apiUrl}/guests/${uuid}`;
const deleteGuestEndpoint = (uuid: string) => `${apiUrl}/guests/${uuid}`;
const registerGuestEndpoint = (uuid: string) => `${apiUrl}/guests/${uuid}/register`;
const unregisterGuestEndpoint = (uuid: string) => `${apiUrl}/guests/${uuid}/unregister`;

export {
  getGuestsEndpoint,
  getGuestEndpoint,
  createGuestEndpoint,
  updateGuestEndpoint,
  deleteGuestEndpoint,
  registerGuestEndpoint,
  unregisterGuestEndpoint,
};
