import React, { FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';

import { GuestRegisterRequest, guestsService } from 'services/account-management';
import { useCheckboxInput, useInput, useMultiselectInput, useSelectInput } from 'hooks/input-hook';
import { EmployeeDataShort, employeesService } from 'services/company-management/employees.service';
import { useSessionContext } from 'contexts/SessionContext';
import { VariantType, useSnackbar } from 'notistack';
import { ResponseSuccessMessages, getCreateErrorText, getGetErrorText } from 'services/genericService';
import { addDays } from 'date-fns';
import LocationSelect from 'components/LocationSelect';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import LazyLoadIcon from 'components/LazyLoadIcon';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
}));

interface RegisterGuestDialogProps {
    selectedUuid: string;
    handleClose: () => void;
}

export default function RegisterGuestDialog(props: RegisterGuestDialogProps) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const { selectedUuid, handleClose } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [ supervisors, setSupervisors ] = useState<EmployeeDataShort[]>([]);
    const [ name, setName ] = useState<string>("");

    const { value: supervisor, bind: bindSupervisor } = useSelectInput("");
    const { value: cardId, bind: bindCardId } = useInput("", /^\d*$/);
    const { value: reusable, bind: bindReusable } = useCheckboxInput(false);
    const [validUntilDate, setValidUntilDate] = React.useState<Date | null>(addDays(new Date(), 1));
    const { value: locations, setValue: setLocations, bind: bindLocations } = useMultiselectInput([]);
    const { value: PIN, bind: bindPIN, setValue: setPIN } = useInput("", /^\d{0,4}$/);

    const [ supervisorError, setSupervisorError ] = useState(false);
    const [ cardIdError, setCardIdError ] = useState(false);
    const [ validDateError, setValidDateError ] = useState(false);
    const [ locationError, setLocationError ] = React.useState<boolean>(false);
    const [ pinError, setPinError ] = React.useState<boolean>(false);
    const [ supervisorAutofocus, setSupervisorAutofocus ] = React.useState<boolean>(false);

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        employeesService.getAll(sessionContext.token)
            .then(res => res.filter(data => (
                // data.location?.uuid === locationUuid &&
                (data.visitorsAllowed === undefined || !!data.visitorsAllowed)
            )))
            .then(res => {
                let temp: EmployeeDataShort[] = [];

                res.forEach(({ uuid, firstName, lastName }) => {
                    temp.push({
                        uuid,
                        name: `${firstName} ${lastName}`
                    })
                })
                
                setSupervisors(temp);
                setSupervisorAutofocus(true);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
        
        guestsService.getGuest(selectedUuid, sessionContext.token)
            .then(res => {
                if (!res) throw Error("Not found");

                setName(`${res.firstName} ${res.lastName}`);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    const handleOnSubmit = async (e: FormEvent): Promise<any> => {
        e.preventDefault();

        if (!validateOk()) return;

        const dept: GuestRegisterRequest = {
            cardNumber: cardId.length ? cardId : undefined,
            pin: PIN.length ? PIN : undefined,
            supervisorUuid: supervisor,
            oneTime: !reusable,
            expirationDate: reusable && validUntilDate ? validUntilDate : undefined,
            locations: locations.map(locationUuid => ({ locationUuid })),
        };

        guestsService.registerGuest(selectedUuid, dept, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.REGISTER, 'success');
                handleClose();
            })
            .catch(err => {
                if (err.status === 409) handleSnackBar(err.data.message ?? "", 'warning')
                else handleSnackBar(getCreateErrorText(err), 'error')
            });
    }

    function validateOk(): boolean {
        resetErrorLog();
        let temp = false;

        if (!locations.length) {
            setLocationError(true);
            temp = true;
        }

        if (!supervisor.length) {
            setSupervisorError(true);
            temp = true;
        }

        if (!cardId.length && !PIN.length) {
            setCardIdError(true);
            setPinError(true);
            temp = true;
        }

        if (!validUntilDate && reusable) {
            setValidDateError(true);
            temp = true;
        }

        if (temp) {
            handleSnackBar("You have to fill in all spaces", "error")
            return false;
        }

        const cardFormat = /^\d*$/;
        if (!cardFormat.test(cardId)) {
            setCardIdError(true);
            handleSnackBar("Wrong card format", 'error')
            return false;
        }
        
        return true;
    }

    const resetErrorLog = () => {
        setSupervisorError(false);
        setCardIdError(false);
        setValidDateError(false);
        setPinError(false);
    }

    const handleDateChange = (date: Date | null) => {
        setValidUntilDate(date);
    };

    const handleRandomPin = () => {
        employeesService.getRandomPin(sessionContext.token)
            .then(res => setPIN(res.pin))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }
    
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("You are about to register")}
                    </Typography>
                    <Typography component="h4" variant='h6' align='center'>
                        {name}
                    </Typography>
                </Box>

                <form className={classes.formControl} noValidate onSubmit={handleOnSubmit}>
                    <FormControl fullWidth sx={{ mt: 2, width: "100%" }} required variant='standard'>
                        <InputLabel id="supervisor-select-label">
                            {supervisors.length === 0 ? t("No supervisors") : t("Choose supervisor")}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="supervisor-select-label"
                            id="supervisor-select"
                            error={supervisorError}
                            disabled={supervisors.length === 0}
                            autoFocus={supervisorAutofocus}
                            {...bindSupervisor}
                        >
                            {supervisors && supervisors.map((supervisor, index) => (
                                <MenuItem key={index} value={supervisor.uuid ?? "00000000-0000-0000-0000-000000000000"}>
                                    {supervisor.name ?? t("nd")}
                                </MenuItem >
                            ))}
                        </Select>
                        {supervisors.length === 0 && (
                            <>
                                <FormHelperText>
                                    {t("Add employee in My company -> Employees")}
                                </FormHelperText>
                                <FormHelperText>
                                    {t("Then grand him to supervisor by editing him")}
                                </FormHelperText>
                            </>
                        )}
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2, width: "100%" }} required>
                        <LocationSelect
                            required
                            multiple
                            error={locationError}
                            setValue={setLocations}
                            {...bindLocations}
                        />
                    </FormControl>

                    <Box mt={3}>
                        <Grid component="label" container item xs={12} justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item>{t('One time only')}</Grid>
                            <Grid item>
                                <Switch color='primary' name="reusableSwitch" {...bindReusable} />
                            </Grid>
                            <Grid item>{t('Long term')}</Grid>
                        </Grid>
                    </Box>
                    <DateTimePicker
                        onChange={handleDateChange}
                        value={validUntilDate}
                        disabled={!reusable}
                        minDate={new Date()}
                        format='MM/dd/yyyy HH:mm'
                        label={t("Valid until")}
                        ampm={false}
                        sx={{ width: "100%", mt: 3 }}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                    />
                    <TextField
                        variant="standard"
                        margin="none"
                        required={!PIN.length}
                        fullWidth
                        id="cardId"
                        label={t("Card ID")}
                        name="cardId"
                        autoComplete="cardId"
                        error={cardIdError}
                        {...bindCardId}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        required={!cardId.length}
                        fullWidth
                        id="PIN"
                        label={t("PIN")}
                        name="PIN"
                        autoComplete="PIN"
                        error={pinError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="get random pin"
                                        onClick={handleRandomPin}
                                        onMouseDown={e => e.preventDefault()}
                                        size="large">
                                        <LazyLoadIcon iconName='Casino' />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...bindPIN}
                    />
                </form>

                <Grid container spacing={2}>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleClose}
                            color="primary"
                        >
                            {t("Cancel")}
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleOnSubmit}
                            color="primary"
                        >
                            {t("Register guest")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}