import { EmployeeCreateRequest, EmployeeCreateResponse, EmployeeGetAllResponse, EmployeeGetOneResponse, EmployeeUpdateRequest, EmployeeUpdateResponse } from ".";
import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createEmployeesEndpoint, deleteEmployeesEndpoint, getEmployeeEndpoint, getEmployeesEndpoint, getRandomPinEndpoint, updateEmployeesEndpoint } from "./endpoints";
import { EmployeeGetAllRequest, EmployeeOnlyPin } from "./types";

export interface EmployeeData {
    uuid: string;
    name: string;
    location: string;
    department: string;
    role: string;
    cardId: string;
    PIN: string;
}

export interface EmployeeDataShort {
    uuid: string;
    name: string;
}

const getAll = (token?: string): Promise<EmployeeGetAllResponse[]> => {
    return genericGet<EmployeeGetAllResponse[]>(getEmployeesEndpoint(), {}, token);
}

const getAllByFilter = (filter: EmployeeGetAllRequest, token?: string): Promise<EmployeeGetAllResponse[]> => {
    return genericGet<EmployeeGetAllResponse[]>(getEmployeesEndpoint(filter.locationUuid), {}, token);
}

const getAllShortVersion = (filter: EmployeeGetAllRequest, token?: string): Promise<EmployeeDataShort[]> => getAllByFilter({ locationUuid: filter.locationUuid }, token)
    .then(res => res.map(employee => ({
        uuid: employee.uuid,
        name: `${employee.firstName} ${employee.lastName}`,
    })))

const getRandomPin = (token?: string): Promise<EmployeeOnlyPin> => {
    return genericGet<EmployeeOnlyPin>(getRandomPinEndpoint(), {}, token);
}

const getEmployee = (uuid: string, token?: string): Promise<EmployeeGetOneResponse> => {
    return genericGet<EmployeeGetOneResponse>(getEmployeeEndpoint(uuid), {}, token);
}

const createEmployee = (dept: EmployeeCreateRequest, token?: string): Promise<EmployeeCreateResponse> => {
    return genericPost<EmployeeCreateResponse>(createEmployeesEndpoint(), dept, token);
}

const updateEmployee = (uuid: string, dept: EmployeeUpdateRequest, token?: string): Promise<EmployeeUpdateResponse> => {
    return genericPut<EmployeeUpdateResponse>(updateEmployeesEndpoint(uuid), dept, token);
}

const deleteEmployees = (uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
    return genericDelete(deleteEmployeesEndpoint(uuid), token);
}

export const employeesService = {
    getAll,
    getAllByFilter,
    getAllShortVersion,
    getRandomPin,
    getEmployee,
    createEmployee,
    updateEmployee,
    deleteEmployees,
}
