import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { GuestTypeData } from 'services/account-management/guestTypes.service';
import TableSearchbar from 'components/TableSearchbar';
import { WorktimeScheduleSettingsTable } from 'services/setting-management/types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof GuestTypeData;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Work time schedule name' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof GuestTypeData) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof GuestTypeData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell
                    align={'center'}
                    className={classes.actionsColumn}
                >
                    {t("Actions")}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(2),
    },
    label: {
        paddingTop: theme.spacing(4),
    },
    noCheckbox: {
        width: 8,
        height: 58,
    },
    selectLabel: {
        marginTop: theme.spacing(3),
    },
    fixedMaxWidth: {
        maxWidth: 350,
    },
    multiselect: {
        width: 200,
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    actionsColumn: {
        width: "150px",
    },

    oot: {
        width: '100%',
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
}));

interface WorktimeSettingsTableProps {
    handleClickEditOpen: (uuid: string) => void;
    handleClickDeleteOpen: (uuid: string) => void;
    rows: WorktimeScheduleSettingsTable[];
    searchTerm: string,
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
}

export default function WorktimeSettingsTable(props: WorktimeSettingsTableProps) {
    const classes = useStyles();
    const [t] = useTranslation();
    const { rows, handleClickDeleteOpen, handleClickEditOpen, searchTerm, setSearchTerm } = props;

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof WorktimeScheduleSettingsTable>('name');

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof WorktimeScheduleSettingsTable) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar className={classes.toolbarRoot}>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {t("Work time schedules")}
                    </Typography>
                    <TableSearchbar type="work-time-schedule" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                </Toolbar>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="employeesTitle"
                        aria-label="employees table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `employees-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.uuid}
                                        >
                                            <TableCell
                                                align="left"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                            >
                                                {row.name}
                                            </TableCell>

                                            <TableCell align="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickEditOpen(row.uuid)} size="large">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <IconButton onClick={() => handleClickDeleteOpen(row.uuid)} size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
