import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, IconButton, Toolbar, Typography, AppBar, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar, VariantType } from 'notistack';

import { useSessionContext } from 'contexts/SessionContext';
import { deviceControllerService } from 'services/device-management/deviceController.service';
import { DeviceControllerGetForTableResponse } from 'services/device-management/types';
import DeleteDialog from 'components/DeleteDialog';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import AddDeviceForm from './AddDeviceForm';
import DevicesTable from './DevicesTable';
import DialogOverlay from 'components/DialogOverlay';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    deleteAppBar: {
        position: 'relative',
        backgroundColor: theme.palette.error.main
    },
    appBarSpacer: theme.mixins.toolbar,
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function MyDevicesDevicesTab() {
    const classes = useStyles();
    const theme = useTheme();
    const [t] = useTranslation();
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [editedDevice, setEditedDevice] = React.useState<string>('');
    const [rows, setRows] = React.useState<DeviceControllerGetForTableResponse[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const xsmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sessionContext] = useSessionContext();
    const { enqueueSnackbar } = useSnackbar();
    let refreshTimeout: NodeJS.Timeout;
    const refreshTimeoutDelay = 30 * 1000; // 30 sec

    useEffect(() => {
        refreshTimeout = setInterval(refreshList, refreshTimeoutDelay);
    
        return () => {
            clearInterval(refreshTimeout)
        }
    }, [])

    useEffect(() => {
        refreshList();
    }, [editedDevice]);

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickEditOpen = (key: string) => {
        setEditedDevice(key);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (key: string) => {
        setEditedDevice(key);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        setEditedDevice('');
        refreshList();
    };

    const handleDelete = () => {
        deviceControllerService.deleteDevice(editedDevice, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
                setEditedDevice('');
            })
            .catch(err => {
                handleSnackBar(getDeleteErrorText(err), "error");
            });
    };

    const refreshList = () => {
        deviceControllerService.getAllForTable(sessionContext.token)
            .then(res => {
                setRows(res);
            })
            .catch(err => {
                handleSnackBar(getGetErrorText(err), "error");
            });
    };

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const containsSearchTerm = (device: DeviceControllerGetForTableResponse, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return device.serialNumber.toLowerCase().includes(term) ||
            device.location.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add device")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Box mt={2}>
                <DevicesTable
                    rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickDeleteOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add device")}>
                <AddDeviceForm handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit device")}>
                <AddDeviceForm editedDevice={editedDevice} handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete device")}>
                <DeleteDialog type="device" names={[rows.find(dev => dev.uuid === editedDevice)?.serialNumber ?? '']} handleClose={handleClose} handleDelete={handleDelete} />
            </DialogOverlay>
        </React.Fragment>
    );
}
