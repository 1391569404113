import * as React from 'react';
import {
  add,
  addMonths, addYears, differenceInDays, isAfter, isBefore, isSameDay, isSameMonth, isSameYear, isWithinInterval,
} from 'date-fns';
import { DateRange, DefinedRange, NavigationAction } from '../types';
import { getValidatedMonths, parseOptionalDate } from '../utils';
import { Marker, MARKERS } from './Markers';
import { Divider, Grid, Paper } from '@mui/material';
import DefinedRanges from './DefinedRanges';
import Month from './Month';

interface DateRangePickerWrapperProps {
  initialDateRange?: DateRange;
  definedRanges: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  locale?: Locale;
  maxDaysBetween?: number;
}

const DateRangePickerWrapper: React.FunctionComponent<DateRangePickerWrapperProps> = (
  props: DateRangePickerWrapperProps,
) => {
  const today = new Date();

  const {
    onChange,
    initialDateRange,
    minDate,
    maxDate,
    definedRanges,
    locale,
  } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));
  const [intialFirstMonth] = getValidatedMonths(
    initialDateRange || {},
    minDateValid,
    maxDateValid,
  );
  const maxDaysBetween = props.maxDaysBetween ?? 31;

  const [dateRange, setDateRange] = React.useState<DateRange>({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(intialFirstMonth || today);
  const [ displayMode, setDisplayMode ] = React.useState<"normal" | "months" | "years">("normal");

  const { startDate, endDate } = dateRange;

  const onDayClick = (day: Date) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      if (differenceInDays(day, startDate) > maxDaysBetween) {
        day = add(startDate, { days: maxDaysBetween });
      }
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onNormalNavigate = (marker: Marker, action: NavigationAction) => {
    const firstNew = addMonths(firstMonth, action);
    setFirstMonth(firstNew);
  };

  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    const firstNew = addYears(firstMonth, action);
    setFirstMonth(firstNew);
  };

  const onDayHover = (date: Date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  const onRangeClick = (date: DateRange) => {
    setDateRange(date);
    const firstNew = new Date();
    setFirstMonth(firstNew);
    setHoverDay(date.endDate);
    onChange(date)
    setDisplayMode('normal');
  }

  // helpers
  const inHoverRange = (day: Date) => (startDate
      && !endDate
      && hoverDay
      && isAfter(hoverDay, startDate)
      && isWithinInterval(day, { start: startDate, end: hoverDay })) as boolean;

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onNormalNavigate,
    onMonthNavigate,
  };

  const commonProps = {
    dateRange,
    minDate: minDateValid,
    maxDate: maxDateValid,
    helpers,
    handlers,
    hoverDay,
    displayMode,
    setDisplayMode,
  };

  return (
    <Paper elevation={5} square>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          <DefinedRanges
            selectedRange={dateRange}
            ranges={definedRanges}
            setRange={onRangeClick}
          />
        </Grid>
        <Divider orientation="vertical" flexItem/>
        <Grid>
          <Grid container direction="row" justifyContent="center" wrap="nowrap">
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[
                displayMode !== 'years',
                displayMode !== 'years' && isBefore(firstMonth, new Date()) && !(displayMode === 'normal' ? isSameMonth : isSameYear)(firstMonth, new Date())
              ]}
              marker={MARKERS.FIRST_MONTH}
              locale={locale}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DateRangePickerWrapper;
