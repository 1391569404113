import { apiUrl } from "../../config";

const getSchedulesEndpoint = () => `${apiUrl}/access-schedules`;
const getScheduleEndpoint = (scheduleId: string) => `${apiUrl}/access-schedules/${scheduleId}`;
const createScheduleEndpoint = () => `${apiUrl}/access-schedules`;
const updateScheduleEndpoint = (scheduleId: string) => `${apiUrl}/access-schedules/${scheduleId}`;
const deleteScheduleEndpoint = (scheduleId: string) => `${apiUrl}/access-schedules/${scheduleId}`;

export {
    getSchedulesEndpoint,
    getScheduleEndpoint,
    createScheduleEndpoint,
    updateScheduleEndpoint,
    deleteScheduleEndpoint
};