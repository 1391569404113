import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Switch, Tab, Tabs } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';

import { useInput, useSelectInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import { DeviceControllerCreateRequest, DeviceControllerGetAllSerialNumbers, DeviceCreate } from 'services/device-management/types';
import { ScheduleGetAllNamesResponse } from 'services/schedules-management/types';
import { schedulesService } from 'services/schedules-management/schedules.service';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { deviceControllerService } from 'services/device-management/deviceController.service';
import LocationSelect from 'components/LocationSelect';

const tabStyles = {
    schedulePaper: {
        m: 1,
        minHeight: 260,
        maxHeight: 260,
        overflow: "auto",
        width: '100%',
    },
    numberInput: {
        textAlign: "center",
        ml: 1,
        maxWidth: 50,
    },
    securityInput: {
        textAlign: "center",
        ml: 1,
        maxWidth: 75,
    }
};

interface TabPanelProps {
    index: 0 | 1;
    value: 0 | 1;
    t: (key: string) => string;
    schedules: ScheduleGetAllNamesResponse[];
    device: DeviceCreate;
    setDevice: (device: DeviceCreate) => void;
}

function TabPanel(props: TabPanelProps) {
    const { value, index, t, schedules, device, setDevice, ...other } = props;

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setDevice({ ...device, name: event.target.value as string });
    };

    const handleScheduleSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const newSchedules = [...device.deviceAccessSchedules];
        const index = newSchedules.findIndex(sch => sch.uuid === event.target.name);
        index !== -1 ? newSchedules.splice(index, 1) : newSchedules.push({ uuid: event.target.name });
        const newDevice: DeviceCreate = {
            ...device,
            deviceAccessSchedules: newSchedules
        }
        setDevice(newDevice);
    };

    const handleNumberInputChange = (event: ChangeEvent<HTMLInputElement>, len = 2): void => {
        const value = parseInt(event.target.value.length > len ? event.target.value.slice(-len) : event.target.value);
        setDevice({ ...device, [event.target.name]: value });
    };

    const handleSecurityPinChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value.length > 8 ? event.target.value.slice(-8) : event.target.value;
        
        if (isNaN(Number(value))) return;
            
        setDevice({ ...device, [event.target.name]: value });
    };

    const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setDevice({ ...device, [event.target.name]: event.target.checked });
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
                        <TextField
                            required
                            id={`entrance-${index + 1}-name-input`}
                            label={t("Name")}
                            value={device.name}
                            onChange={handleNameChange}
                            variant='standard'
                        />
                    </FormControl>

                    <Grid container spacing={1}>
                        <Paper sx={tabStyles.schedulePaper} elevation={2}>
                            <Grid container sx={{ width: "100%", p: 2 }} spacing={1}>
                                {schedules.length > 0 ? (
                                    <>
                                        <Grid item>
                                            <Typography variant="h6" id="scheduleTitle" component="div">
                                                {t("Schedules")}
                                            </Typography>
                                        </Grid>
                                        {schedules.map(schedule => (
                                            <Grid key={schedule.uuid} container item justifyContent="space-between">
                                                <Grid item p={1} xs>
                                                    {schedule.name}
                                                </Grid>
                                                <Grid item >
                                                    <Switch
                                                        name={schedule.uuid}
                                                        checked={device.deviceAccessSchedules.find(devSch => devSch.uuid === schedule.uuid) ? true : false}
                                                        onChange={handleScheduleSwitchChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                ) : (
                                    <Grid item p={1} xs>
                                        <Typography variant="h6" id="noScheduleTitle" component="div">
                                            {t("No schedules")}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid container spacing={1}>
                        <Paper sx={tabStyles.schedulePaper} elevation={2}>
                            <Grid container>
                                <Grid item p={1}>
                                    {t("Lock will be open for")}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        sx={tabStyles.numberInput}
                                        type="number"
                                        name="doorUnlockDuration"
                                        InputProps={{ inputProps: { min: "0", max: "10" } }}
                                        value={device.doorUnlockDuration}
                                        onChange={handleNumberInputChange}
                                        variant='standard'
                                    />
                                </Grid>
                                <Grid item p={1}>
                                    {t("seconds")}
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item p={1}>
                                    {t("Alarm length")}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        sx={tabStyles.numberInput}
                                        type="number"
                                        name="alarmDuration"
                                        InputProps={{ inputProps: { min: "0", max: "10" } }}
                                        value={device.alarmDuration}
                                        onChange={handleNumberInputChange}
                                        variant='standard'
                                    />
                                </Grid>
                                <Grid item p={1}>
                                    {t("seconds")}
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item p={1}>
                                    {t("Fire alarm length")}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        sx={tabStyles.numberInput}
                                        type="number"
                                        name="fireAlarmDuration"
                                        InputProps={{ inputProps: { min: "0", max: "10" } }}
                                        value={device.fireAlarmDuration}
                                        onChange={handleNumberInputChange}
                                        variant='standard'
                                    />
                                </Grid>
                                <Grid item p={1}>
                                    {t("seconds")}
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item p={1}>
                                    {t("Generate alarm for passing entrance")}
                                </Grid>
                                <Grid item>
                                    <Switch
                                        size="small"
                                        name="openTimeoutEnabled"
                                        checked={device.openTimeoutEnabled}
                                        onChange={handleSwitchChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item p={1}>
                                    {t("Open timeout")}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        sx={tabStyles.numberInput}
                                        type="number"
                                        name="openTimeout"
                                        InputProps={{ inputProps: { min: "0", max: "10" } }}
                                        value={device.openTimeout}
                                        onChange={handleNumberInputChange}
                                        disabled={!device.openTimeoutEnabled}
                                        variant='standard'
                                    />
                                </Grid>
                                <Grid item p={1}>
                                    {t("seconds")}
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item p={1}>
                                    {t("Security pin enable")}
                                </Grid>
                                <Grid item>
                                    <Switch
                                        size="small"
                                        name="securityPinEnabled"
                                        checked={device.securityPinEnabled}
                                        onChange={handleSwitchChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item p={1}>
                                    {t("Security pin")}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        sx={tabStyles.securityInput}
                                        type="string"
                                        name="securityPin"
                                        InputProps={{ inputProps: { minLength: 8, maxLength: 8 } }}
                                        value={device.securityPin}
                                        onChange={handleSecurityPinChange}
                                        disabled={!device.securityPinEnabled}
                                        required
                                        variant='standard'
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Box>
            )}
        </div>
    );
}

const addDeviceFormStyles = {
    formControl: {
        m: 1,
        minWidth: 120,
        width: "100%",
    },
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

interface AddDeviceFormProps {
    editedDevice?: string;
    handleClose: () => void;
};

export default function AddDeviceForm(props: AddDeviceFormProps) {
    const classes = useStyles();
    const [sessionContext] = useSessionContext();
    const { editedDevice, handleClose } = props;
    const [serialNumbers, setSerialNumbers] = React.useState<DeviceControllerGetAllSerialNumbers[]>([]);
    const { value: deviceController, setValue: setDeviceController, bind: bindDeviceController } = useSelectInput("");
    const { value: location, setValue: setLocation, bind: bindLocation } = useSelectInput("");
    const { value: type, setValue: setType, bind: bindType } = useSelectInput("");
    const { value: description, setValue: setDescription, bind: bindDescription } = useInput('');
    const [evacuationListEnabled, setEvacuationListEnabled] = React.useState<boolean>(true);
    const [ isWorkTimeReportingEnabled, setIsWorkTimeReportingEnabled ] = React.useState<boolean>(true);
    const [schedules, setSchedules] = React.useState<ScheduleGetAllNamesResponse[]>([]);
    const [entranceTab, setEntranceTab] = useState<0 | 1>(0);
    const { enqueueSnackbar } = useSnackbar();
    const [t] = useTranslation();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };
    const [deviceOne, setDeviceOne] = React.useState<DeviceCreate>({
        name: '',
        deviceGroupUuid: undefined,
        doorUnlockDuration: 3,
        alarmDuration: 5,
        fireAlarmDuration: 0,
        openTimeoutEnabled: false,
        openTimeout: 10,
        securityPinEnabled: false,
        securityPin: "",
        deviceAccessSchedules: [],
        order: 1,
    });
    const [deviceTwo, setDeviceTwo] = React.useState<DeviceCreate>({
        name: '',
        deviceGroupUuid: undefined,
        doorUnlockDuration: 3,
        alarmDuration: 5,
        fireAlarmDuration: 0,
        openTimeoutEnabled: false,
        openTimeout: 10,
        securityPinEnabled: false,
        securityPin: "",
        deviceAccessSchedules: [],
        order: 2,
    });

    useEffect(() => {
        schedulesService.getAllSchedulesNames(sessionContext.token)
            .then(res => {
                setSchedules(res);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), "error"));

        if (editedDevice) {
            deviceControllerService.getOneForEdit(editedDevice, sessionContext.token)
                .then(res => {
                    setDeviceController(editedDevice);
                    setDescription(res.description);
                    setLocation(res.locationUuid);

                    setEvacuationListEnabled(res.evacuationListEnabled);
                    setIsWorkTimeReportingEnabled(res.isWorkTimeReportingEnabled);
                    if (res.devices.length >= 1) setDeviceOne(res.devices[0]);
                    if (res.devices.length >= 2) setDeviceTwo(res.devices[1]);
                })
                .catch(err => {
                    handleSnackBar(getGetErrorText(err), "error");
                });
        }

        deviceControllerService.getAllSerialNumbersToSelect(sessionContext.token)
            .then(res => res.filter(dc => dc.uuid === editedDevice || (dc.uuid !== editedDevice && !dc.isActivated)))
            .then(res => setSerialNumbers(res))
            .catch(err => handleSnackBar(getGetErrorText(err), "error"));
    }, []);

    useEffect(() => {
        if (deviceController) {
            deviceControllerService.getOne(deviceController, sessionContext.token)
                .then(res => {
                    // setType(res.type.slice(1));
                    setType("1")
                })
                .catch(err => handleSnackBar(getGetErrorText(err), "error"));
        }
    }, [deviceController]);

    useEffect(() => {
        setEntranceTab(0);
    }, [type]);

    function handleOnSubmit(e: FormEvent) {
        e.preventDefault();
        
        if (!validationOk()) return;

        let newDeviceController: DeviceControllerCreateRequest = {
            locationUuid: location,
            description: description ? description : undefined,
            evacuationListEnabled,
            isWorkTimeReportingEnabled,
            devices: []
        };

        if (parseInt(type) >= 1 && deviceOne.name.length > 0) newDeviceController.devices.push(deviceOne);
        if (parseInt(type) >= 2 && deviceTwo.name.length > 0) newDeviceController.devices.push(deviceTwo);
       
        newDeviceController.devices = newDeviceController.devices.map(d => ({
            ...d,
            securityPin: d.securityPinEnabled ? d.securityPin : undefined,
            openTimeout: d.openTimeoutEnabled ? d.openTimeout : undefined,
        }))

        deviceControllerService.updateDevice(deviceController, newDeviceController, sessionContext.token)
            .then(() => {
                handleSnackBar(editedDevice ? ResponseSuccessMessages.EDIT : ResponseSuccessMessages.ADD, 'success');
                handleClose();
            })
            .catch(err => {
                handleSnackBar(getCreateErrorText(err), 'error');
            });
    };

    const validationOk = (): boolean => {
        let err = true;

        if (deviceController.length === 0) { handleSnackBar("Select location first", "error"); err = false; }

        if (location.length === 0) { handleSnackBar("Insert serial number", "error"); err = false; }

        if (parseInt(type) >= 1 && !deviceIsValid(deviceOne)) { handleSnackBar("Insert device data", "error"); err = false; }

        // if (parseInt(type) >= 2 && !deviceIsValid(deviceTwo)) { handleSnackBar("Insert device data", "error"); err = false; }

        return err;
    }

    const deviceIsValid = (device: DeviceCreate): boolean => {
        return device.name !== ''
            && device.fireAlarmDuration >= 0
            && device.doorUnlockDuration >= 0
            && device.alarmDuration >= 0
            && device.fireAlarmDuration <= 10
            && device.doorUnlockDuration <= 10
            && device.alarmDuration <= 10
            && (!device.securityPinEnabled || device.securityPin?.length === 8);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: 0 | 1) => {
        setEntranceTab(newValue);
    };

    const handleSwitchEvacuationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEvacuationListEnabled(event.target.checked);
    };

    const handleSwitchWorkTimeReportsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsWorkTimeReportingEnabled(event.target.checked);
    };

    function a11yProps(index: number) {
        return {
            id: `entrance-tab-${index}`,
            'aria-controls': `entrance-tabpanel-${index}`,
        };
    }

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("Insert device data")}
                    </Typography>
                </Box>
                <form className={classes.form} onSubmit={handleOnSubmit}>
                    <FormControl sx={addDeviceFormStyles.formControl} required disabled={!!editedDevice} variant='standard'>
                        <InputLabel id="serial-number-select-label">{t("Device serial number")}</InputLabel>
                        <Select
                            labelId="serial-number-select-label"
                            id="serial-number-select"
                            {...bindDeviceController}
                        >
                            <MenuItem value='' disabled>{t("Choose device serial number")}</MenuItem>
                            {serialNumbers.map(sn => (
                                <MenuItem key={sn.uuid} value={sn.uuid}>{sn.serialNumber}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={addDeviceFormStyles.formControl} required disabled variant='standard'>
                        <InputLabel id="type-select-label">{t("Device type")}</InputLabel>
                        <Select
                            labelId="type-select-label"
                            id="type-select"
                            {...bindType}
                        >
                            <MenuItem value='' disabled>{t("Choose device type")}</MenuItem>
                            <MenuItem value='1'>{t("1 entrance")}</MenuItem>
                            <MenuItem value='2'>{t("2 entrance")}</MenuItem>
                            <MenuItem value='4'>{t("4 entrance")}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ marginLeft: 8 }}>
                        <TextField
                            multiline
                            variant="standard"
                            fullWidth
                            id="description"
                            label={t("Description")}
                            name="description"
                            {...bindDescription}
                        />
                    </FormControl>
                    <FormControl sx={addDeviceFormStyles.formControl} required>
                        <LocationSelect
                            setValue={setLocation}
                            required
                            {...bindLocation}
                        />
                    </FormControl>
                    <FormControl sx={addDeviceFormStyles.formControl}>

                        <Grid container justifyContent="space-between" component='label'>
                            <Grid item p={1}>
                                {t("Take into account when creating evacuation list")}
                            </Grid>
                            <Grid item>
                                <Switch
                                    name="evacuationListEnabled"
                                    checked={evacuationListEnabled}
                                    onChange={handleSwitchEvacuationChange}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl sx={addDeviceFormStyles.formControl}>

                        <Grid container justifyContent="space-between" component='label'>
                            <Grid item p={1}>
                                {t("Include in worktime reports")}
                            </Grid>
                            <Grid item>
                                <Switch
                                    name="isWorkTimeReportingEnabled"
                                    checked={isWorkTimeReportingEnabled}
                                    onChange={handleSwitchWorkTimeReportsChange}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>

                    {type && <Paper sx={{ width: "100%", m: 1, mt: 2 }} elevation={3}>
                        <Tabs
                            variant="fullWidth"
                            value={entranceTab}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            {parseInt(type) >= 1 && <Tab label={t("Entrance 1")} {...a11yProps(0)} />}
                            {parseInt(type) >= 2 && <Tab label={t("Entrance 2")} {...a11yProps(1)} />}
                        </Tabs>
                        {parseInt(type) >= 1 && <TabPanel t={t} schedules={schedules} device={deviceOne} setDevice={setDeviceOne} value={entranceTab} index={0} />}
                        {parseInt(type) >= 2 && <TabPanel t={t} schedules={schedules} device={deviceTwo} setDevice={setDeviceTwo} value={entranceTab} index={1} />}
                    </Paper> }

                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedDevice ? "Save" : "Add device")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
