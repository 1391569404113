import { Session } from '../contexts/SessionContext';
import { apiUrl } from './config';

export type ResponseMessage = { status: "error" | "success", message: string };

const login = (email: string, password: string, [sessionContext, updateSessionContext]: [Session, (session: Session) => void]): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": email, "password": password })
    };

    return fetch(`${apiUrl}/sign-in`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            }
            return res.json();
        })
        .then(res => {
            localStorage.setItem('currentUser', JSON.stringify(res.authenticationToken));
            localStorage.setItem('currentUserEmail', res.user.email);
            updateSessionContext({
                ...sessionContext,
                email: res.user.email,
                token: res.authenticationToken
            });
            return { status: "success", message: "Successfully logged in. Redirecting..." }
        })
        .catch(res => {
            switch (res.status) {
                case 401:
                    return { status: "error", message: "Wrong email or password" };
                case 400:
                    return { status: "error", message: "Make sure to input both email and password" };
                case 423:
                    return { status: "error", message: "This account has been blocked" };
                case 429:
                    return { status: "error", message: "This account has been blocked" };
                default:
                    return { status: "error", message: "Something went wrong" };
            };
        });
};

const logout = ([sessionContext, updateSessionContext]: any) => {
    localStorage.removeItem('currentUser');
    updateSessionContext({ ...sessionContext, token: undefined })
};

const register = (plan: string, accountName: string, taxId: number, email: string, firstName: string, lastName: string, token: string | undefined): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "plan": plan,
            "name": accountName,
            "taxId": taxId,
            "email": email,
            "firstName": firstName,
            "lastName": lastName
        })
    };

    return fetch(`${apiUrl}/sign-up`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            };
            return res.json();
        })
        .then(() => ({ status: "success", message: `Email sent to ${email}.` }))
        .catch(res => {
            switch (res.status) {
                case 400:
                    return { status: "error", message: "You have to fill in all spaces" };
                case 401:
                    return { status: "error", message: "You are not logged in" };
                case 403:
                    return { status: "error", message: "You are not authorized" };
                case 409:
                    return { status: "error", message: "This NIP is already registered" };
                default:
                    return { status: "error", message: "Something went wrong" };
            };
        });
};

const registerConfirmation = (id: string, guid: string, password: string): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "stamp": guid,
            "password": password
        })
    };

    return fetch(`${apiUrl}/accounts/${id}/confirmation`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            };
        })
        .then(() => ({ status: "success", message: "Password set" }))
        .catch(res => {
            switch (res.status) {
                case 400:
                    return { status: "error", message: "You have to fill in all spaces" };
                case 401:
                    return { status: "error", message: "Invalid verification code" };
                case 409:
                    return { status: "error", message: "Account is already confirmed" };
                case 410:
                    return { status: "error", message: "Verification code has expired" };
                default:
                    return { status: "error", message: "Something went wrong" };
            };
        });
};

const requestResetPassword = (email: string): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "email": email,
        })
    };

    return fetch(`${apiUrl}/password-reset`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            };
            return res.json();
        })
};

const resetPassword = (id: string, guid: string, newPassword: string): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "stamp": guid,
            "password": newPassword,
        })
    };

    return fetch(`${apiUrl}/password-reset/${id}/confirmation`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            };
            return res.json();
        })
        .then(() => ({ status: "success", message: `Password set` }))
        .catch(res => {
            switch (res.status) {
                case 400:
                    return { status: "error", message: "Incorrect password" };
                case 403:
                    return { status: "error", message: "Invalid verification code" };
                case 410:
                    return { status: "error", message: "Verification code has expired" };
                default:
                    return { status: "error", message: "Something went wrong" };
            };
        });
};

export const authenticationService = {
    login,
    logout,
    register,
    registerConfirmation,
    requestResetPassword,
    resetPassword
};