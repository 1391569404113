import React, { FormEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { VariantType, useSnackbar } from 'notistack';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

import { useInput, useMultiselectInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { DeviceOnlyNamesGetResponse } from 'services/device-management/types';
import { deviceService } from 'services/device-management/device.service';
import { guestTypesService } from 'services/account-management/guestTypes.service';
import { GuestTypeCreateRequest } from 'services/account-management';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

interface AddGuestSettingsFormProps {
    editedGuestType?: string;
    handleClose: () => void;
};

export default function AddGuestSettingsForm(props: AddGuestSettingsFormProps) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [sessionContext] = useSessionContext();
    const { editedGuestType, handleClose } = props;

    const { value: name, setValue: setName, bind: bindName } = useInput("");
    const { value: devicesSelected, setValue: setDevicesSelected, bind: bindDevicesSelected } = useMultiselectInput([]);

    const [ devices, setDevices ] = React.useState<DeviceOnlyNamesGetResponse[]>([]);

    const [ nameError, setNameError ] = React.useState<boolean>(false);

    const [t] = useTranslation();

    useEffect(() => {
        refreshData();
    }, [editedGuestType]);

    const refreshData = () => {
        deviceService.getAll(sessionContext.token)
            .then(res => setDevices(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));

        if (!editedGuestType) return;
        
        guestTypesService.getGuestType(editedGuestType, sessionContext.token)
            .then(res => {
                setName(res.name);
                if (res.devices.length) setDevicesSelected(res.devices.map(({ uuid }) => uuid))
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }

    const resetErrorLog = (): void => {
        setNameError(false);
    }

    const validationOk = async (): Promise<boolean> => {
        resetErrorLog();
        let temp = false;

        if (!name.length) {
            setNameError(true);
            temp = true;
        }

        if (temp) {
            handleSnackBar("You have to fill in all spaces", "error")
            return false;
        }
        
        return true;
    }

    const handleOnSubmit = async(e: FormEvent): Promise<void> => {
        e.preventDefault();

        if (!(await validationOk())) return;

        const dept: GuestTypeCreateRequest = {
            name,
            devices: devicesSelected.map(uuid => ({ uuid })),
        };
        
        if (editedGuestType) {
            guestTypesService.updateGuestType(editedGuestType, dept, sessionContext.token)
                .then(res => {
                    handleSnackBar(ResponseSuccessMessages.EDIT, 'success')
                    handleClose();
                })
                .catch(err => handleSnackBar(getCreateErrorText(err), "error"));
        }
        else {
            guestTypesService.createGuestType(dept, sessionContext.token)
                .then(res => {
                    handleSnackBar(ResponseSuccessMessages.ADD, 'success')
                    handleClose();
                })
                .catch(err => handleSnackBar(getCreateErrorText(err), "error"));
        }
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {t("Insert guest type")}
                </Typography>
                <form className={classes.form} onSubmit={handleOnSubmit}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="guestTypeName"
                        label={t("Guest type name")}
                        name="guestTypeName"
                        autoComplete="guestTypeName"
                        autoFocus
                        error={nameError}
                        {...bindName}
                    />
                    <FormControl fullWidth sx={{ width: "100%" }} variant='standard'>
                        <InputLabel id="device-select-label">{t("Choose device")}</InputLabel>
                        <Select
                            fullWidth
                            multiple
                            labelId="device-select-label"
                            id="device-select"
                            sx={{ width: '100%' }}
                            {...bindDevicesSelected}
                        >
                            {devices.map(d => (
                                <MenuItem key={d.uuid} value={d.uuid}>
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedGuestType ? "Save" : "Add guest type")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container >
    );
}