import { createGuestTypesEndpoint, deleteGuestTypesEndpoint, getGuestTypeEndpoint, getGuestTypesEndpoint, GuestTypeCreateRequest, GuestTypeCreateResponse, GuestTypeGetAllResponse, GuestTypeGetResponse, GuestTypeUpdateRequest, GuestTypeUpdateResponse, updateGuestTypesEndpoint } from ".";
import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";

export interface GuestTypeData {
  uuid: string,
  name: string,
};

const getAll = async(token?: string): Promise<GuestTypeGetAllResponse[]> => {
  return await genericGet<GuestTypeGetAllResponse[]>(getGuestTypesEndpoint(), {}, token);
};

const getGuestType = async(uuid: string, token?: string): Promise<GuestTypeGetResponse> => {
  return await genericGet<GuestTypeGetResponse>(getGuestTypeEndpoint(uuid), {}, token);
};

const createGuestType = async(dept: GuestTypeCreateRequest, token?: string): Promise<GuestTypeCreateResponse> => {
  return await genericPost<GuestTypeCreateResponse>(createGuestTypesEndpoint(), dept, token);
};

const updateGuestType = async(uuid: string, dept: GuestTypeUpdateRequest, token?: string): Promise<GuestTypeUpdateResponse> => {
  return await genericPut<GuestTypeUpdateResponse>(updateGuestTypesEndpoint(uuid), dept, token);
};

const deleteGuestType = async(uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
  return await genericDelete(deleteGuestTypesEndpoint(uuid), token);
};

export const guestTypesService = {
  getAll,
  getGuestType,
  createGuestType,
  updateGuestType,
  deleteGuestType
};

