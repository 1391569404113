import { SelectChangeEvent } from "@mui/material";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import { useState, ChangeEvent } from "react";

export const useInput = (initialValue: any, allowedStructure?: RegExp) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: (value?: string) => setValue(value ?? ""),
    bind: {
      value,
      onChange: (event: ChangeEvent<HTMLInputElement>): void => {
        if (allowedStructure && !allowedStructure.test(event.target.value))
          return;

        setValue(event.target.value);
      },
    },
  };
};

export const useMultiselectInput = (initialValue: string[]) => {
  const [value, setValue] = useState<string[]>([]);

  return {
    value,
    setValue,
    reset: (value: string[]) => setValue(value),
    bind: {
      value,
      setValue,
      onChange: (event: SelectChangeEvent<any>): void => {
        setValue(event.target.value as string[]);
      },
    },
  };
};

export const useSelectInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: (value: string) => setValue(value),
    bind: {
      value,
      onChange: (event: SelectChangeEvent<any>): void => {
        setValue(event.target.value as string);
      },
    },
  };
};

export const useCheckboxInput = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: (value: boolean) => setValue(value),
    bind: {
      checked: value,
      onChange: (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.checked);
      },
    },
  };
};

export const useRadioInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue((event.target as HTMLInputElement).value);
      },
    },
  };
};

export const useNumberInput = (
  initialValue: number,
  min: number,
  max: number
) => {
  const [value, setValue] = useState(initialValue);

  const handleNumberInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const value = parseInt((event.target as HTMLInputElement).value);
    if (min <= value && value <= max) {
      setValue(value);
    }
  };

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleNumberInputChange(event);
      },
    },
  };
};

export const useTimeInput = (initialValue?: Date | string, inputFormat = "HH:mm") => {
  const [value, setValue] = useState<Date | null>(
    typeof initialValue === 'string'
    ? parse(initialValue, inputFormat, new Date())
    : initialValue ?? null
  );

  return {
    value: value ? format(value, inputFormat) : null,
    setValue: (stringVal: string) => setValue(parse(stringVal, inputFormat, new Date())),
    bind: {
      value,
      onChange: (value: unknown) => {
        if (!isNaN(value as any) || value === null) setValue(value as Date | null);
      },
      format: inputFormat,
      ampm: false,
      viewRenderers: {
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
      }
    },
  };
};
