import React, { FormEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { authenticationService, ResponseMessage } from 'services/authentication.service';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        maxWidth: "500px",
    },
    gridBox: {
        verticalAlign: "middle",
        marginTop: "auto",
        marginBottom: "auto",
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface MatchParams {
    id: string;
    guid: string;
}

interface ResetPasswordConfirmationViewProps extends RouteComponentProps<MatchParams> {
}

export default function ResetPasswordConfirmationView(props: ResetPasswordConfirmationViewProps) {
    const classes = useStyles();
    const history = useHistory();
    const { id, guid } = props.match.params;
    const [result, setResult] = React.useState<null | ResponseMessage>(null);
    const [state, setState] = React.useState({
        password: '',
        repeatPassword: ''
    });
    const { password, repeatPassword } = state;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResult(null);
        setState({ ...state, [e.target.id]: e.target.value });
    };

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();
        if (password !== repeatPassword) {
            setResult({ status: "error", message: "Both passwords need to be identical" });
        } else {
            const res = await authenticationService.resetPassword(id, guid, password);
            setResult(res);
            setTimeout(()=> history.push('/login'), 500);
        };
    };

    const {t } = useTranslation();

    return (
        <Box className={classes.gridBox}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {t("Insert new password")}
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label={t("New password")}
                    type="password"
                    name="password"
                    autoFocus
                    value={password}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="repeatPassword"
                    label={t("Repeat new password")}
                    type="password"
                    name="repeat-password"
                    value={repeatPassword}
                    onChange={handleChange}
                />
                {!!result
                    && <>
                        <Box mt={2} />
                        <Alert severity={result.status}>{result.message}</Alert>
                    </>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    {t("Confirm")}
                </Button>
            </form>
        </Box>
    );
}