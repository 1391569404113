import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import SideMenu from './SideMenu';
import { Box, Toolbar } from '@mui/material';

interface AuthorizedAppLayoutProps {
  children?: React.ReactNode;
};

export default function AuthorizedAppLayout(props: AuthorizedAppLayoutProps) {
  return (
    <Box display="flex">
      <CssBaseline />
      <SideMenu />
      <Box component="main" flexGrow={1}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
};

