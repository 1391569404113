import { apiUrl } from "../../config";

const getRolesWithPermissionsEndpoint = () => `${apiUrl}/roles/permissions`;
const getRolesEndpoint = () => `${apiUrl}/roles`;
const getRoleEndpoint = (uuid: string) => `${apiUrl}/roles/${uuid}`;
const createRoleEndpoint = () => `${apiUrl}/roles`;
const updateRoleEndpoint = (uuid: string) => `${apiUrl}/roles/${uuid}`;
const deleteRoleEndpoint = (uuid: string) => `${apiUrl}/roles/${uuid}`;

export {
    getRolesWithPermissionsEndpoint,
    getRolesEndpoint,
    getRoleEndpoint,
    createRoleEndpoint,
    updateRoleEndpoint,
    deleteRoleEndpoint
};