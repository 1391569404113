import React from 'react';
import { Container, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';

import { LocationGetNamesResponse, locationsService } from 'services/company-management';
import { useSessionContext } from 'contexts/SessionContext';
import { useSelectInput } from 'hooks/input-hook';
import { deviceService } from 'services/device-management/device.service';
import { ActiveGetResponse, EventAlarmsGetResponse, EventGetAllResponse } from 'services/device-management/types';
import { getCreateErrorText } from 'services/genericService';
import DashboardLocationLogsTable from './components/DashboardLocationLogsTable';
import DashboardCurrentlyActiveTable from './components/DashboardCurrentlyActiveTable';
import DashboardAlarmsTable from 'pages/dashboard/components/DashboardAlarmsTable';
import DashboardAlarmsChart from './components/DashboardAlarmsChart';
import DashboardPeopleFlowChart from './components/DashboardPeopleFlowChart';
import DashboardTopBar from './components/DashboardTopBar';
import { differenceInDays } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
    },
    headerPaper: {
        padding: theme.spacing(2),
        // marginBottom: theme.spacing(1),
        maxWidth: 1500,
    },
    fixActivesSpacing: {
        '& > *:first-child': {
            [theme.breakpoints.only('md')]: {
                paddingRight: theme.spacing(1),
            },
            [theme.breakpoints.up('lg')]: {
                paddingBottom: theme.spacing(1),
            },
            [theme.breakpoints.down('md')]: {
                paddingBottom: theme.spacing(1),
            },
        },
        '& > *:last-child': {
            [theme.breakpoints.only('md')]: {
                paddingLeft: theme.spacing(1),
            },
            [theme.breakpoints.up('lg')]: {
                paddingTop: theme.spacing(1),
            },
            [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing(1),
            },
        },
    },
    maxHeight: {
        [theme.breakpoints.up('lg')]: {
            height: '100%',
        },
    },
}));

export default function DashboardView(props: any) {
    const classes = useStyles();
    const [showFromDate, setShowFromDate] = React.useState<Date | null>(new Date());
    const [showToDate, setShowToDate] = React.useState<Date | null>(new Date());
    const { value: location, setValue: setLocation, bind: bindLocation } = useSelectInput('');
    const [alarms, setAlarms] = React.useState<EventAlarmsGetResponse | undefined>(undefined);
    const [ actives, setActives ] = React.useState<ActiveGetResponse | undefined>();
    const [peopleFlow, setPeopleFlow] = React.useState<ActiveGetResponse[]>([]);
    const [logs, setLogs] = React.useState<EventGetAllResponse | undefined>(undefined);
    const [ selectedLogs, setSelectedLogs ] = React.useState<number[]>([]);
    const [sessionContext] = useSessionContext();
    const refreshIntervalDelay = 10 * 1000; // 10 sec
    const oneDayInMs = 86400000;
    const [ refreshDetector, setRefreshDetector ] = React.useState<Date | undefined>(undefined);
    const [ isLessThenOneDayRange, setIsLessThenOneDayRange ] = React.useState<boolean>(false);
    const [ logsPage, setLogsPage ] = React.useState<number>(0);
    const [ logsRowsPerPage, setLogsRowsPerPage ] = React.useState<number>(6);

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };
    
    const { t } = useTranslation();

    useEffect(() => {
        setIsLessThenOneDayRange((showToDate?.valueOf() ?? 0) - (showFromDate?.valueOf() ?? 0) < oneDayInMs);
        refreshData();
        setLogsPage(0);

        const refreshInterval = setInterval(refreshData, refreshIntervalDelay);
    
        return () => {
            clearInterval(refreshInterval)
        }
    }, [location, showFromDate, showToDate]);

    useEffect(() => {
        fetchEvents()
    }, [logsPage, logsRowsPerPage, selectedLogs])

    const refreshData = () => setRefreshDetector(new Date());

    const fetchEvents = () => {
        if (!location) return;
        if (showToDate && showFromDate && differenceInDays(showToDate, showFromDate) > 32) return;
        
        deviceService.getAllEvents(logsPage, logsRowsPerPage, selectedLogs, showFromDate, showToDate, location, sessionContext.token)
            .then(res => {
                setLogs(res)
            })
            .catch(err => 
                handleSnackBar(getCreateErrorText(err), 'error')
            )
    }

    useEffect(() => {
        if (showToDate && showFromDate && differenceInDays(showToDate, showFromDate) > 32) return;
        
        fetchEvents();

        deviceService.getAllActive(location, sessionContext.token)
            .then(res => {
                setActives(res)
            })
            .catch(err => 
                handleSnackBar(getCreateErrorText(err), 'error')
            )

        deviceService.getDataFlow(showFromDate, showToDate, location, sessionContext.token)
            .then(res => {
                setPeopleFlow(res);
            })
            .catch(err => {
                handleSnackBar(getCreateErrorText(err), 'error')
            })
            
        deviceService.getAlarms(showFromDate, showToDate, location, sessionContext.token)
            .then(res => {
                setAlarms(res)
            })
            .catch(err => {
                handleSnackBar(getCreateErrorText(err), 'error')
            })
    }, [ refreshDetector ]);

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.headerPaper}>
                        <DashboardTopBar
                            bindLocation={bindLocation}
                            setLocation={setLocation}
                            showFromDate={showFromDate}
                            setShowFromDate={setShowFromDate}
                            showToDate={showToDate}
                            setShowToDate={setShowToDate}
                        />
                    </Paper>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.paper}>
                            <DashboardPeopleFlowChart peopleFlow={peopleFlow} isLessThenOneDayRange={isLessThenOneDayRange} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.paper}>
                            <DashboardAlarmsChart alarms={alarms} isLessThenOneDayRange={isLessThenOneDayRange} />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item spacing={2} alignItems="flex-start">
                    <Grid item container xs={12} lg={6} className={classes.fixActivesSpacing}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Paper className={classes.paper}>
                                <DashboardCurrentlyActiveTable actives={actives} location={location} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={12}>
                            <Paper className={classes.paper}>
                                <DashboardAlarmsTable alarms={alarms} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.maxHeight}>
                        <Paper className={classes.paper}>
                            <DashboardLocationLogsTable
                                rows={logs}
                                setRowsPerPage={setLogsRowsPerPage}
                                setPage={setLogsPage}
                                setSelectedLogs={setSelectedLogs}
                                selectedLogs={selectedLogs}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Container>

    );
}
