import { apiUrl } from "../../config";

const getHolidaysEndpoint = () => `${apiUrl}/holidays`;
const getHolidayEndpoint = (holidayId: string) => `${apiUrl}/holidays/${holidayId}`;
const createHolidayEndpoint = () => `${apiUrl}/holidays`;
const updateHolidayEndpoint = (holidayId: string) => `${apiUrl}/holidays/${holidayId}`;
const deleteHolidayEndpoint = (holidayId: string) => `${apiUrl}/holidays/${holidayId}`;

export {
    getHolidaysEndpoint,
    getHolidayEndpoint,
    createHolidayEndpoint,
    updateHolidayEndpoint,
    deleteHolidayEndpoint
};