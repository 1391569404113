import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, Dialog, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AddAlarmForm from 'pages/devices-management/components/AddAlarmForm';
import { alarmsService, AlarmTableData } from 'services/alarms.service';
import TableSearchbar from 'components/TableSearchbar';
import DeleteDialog from 'components/DeleteDialog';
import AlarmsSettingsTable from './AlarmsSettingsTable';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    deleteAppBar: {
        position: 'relative',
        backgroundColor: theme.palette.error.main
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(6) + 1,
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginLeft: theme.spacing(8) + 1,
            marginRight: 'auto',
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function AlarmsSettings() {
    const classes = useStyles();
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);
    const [rows, setRows] = React.useState<AlarmTableData[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    useEffect(() => {
        setRows(alarmsService.getAllTable());
    }, [selectedId]);

    const [t] = useTranslation();

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickEditOpen = (id: number) => {
        setSelectedId(id);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (id: number) => {
        setSelectedId(id);
        setDeleteOpen(true);
    }

    const handleClose = () => {
        setSelectedId(0);
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
    };

    const handleDelete = () => {
        alarmsService.deleteAlarms([selectedId]);
        setSelectedId(-1);
        handleClose();
    };

    const containsSearchTerm = (report: AlarmTableData, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return report.name.toLowerCase().includes(term) ||
            report.event.toLowerCase().includes(term) ||
            report.devices.toLowerCase().includes(term) ||
            report.status.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container justifyContent="space-between">
                <Grid item container xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add alarm")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container xs justifyContent="flex-end">
                    <Grid item>
                        <TableSearchbar type="alarms" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2}>
                <AlarmsSettingsTable
                    rows={searchTerm
                        ? rows.filter(account => containsSearchTerm(account, searchTerm))
                        : rows}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickDeleteOpen} />
            </Box>

            <Dialog fullScreen open={addOpen} onClose={handleClose}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Add alarm")}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AddAlarmForm handleClose={handleClose} />
            </Dialog>

            <Dialog fullScreen open={editOpen} onClose={handleClose} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Edit alarm")}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AddAlarmForm alarmId={selectedId} handleClose={handleClose} />
            </Dialog>

            <Dialog fullScreen open={deleteOpen} onClose={handleClose} >
                <AppBar className={classes.deleteAppBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Delete alarm")}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DeleteDialog type="alarms" names={[rows.find(row => row.id === selectedId)?.name ?? '']} handleDelete={handleDelete} handleClose={handleClose} />
            </Dialog>

        </React.Fragment>
    );
}