import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import LazyLoadIcon from "components/LazyLoadIcon";
import { useSessionContext } from "contexts/SessionContext";
import { useInput, useTimeInput } from "hooks/input-hook";
import { enqueueSnackbar, VariantType } from "notistack";
import { FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCreateErrorText, ResponseSuccessMessages } from "services/genericService";
import { worktimeService } from "services/report-management";
import { ReportWorktimeCreateRequest, ReportWorkTimeTableData, ReportWorktimeUpdateRequest } from "services/report-management/types";

interface EditWorktimeEntryFormProps {
  row?: ReportWorkTimeTableData;
  customEntryUuid?: string;
  handleClose: () => void;
  locationUuid: string;
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function EditWorktimeEntryForm(props: EditWorktimeEntryFormProps) {
  const { row, handleClose, locationUuid, customEntryUuid, setSelectedRows } = props;

  const [sessionContext] = useSessionContext();
  const [t] = useTranslation();
  const { value: comments, setValue: setComments, bind: bindComments } = useInput("");
  const { value: start, setValue: setStart, bind: bindStart } = useTimeInput();
  const { value: exit, setValue: setExit, bind: bindExit } = useTimeInput();

  const handleSnackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(t(message), { variant });
  };

  useEffect(() => {
    if (customEntryUuid)
      worktimeService.getCustomEntry(customEntryUuid, sessionContext.token)
        .then(res => {
          setComments(res?.description ?? "");
          if (res?.entryTime) setStart(res.entryTime);
          if (res?.exitTime) setExit(res.exitTime);
        })
        .catch(err => handleSnackBar(getCreateErrorText(err), 'error'))
  }, [customEntryUuid]);
  
  const validateOk = () => {
    return true;
  }
  
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validateOk()) return;
    
    const getMethod = () => {
      if (customEntryUuid) {
        const dept: ReportWorktimeUpdateRequest = {
          entryTime: start ?? undefined,
          exitTime: exit ?? undefined,
          description: comments.length ? comments : undefined,
        }

        return worktimeService.updateCustomEntry(customEntryUuid, dept, sessionContext.token);
      }

      const dept: ReportWorktimeCreateRequest = {
        ...(row?.workTimeEntryEventId ? {
          workTimeEntryEventId: row.workTimeEntryEventId,
        } : {
          day: row?.day ? (new Date(row.day)).toISOString() : "",
          employeeUuid: row?.employeeUuid ?? "",
          locationUuid,
        }),
        entryTime: start ?? undefined,
        exitTime: exit ?? undefined,
        description: comments.length ? comments : undefined,
      }

      return worktimeService.createCustomEntry(dept, sessionContext.token);
    }

    try {
      await getMethod();

      if ((!row?.workTimeEntryEventId && !customEntryUuid)) await delay(1000)

      handleSnackBar(ResponseSuccessMessages.EDIT, 'success')
      setSelectedRows([]);
      handleClose()
    } catch (err) {
      handleSnackBar(getCreateErrorText(err as number), 'error')
    }
  }
  
  const handleDelete = () => {
    if (!row?.customEntryUuid) return;

    worktimeService.deleteCustomEntry(row.customEntryUuid, sessionContext.token)
      .then(() => {
        handleSnackBar(ResponseSuccessMessages.DELETE, 'success')
        setSelectedRows([]);
        handleClose()
      })
      .catch(err => handleSnackBar(getCreateErrorText(err), 'error'))
  }

  const delay = (milliseconds: number) => new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  })
  
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2} my={0}>
        <Grid item xs>
          <TimePicker
            label={t("Entrance date")}
            sx={{
              "fieldset": {
                borderTop: "0 !important",
                borderLeft: "0 !important",
                borderRight: "0 !important",
                borderRadius: 0,
              }
            }}
            {...bindStart}
          />
        </Grid>

        <Grid item xs>
          <TimePicker
            label={t("Exit date")}
            sx={{
              "fieldset": {
                borderTop: "0 !important",
                borderLeft: "0 !important",
                borderRight: "0 !important",
                borderRadius: 0,
              }
            }}
            {...bindExit}
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            id="comments"
            label={t("Comments")}
            name="comments"
            autoComplete="comments"
            autoFocus
            sx={{
              mt: 0,
              ".MuiInputBase-root": {
                mt: 0,
              },
              "input": {
                padding: "16.5px 14px",
              },
            }}
            {...bindComments}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={0}>
        <Grid item xs>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClose}
            >
                {t("Cancel")}
            </Button>
        </Grid>

        <Grid item xs>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
            >
                {t("Save")}
            </Button>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleDelete} disabled={!row?.customEntryUuid}>
              <LazyLoadIcon iconName="Delete" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
