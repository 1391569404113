import React from 'react';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useSessionContext } from './contexts/SessionContext';
import ProtectedRoute, { ProtectedRouteProps } from './components/ProtectedRoute';
import AuthorizedAppLayout from './components/AuthorizedAppLayout';
import UnauthorizedAppLayout from './components/UnauthorizedAppLayout';
import DashboardView from './pages/dashboard/DashboardView';
import MyCompanyView from './pages/company-management/MyCompanyView';
import RegisterConfirmationView from './pages/auth/RegisterConfirmationView';
import SignInView from './pages/auth/SignInView';
import ResetPasswordView from './pages/auth/ResetPasswordView';
import ResetPasswordConfirmationView from './pages/auth/ResetPasswordConfirmationView';
import MyDevicesView from './pages/devices-management/MyDevicesView';
import SchedulesView from './pages/schedules/SchedulesView';
import MyGuestsView from './pages/company-management/components/guests/MyGuestsView';
import ManageAccessView from './pages/access-control/ManageAccessView';
import SettingsView from './pages/settings/SettingsView';
import SettingsSecondaryView from './pages/settings/SettingsSecondaryView';
import ReportsView from './pages/reports/ReportsView';
import ReportsSecondaryView from './pages/reports/ReportsSecondaryView';
import { ReactQueryClientProvider } from './lib/wrappers';

const App: React.FC = () => {
  const [sessionContext] = useSessionContext();
  const renderComponent = (redirectPath: string) => <Redirect to={{ pathname: redirectPath }} />;
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: !!sessionContext.token,
    isAllowed: true,
    authenticationPath: '/auth/login',
    restrictedPath: '/'
  };

  return (
    <Router>
      <ReactQueryClientProvider>

        {!!sessionContext.token
          ? <AuthorizedAppLayout >

            <Switch>
              <ProtectedRoute {...defaultProtectedRouteProps} exact path="/" component={DashboardView} />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path="/company" component={MyCompanyView} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/company/:type" component={MyCompanyView} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/devices" component={MyDevicesView} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/schedules" component={SchedulesView} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/guests" component={MyGuestsView} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/manage" component={ManageAccessView} />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path="/settings" component={SettingsView} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/settings/:type" component={SettingsSecondaryView} />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path="/reports" component={ReportsView} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/reports/:type" component={ReportsSecondaryView} />
              <Route component={() => renderComponent('/')} />
            </Switch>

          </AuthorizedAppLayout>
          : <UnauthorizedAppLayout >
            <Switch>
              <Route path="/auth/login" component={SignInView} />
              <Route path="/auth/password-reset" component={ResetPasswordView} />
              <Route path="/auth/password-reset-confirmation/:id/:guid" component={ResetPasswordConfirmationView} />
              <Route path='/auth/register-confirmation/:id/:guid' component={RegisterConfirmationView} />
              <Route component={() => renderComponent('/auth/login')} />
            </Switch>
          </UnauthorizedAppLayout>}

      </ReactQueryClientProvider>
    </ Router>
  );
}

export default App;