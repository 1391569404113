import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MyCompanyLocationsTab from './components/locations/MyCompanyLocationsTab';
import MyCompanyEmployeesTab from './components/employees/MyCompanyEmployeesTab';
import MyCompanyRolesTab from './components/roles/MyCompanyRolesTab';
import MyCompanyDepartmentsTab from './components/departments/MyCompanyDepartmentsTab';
import { Link, RouteComponentProps } from 'react-router-dom';
import usePermission from 'hooks/permission-hook';
import { PermissionCategory } from 'services/account-management';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
        value: index,
    };
}

function LinkTab(props: any) {
    return (
        <Tab
            component={Link}
            {...props}
        />
    );
}

interface MatchParams {
    type?: "locations" | "departments" | "employees" | "roles";
};

interface MyCompanyViewProps extends RouteComponentProps<MatchParams> {
};

export default function MyCompanyView(props: MyCompanyViewProps) {
    const [ hasLocationPermission ] = usePermission(PermissionCategory.Location);
    const [ hasDepartmentsPermission ] = usePermission(PermissionCategory.Departments);
    const [ hasEmployeePermission ] = usePermission(PermissionCategory.Employees);
    const [ hasRolesPermission ] = usePermission(PermissionCategory.Roles);

    const getCurrentTab = () => {
        const type = props.match.params.type;
        if (type === 'departments' && hasDepartmentsPermission) return "two";
        if (type === 'employees' && hasEmployeePermission) return "three";
        if (type === 'roles' && hasRolesPermission) return "four";
        if (hasLocationPermission) return "one";
        if (hasDepartmentsPermission) return "two";
        if (hasEmployeePermission) return "three";
        if (hasRolesPermission) return "four";
    }

    const [value, setValue] = React.useState(getCurrentTab());

    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(getCurrentTab())
    }, [props.match.params.type, hasLocationPermission, hasDepartmentsPermission, hasEmployeePermission, hasRolesPermission]);

    if (!(hasLocationPermission || hasDepartmentsPermission || hasEmployeePermission || hasRolesPermission)) return (<></>);
    
    return (
        <>
            <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                {hasLocationPermission && <LinkTab label={t("LocationsCompany")} to="/company/location" {...a11yProps('one')} />}
                {hasDepartmentsPermission && <LinkTab label={t("Departments")} to="/company/departments" {...a11yProps('two')} />}
                {hasEmployeePermission && <LinkTab label={t("Employees")} to="/company/employees" {...a11yProps('three')} />}
                {hasRolesPermission && <LinkTab label={t("Roles")} to="/company/roles" {...a11yProps('four')} />}
            </Tabs>
            <TabPanel value={value} index="one">
                <MyCompanyLocationsTab />
            </TabPanel>
            <TabPanel value={value} index="two">
                <MyCompanyDepartmentsTab />
            </TabPanel>
            <TabPanel value={value} index="three">
                <MyCompanyEmployeesTab />
            </TabPanel>
            <TabPanel value={value} index="four">
                <MyCompanyRolesTab />
            </TabPanel>
        </>
    );
}