import React, { FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { useInput } from 'hooks/input-hook';
import ColorPicker from 'components/ColorPicker';
import { LocationCreateRequest, locationsService } from 'services/company-management';
import { useSessionContext } from 'contexts/SessionContext';
import { useSnackbar, VariantType } from 'notistack';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface AddLocationFormProps {
    editedLocation?: string;
    handleClose: () => void;
}

export default function AddLocationForm(props: AddLocationFormProps) {
    const classes = useStyles();
    const { value: name, setValue: setName, bind: bindName } = useInput("", /^.{0,50}$/);
    const { value: city, setValue: setCity, bind: bindCity } = useInput("", /^.{0,50}$/);
    const [color, setColor] = React.useState<string>("ff0000");
    const { editedLocation, handleClose } = props;
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const editMode = !!editedLocation;
    const [ nameError, setNameError ] = useState<boolean>(false);
    const [ cityError, setCityError ] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    useEffect(() => {
        if (editedLocation) {
            locationsService.getOne(editedLocation, sessionContext.token)
                .then(res => {
                    setName(res?.name);
                    setCity(res?.city);
                    res?.color ? setColor(res.color) : setColor('none');
                })
                .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
        }
    }, [editedLocation]);

    const handleOnSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();

        resetErrorLog();
        if (!ifValidateOk()) return;

        const location: LocationCreateRequest = { name, ...(!!color && color !== 'none' && { color }), city };
        try {
            if (!!editedLocation) {
                await locationsService.updateLocation(editedLocation, location, sessionContext.token)
                handleSnackBar(ResponseSuccessMessages.EDIT, 'success');
            } else {
                await locationsService.createLocation(location, sessionContext.token)
                handleSnackBar(ResponseSuccessMessages.ADD, 'success');
            }

            handleClose();

        } catch (error) {
            handleSnackBar(getCreateErrorText(error as number), 'error'); 
        }
    }

    const resetErrorLog = (): void => {
        setNameError(false);
        setCityError(false);
    }
    
    const ifValidateOk = (): boolean => {
        let temp = false;
        
        if (name.length === 0) {
            setNameError(true);
            temp = true;
        }
        
        if (city.length === 0) {
            setCityError(true);
            temp = true;
        }
        
        if (temp) {
            handleSnackBar("You have to fill in all spaces", "error")
            return false;
        }

        return true;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("Insert location data")}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                    <ColorPicker value={color} setValue={setColor} />

                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="locationName"
                        label={t("Location name")}
                        name="locationName"
                        autoComplete="locationName"
                        autoFocus
                        error={nameError}
                        {...bindName}
                    />

                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        name="City"
                        label={t("City")}
                        id="city"
                        autoComplete="city"
                        error={cityError}
                        {...bindCity}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        sx={{
                            mt: 3,
                            mb: 2,
                        }}
                    >
                        {t(editMode ? "Save" : "Add location")}
                    </Button>
                </form>

            </div>
        </Container>
    );
}