import { genericDelete, genericGet, genericPost, genericPut } from "services/genericService";
import { createWorkTimeCustomEntryEndpoint, deleteWorkTimeCustomEntryEndpoint, downloadWorkTimeEndpoint, getWorkTimeCustomEntryEndpoint, getWorkTimeEndpoint, updateWorkTimeCustomEntryEndpoint } from "./endpoints";
import { DownloadReportProps, GetReportProps, ReportPaginationData, ReportWorktimeCreateRequest, ReportWorktimeCreateResponse, ReportWorkTimeData, ReportWorktimeGetResponse, ReportWorkTimeSortOrderBy, ReportWorkTimeTableData, ReportWorktimeUpdateRequest, ReportWorktimeUpdateResponse } from "./types";
import { getRange, getWorkTime } from "./report.helpers";

const getAllWorkTimeReports = async (props: GetReportProps<ReportWorkTimeSortOrderBy>): Promise<ReportPaginationData<ReportWorkTimeTableData>> => {
  const {
      locationUuid,
      filter,
      employeeFilter,
      sortOrder,
      sortOrderBy,
      page,
      pageSize,
      startDate,
      endDate,
      token
  } = props;

  const { start, end } = getRange(startDate, endDate)

  const list = await genericGet<ReportPaginationData<ReportWorkTimeData>>(getWorkTimeEndpoint(), {
    params: {
      location: locationUuid,
      filter,
      sortOrder,
      sortOrderBy,
      page,
      pageSize,
      employeeFilter,
      ...((start && end) ? {
        startDate: start,
        endDate: end,
      } : {}),
    },
  }, token);
  return convertWorkTimeToTable(list);
}

const downloadAllWorkTimeReports = (props: DownloadReportProps<ReportWorkTimeSortOrderBy>): Promise<Blob> => {
  const {
      locationUuid,
      filter,
      employeeFilter,
      sortOrder,
      sortOrderBy,
      startDate,
      endDate,
      type,
      lang,
      token
  } = props;

  const { start, end } = getRange(startDate, endDate)

  return genericGet<Blob>(
      downloadWorkTimeEndpoint(),
      {
        responseType: 'blob',
        params: {
          location: locationUuid,
          filter,
          sortOrder,
          sortOrderBy,
          employeeFilter,
          ...((start && end) ? {
            startDate: start,
            endDate: end,
          } : {}),
          type,
          lang,
        },
      },
      token
  )
}

const convertWorkTimeToTable = (list: ReportPaginationData<ReportWorkTimeData>): ReportPaginationData<ReportWorkTimeTableData> => {
  return {
      ...list,
      rows: list.rows.map(wt => ({
          employeeId: wt.employeeId,
          day: wt.day,
          weekDay: wt.weekDay,
          employeeDepartment: wt.employeeDepartment,
          employeeName: `${wt.employeeLastName} ${wt.employeeFirstName}`,
          workTime: wt.workTimeInSeconds ? getWorkTime(wt.workTimeInSeconds) : "",
          normalTime: getWorkTime(wt.normalTimeInSeconds),
          cameLateBy: wt.cameLateByInSeconds ? getWorkTime(wt.cameLateByInSeconds) : "",
          leftEarlierBy: wt.leftEarlierByInSeconds ? getWorkTime(wt.leftEarlierByInSeconds) : "",
          nightHours: wt.nightHoursInSeconds ? getWorkTime(wt.nightHoursInSeconds) : "",
          breakTime: wt.breakTimeInSeconds ? getWorkTime(wt.breakTimeInSeconds) : "",
          entranceTime: wt.entranceTime?.split('.')[0].replace("T", " ") ?? "",
          exitTime: wt.exitTime ? wt.exitTime.split('.')[0].replace("T", " ") : "",
          description: wt.description ?? "",
          customEntryUuid: wt.customEntryUuid ?? "",
          workTimeEntryEventId: wt.workTimeEntryEventId,
          employeeUuid: wt.employeeUuid,
      })),
  };
}

const getCustomEntry = async (uuid: string, token?: string): Promise<ReportWorktimeGetResponse | undefined> => {
  return await genericGet<ReportWorktimeGetResponse>(getWorkTimeCustomEntryEndpoint(uuid), {}, token);
}

const createCustomEntry = (dept: ReportWorktimeCreateRequest, token?: string): Promise<ReportWorktimeCreateResponse | undefined> => {
  return genericPost<ReportWorktimeCreateResponse>(createWorkTimeCustomEntryEndpoint(), dept, token);
}

const updateCustomEntry = (uuid: string, dept: ReportWorktimeUpdateRequest, token?: string): Promise<ReportWorktimeUpdateResponse | undefined> => {
  return genericPut<ReportWorktimeCreateResponse>(updateWorkTimeCustomEntryEndpoint(uuid), dept, token);
}

const deleteCustomEntry = (uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
  return genericDelete(deleteWorkTimeCustomEntryEndpoint(uuid), token);
}

export const worktimeService = {
  getAllWorkTimeReports,
  downloadAllWorkTimeReports,
  getCustomEntry,
  createCustomEntry,
  updateCustomEntry,
  deleteCustomEntry,
}
