import { endOfDay, format, startOfDay } from "date-fns";

export const getStart = (startDate: Date | null) => startDate ? format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
export const getEnd = (endDate: Date | null) => endDate ? format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
export const getRange = (startDate: Date | null, endDate: Date | null) => ({ start: getStart(startDate), end: getEnd(endDate) });

export const getWorkTime = (seconds: number = 0) => {
  if (seconds < 60) return `${seconds}s`;

  const minutes = Math.floor(seconds / 60);
  const hours = minutes >= 60 ? Math.floor(minutes / 60) : 0;

  return `${hours ? `${hours}h ` : ""}${minutes % 60}m`;
}

export const convertWeekDayNumberToWeekDayString = (day: number): string => {
  if (day === 0) return "Sunday";
  if (day === 1) return "Monday";
  if (day === 2) return "Tuesday";
  if (day === 3) return "Wednesday";
  if (day === 4) return "Thursday";
  if (day === 5) return "Friday";
  if (day === 6) return "Saturday";

  return "";
}
