import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from 'react-router-dom';

import { useCheckboxInput, useInput, useNumberInput, useSelectInput } from 'hooks/input-hook';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: theme.spacing(2),
        },
        formContainer: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        secondPaper: {
            padding: theme.spacing(2),
            width: "100%",
        },
        title: {
            width: "100%",
            color: "white",
            padding: theme.spacing(2),
            textAlign: "center",
        },
        label: {
            paddingTop: theme.spacing(6),
        },
        container: {
            height: 400,
        },
        removeButton: {
            backgroundColor: "green",
            margin: theme.spacing(1),
            color: "white",
        },
        addButton: {
            backgroundColor: "orange",
            margin: theme.spacing(1),
            color: "white",
        },
    }));

const fixedColumns: { name: string, id: number }[] = [
    {
        name: "Identyfikator",
        id: 1,
    },
    {
        name: "Imię i nazwisko",
        id: 2,
    },
    {
        name: "Nr karty",
        id: 3,
    },
    {
        name: "Czas pracy",
        id: 4,
    },
    {
        name: "Dział",
        id: 5,
    },
    {
        name: "Rejestracja wejścia",
        id: 6,
    },
];

interface AddReportFormProps {
    editedReport?: number;
}

export default function AddReportForm(props: AddReportFormProps) {
    const classes = useStyles();
    const theme = useTheme();
    const { value: reportName, bind: bindReportName } = useInput("");
    const { value: activeChecked, bind: bindActiveChecked } = useCheckboxInput(true);
    const { value: separator, bind: bindSeparator } = useInput("");
    const { value: separatorAmount, bind: bindSeparatorAmount } = useNumberInput(1, 1, 10);
    const { value: type, bind: bindType } = useSelectInput("");
    const [exportType, setExportType] = React.useState<string>("xls");
    const [columnsRows, setColumnsRows] = React.useState<{ name: string, id: number }[]>(fixedColumns);
    const [selectedColumns, setSelectedColumns] = React.useState<number[]>([]);
    const [chosenColumnsRows, setChosenColumnsRows] = React.useState<{ name: string, id: number }[]>([]);
    const [selectedChosenColumns, setSelectedChosenColumns] = React.useState<number[]>([]);
    const {editedReport} = props;

    const reportTypes: { label: string, value: string, id: number }[] = [
        {
            label: "Daily report - work time",
            value: "dailyWorkTime",
            id: 1,
        },
        {
            label: "Total report - work time",
            value: "totalWorkTime",
            id: 2,
        },
        {
            label: "Alarmy",
            value: "alarms",
            id: 3,
        },
        {
            label: "Events",
            value: "events",
            id: 4,
        },
        {
            label: "Logs",
            value: "logs",
            id: 5,
        },
        {
            label: "Access list",
            value: "accessList",
            id: 6,
        },
        {
            label: "Guest list",
            value: "guestList",
            id: 7,
        },
    ];

    const exportTypes: { label: string, value: string, id: number }[] = [
        {
            label: "XLS",
            value: "xls",
            id: 1,
        },
        {
            label: "CSV",
            value: "csv",
            id: 2,
        },
        {
            label: "TXT",
            value: "txt",
            id: 3,
        },
        {
            label: "PDF",
            value: "pdf",
            id: 4,
        },
    ];

    const [t] = useTranslation();

    const handleExportTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExportType((event.target as HTMLInputElement).value);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selectedColumns.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedColumns, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedColumns.slice(1));
        } else if (selectedIndex === selectedColumns.length - 1) {
            newSelected = newSelected.concat(selectedColumns.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedColumns.slice(0, selectedIndex),
                selectedColumns.slice(selectedIndex + 1),
            );
        }
        setSelectedColumns(newSelected);
    };

    const isSelected = (id: number) => selectedColumns.indexOf(id) !== -1;

    const handleChosenClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selectedChosenColumns.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedChosenColumns, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedChosenColumns.slice(1));
        } else if (selectedIndex === selectedChosenColumns.length - 1) {
            newSelected = newSelected.concat(selectedChosenColumns.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedChosenColumns.slice(0, selectedIndex),
                selectedChosenColumns.slice(selectedIndex + 1),
            );
        }
        setSelectedChosenColumns(newSelected);
    };

    const isChosenSelected = (id: number) => selectedChosenColumns.indexOf(id) !== -1;

    const handleAddClick = () => {
        setColumnsRows([...columnsRows].filter(row => !selectedColumns.includes(row.id)));
        setChosenColumnsRows([...chosenColumnsRows].concat([...columnsRows].filter(row => selectedColumns.includes(row.id))));
        setSelectedColumns([]);
    };

    const handleRemoveClick = () => {
        setChosenColumnsRows([...chosenColumnsRows].filter(row => !selectedChosenColumns.includes(row.id)));
        setColumnsRows([...columnsRows].concat([...chosenColumnsRows].filter(row => selectedChosenColumns.includes(row.id))));
        setSelectedChosenColumns([]);
    };

    const handleMoveUp = (index: number) => {
        const newChosen = [...chosenColumnsRows];
        const movedRow = newChosen.splice(index, 1)[0];
        newChosen.splice(index - 1, 0, movedRow);
        setChosenColumnsRows(newChosen);
    };

    const handleMoveDown = (index: number) => {
        const newChosen = [...chosenColumnsRows];
        const movedRow = newChosen.splice(index, 1)[0];
        newChosen.splice(index + 1, 0, movedRow);
        setChosenColumnsRows(newChosen);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid container spacing={2} direction="column" justifyContent="space-around" alignItems="center" className={classes.formContainer}>
                <Grid container item justifyContent="space-around" >
                    <Grid item>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            id="reportName"
                            label={t("Report name")}
                            name="reportName"
                            autoComplete="reportName"
                            autoFocus
                            {...bindReportName}
                        />
                    </Grid>
                </Grid>
                <Grid container item justifyContent="space-around">
                    <Box display="block">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>{t('Not active')}</Grid>
                            <Grid item>
                                <Switch color='primary' {...bindActiveChecked} name="activeChecked" />
                            </Grid>
                            <Grid item>{t('Active')}</Grid>
                        </Grid>
                    </ Box>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h5" className={classes.title} style={{ backgroundColor: theme.palette.primary.main }}>
                            {t("Report settings")}
                        </Typography>
                        <Paper className={classes.secondPaper}>
                            <FormControl fullWidth>
                                <InputLabel id="report-type-select-label"> {t("Choose report type")}</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="report-type-select-label"
                                    id="report-type-select"
                                    displayEmpty
                                    {...bindType}
                                >
                                    {reportTypes.map(type => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {t(type.label)}
                                        </MenuItem >
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel className={classes.label} >{t("Choose export file extension")}</FormLabel>
                                <RadioGroup aria-label="exportType" name="exportType" value={exportType} onChange={handleExportTypeChange} row>
                                    {exportTypes.map(type => (
                                        <FormControlLabel key={type.value} value={type.value} control={<Radio />} label={type.label} />
                                    ))}
                                </RadioGroup>
                            </FormControl>

                            <Grid container >
                                <Grid container item alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item lg md={9} xs={6}>
                                        <Typography>{t("Separate columns with")}</Typography>
                                    </Grid>
                                    <Grid item lg md xs={6}>
                                        <TextField
                                            variant="standard"
                                            margin="normal"
                                            required
                                            id="separatorCharacter"
                                            name="separatorCharacter"
                                            autoComplete="separatorCharacter"
                                            {...bindSeparator}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center" spacing={2}>
                                    <Grid item >
                                        <Typography>{t("Amount")}</Typography>
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            type="number"
                                            id="separatorAmount"
                                            name="separatorAmount"
                                            InputProps={{ inputProps: { min: "1", max: "999" } }}
                                            {...bindSeparatorAmount}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h5" className={classes.title} style={{ backgroundColor: "#FF6B01" }}>
                            {t("Available columns")}
                        </Typography>
                        <Paper>
                            <TableContainer className={classes.container} >
                                <Table
                                    aria-labelledby="availableColumns"
                                    aria-label="columns table"
                                >
                                    <TableBody
                                    >
                                        {columnsRows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `column-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        align="center"
                                                        scope="row"
                                                        onClick={(event) => handleClick(event, row.id)}
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={handleAddClick}
                                        variant="contained"
                                        size="large"
                                        className={classes.addButton}
                                    >
                                        <FastForwardIcon />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {t("Add columns")}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h5" className={classes.title} style={{ backgroundColor: "#008D4C" }}>
                            {t("Chosen columns")}
                        </Typography>
                        <Paper>
                            <TableContainer className={classes.container} >
                                <Table
                                    aria-labelledby="chosenColumns"
                                    aria-label="chosen columns table"
                                >

                                    <TableBody
                                    >
                                        {chosenColumnsRows.map((row, index) => {
                                            const isItemSelected = isChosenSelected(row.id);
                                            const labelId = `column-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox" onClick={(event) => handleChosenClick(event, row.id)}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        align="center"
                                                        scope="row"
                                                        onClick={(event) => handleChosenClick(event, row.id)}
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid container justifyContent="flex-end">
                                                            {index !== 0
                                                                && <Grid item >
                                                                    <IconButton onClick={() => handleMoveUp(index)} size="large">
                                                                        <ArrowUpwardIcon />
                                                                    </IconButton>
                                                                </Grid>}
                                                            {index !== chosenColumnsRows.length - 1
                                                                && <Grid item>
                                                                    <IconButton onClick={() => handleMoveDown(index)} size="large">
                                                                        <ArrowDownwardIcon />
                                                                    </IconButton>
                                                                </Grid>}
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={handleRemoveClick}
                                        variant="contained"
                                        size="large"
                                        className={classes.removeButton}
                                    >
                                        <FastRewindIcon />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {t("Remove columns")}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container spacing={2} direction="row-reverse">
                    <Grid item lg={2} md={3} xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                            {t(editedReport ? "Save" : "Add report")}
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={3} xs>
                        <Button
                        component={Link}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            to="/reports">
                            {t("Cancel")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </div >
    );
}