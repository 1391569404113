import React, { useEffect } from 'react';
import { VariantType, useSnackbar } from 'notistack';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DeleteDialog from 'components/DeleteDialog';
import { rolesService } from 'services/account-management/';
import { RoleGetAllResponse } from 'services/account-management/types';
import { useSessionContext } from 'contexts/SessionContext';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import RolesTable from './RolesTable';
import AddRoleForm from './AddRoleForm';
import DialogOverlay from 'components/DialogOverlay';

export default function MyCompanyRolesTab() {
    const [sessionContext] = useSessionContext();
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [editedId, setEditedId] = React.useState<string>();
    const [rows, setRows] = React.useState<RoleGetAllResponse[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [t] = useTranslation();
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    useEffect(() => {
        loadData();
    }, [editedId]);

    const loadData = () => {
        rolesService.getRoles(sessionContext.token)
            .then(res => {
                setRows(res);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickEditOpen = (id: string): void => {
        setEditedId(id);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (id: string): void => {
        setEditedId(id);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setDeleteOpen(false);
        setEditOpen(false);
        loadData();
    };

    const handleDelete = () => {
        rolesService.deleteRole((editedId as string), sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), "error"));
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const containsSearchTerm = (role: RoleGetAllResponse, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return role.name.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>

            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add role")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Box mt={2}>
                <RolesTable
                    rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickDeleteOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add role")}>
                <AddRoleForm handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit role")}>
                <AddRoleForm editedRole={editedId} handleClose={handleClose} />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete role")}>
                <DeleteDialog type="roles" handleDelete={handleDelete} names={[rows.find(role => role.uuid === editedId)?.name ?? '']} handleClose={handleClose} />
            </DialogOverlay>

        </React.Fragment>
    );
}